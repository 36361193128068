import { FC, useState, useEffect, PropsWithChildren } from "react";
import styles from "./AnimatedWrapper.module.scss";
import { useLocation } from "react-router-dom";

interface AnimatedWrapperProps extends PropsWithChildren {
  isOpen: boolean;
  delay?: number;
  closeOnLocationChange?: boolean;
  onClose: () => void;
}

const AnimatedWrapper: FC<AnimatedWrapperProps> = ({
  isOpen,
  children,
  delay = 150,
  onClose,
}) => {
  const [shouldRender, setRender] = useState(isOpen);
  const [isVisible, setVisible] = useState(isOpen);
  const location = useLocation();
  const [prevLocation, setPrevLocation] = useState(location);

  useEffect(() => {
    const handleLocationChange = () => {
      if (isOpen) {
        setVisible(false);
        setTimeout(() => setRender(false), delay);
        onClose();
      }
    };

    // If location changes and filters are open, close it
    if (location.pathname !== prevLocation.pathname) {
      handleLocationChange();
    }
    setPrevLocation(location);

    if (isOpen) {
      setRender(true);
      const timer = setTimeout(() => setVisible(true), 50);
      return () => clearTimeout(timer);
    } else {
      setVisible(false);
      const timer = setTimeout(() => setRender(false), delay);
      return () => clearTimeout(timer);
    }
  }, [isOpen, delay, location, onClose, prevLocation]);

  return (
    shouldRender && (
      <div
        className={`${styles.wrapper} ${
          isVisible ? styles.open : styles.closed
        }`}
      >
        {children}
      </div>
    )
  );
};

export default AnimatedWrapper;
