import { FC } from "react";

import styles from "./FilterInput.module.scss";

interface FilterInputProps {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  type?: string;
}

const FilterInput: FC<FilterInputProps> = ({
  value,
  onChange,
  placeholder = "",
  type = "text",
}) => {
  return (
    <input
      className={styles["input"]}
      type={type}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
    />
  );
};

export default FilterInput;
