/* eslint-disable react/prop-types */ // TODO: Define prop types
import React, { useState } from "react";
import Select from "react-select";
import PropTypes from "prop-types";

import api from "func/api";
import {
  fieldsSetting,
  getFiltReq,
  customStyles,
  eqStyle,
} from "./funcs/common.js";

const PresetFilters = ({
  setFilters,
  presets,
  userData,
  setUserData,
  nameColl,
  filtration,
}) => {
  const presetsHTML = presets.map((preset, i) => {
    return (
      <div
        className="save-preset-line"
        key={i}
      >
        <p
          className="save-preset-line__name"
          onClick={() => {
            const filtrationData = preset.filters.map((el) => ({ ...el }));
            setFilters(filtrationData);
            filtration(filtrationData);
          }}
        >
          {preset.name}
        </p>
        <span
          className="save-preset-line__delete"
          onClick={() => {
            userData.settings[nameColl].presets.splice(i, 1);
            api
              .postUser(userData)
              .then((result) => {
                delete result.role;
                setUserData({ ...userData, ...result });
              })
              .catch((err) => {
                console.error("err", err);
              });
          }}
        >
          <i className="bi bi-trash text-danger"></i>
        </span>
      </div>
    );
  });

  return <div>{presetsHTML}</div>;
};

PresetFilters.propTypes = {
  setFilters: PropTypes.func.isRequired,
  presets: PropTypes.array.isRequired,
  userData: PropTypes.object.isRequired,
  setUserData: PropTypes.func.isRequired,
  nameColl: PropTypes.string.isRequired,
  filtration: PropTypes.func.isRequired,
};

/**
 *
 * @param {{filters:object[]}} param0
 * @returns
 */

function FilterTable({
  schema,
  refDatas,
  nameColl,
  setData,
  filters,
  setFilters,
  typeAnd,
  setTypeAnd,
  getData,
  typeModel,
  search,
  pageNumber,
  limitInPage,
  selectOps,
  fields,
  userData,
  setUserData,
  setFilterOn,
  cms,
  inInfoCMS,
}) {
  const [typeFilter, setTypeFilter] = useState("filter");
  const [namePreset, setNamePreset] = useState("");
  const [showPreset, setShowPreset] = useState(false);

  if (!userData.settings) userData.settings = {};

  if (!userData.settings[cms]) userData.settings[cms] = {};

  const filtration = (filtersNew) => {
    if (inInfoCMS) {
      setData(filtersNew ?? filters);
    } else {
      getFiltReq({
        typeModel,
        nameColl,
        filters: filtersNew ?? filters,
        setData,
        typeAnd,
        search,
        pageNumber,
        limitInPage,
      });
      setFilterOn(false);
    }
  };

  const createFilterLineHTML = (el, i, iInGroup) => {
    return (
      <div
        className="row align-items-center position-relative mb-2 me-2"
        key={`filter-line-${iInGroup}`}
      >
        <div className="col-4">
          <Select
            options={selectOps}
            styles={customStyles}
            fields={fields}
            defaultValue={
              el.nameDB
                ? { value: el.nameDB, label: el.name, type: el.type }
                : null
            }
            onChange={(e) => {
              const data =
                iInGroup !== undefined
                  ? filters[i].elements.splice(iInGroup, 1)[0]
                  : filters.splice(i, 1)[0];
              data.nameDB = e.value;
              data.name = e.label;
              data.type = e.type;
              if (data.type === "ref") data.typeEq = "$eq";
              if (fieldsSetting[data.type] && fieldsSetting[data.type].vars)
                data.typeEq = fieldsSetting[data.type].vars[0].value;

              data.value = "";
              if (e.selectVars) data.selectVars = e.selectVars;
              if (iInGroup !== undefined) {
                filters[i].elements.splice(iInGroup, 0, data);
              } else {
                filters.splice(i, 0, data);
              }

              setFilters([...filters]);
            }}
          ></Select>
        </div>
        {el.type !== "ref" && (
          <div className="col-4 input-group">
            {fieldsSetting[el.type]?.typeInp !== "boolean" ? (
              <Select
                options={
                  fieldsSetting[el.type]
                    ? fieldsSetting[el.type].vars
                    : fieldsSetting.overVar.vars
                }
                isDisabled={!el.type}
                styles={eqStyle}
                // fields={fields}
                defaultValue={
                  el.typeEq && el.type && fieldsSetting[el.type]
                    ? fieldsSetting[el.type].vars.find(
                        (variant) => variant.value === el.typeEq
                      )
                    : el.type
                    ? fieldsSetting[el.type]
                      ? fieldsSetting[el.type].vars[0]
                      : fieldsSetting.overVar.vars[0]
                    : null
                }
                onChange={(e) => {
                  const data =
                    iInGroup !== undefined
                      ? filters[i].elements.splice(iInGroup, 1)[0]
                      : filters.splice(i, 1)[0];
                  data.typeEq = e.value;
                  if (iInGroup !== undefined) {
                    filters[i].elements.splice(iInGroup, 0, data);
                  } else {
                    filters.splice(i, 0, data);
                  }
                  setFilters([...filters]);
                }}
              ></Select>
            ) : (
              <div className="col btn-group">
                <button
                  type="button"
                  className={`btn ${
                    el.value === true ? "btn-primary" : "bg-light"
                  }`}
                  onClick={() => {
                    const data =
                      iInGroup !== undefined
                        ? filters[i].elements.splice(iInGroup, 1)[0]
                        : filters.splice(i, 1)[0];
                    data.value = true;
                    if (iInGroup !== undefined) {
                      filters[i].elements.splice(iInGroup, 0, data);
                    } else {
                      filters.splice(i, 0, data);
                    }
                    setFilters([...filters]);
                  }}
                >
                  ДА
                </button>
                <button
                  type="button"
                  className={`btn ${
                    el.value === false ? "btn-primary" : "bg-light"
                  }`}
                  onClick={() => {
                    const data =
                      iInGroup !== undefined
                        ? filters[i].elements.splice(iInGroup, 1)[0]
                        : filters.splice(i, 1)[0];
                    data.value = false;
                    if (iInGroup !== undefined) {
                      filters[i].elements.splice(iInGroup, 0, data);
                    } else {
                      filters.splice(i, 0, data);
                    }
                    setFilters([...filters]);
                  }}
                >
                  НЕТ
                </button>
              </div>
            )}
            {/* <select className="form-select pointer" id="inputGroupSelect01">
              <option selected>Равно</option>
              <option value="1">Не равно</option>
              <option value="2">Содержит</option>
              <option value="3">Не содержит</option>
            </select> */}
          </div>
        )}

        {fieldsSetting[el.type] !== undefined &&
          fieldsSetting[el.type].typeInp !== "boolean" && (
            <div className=" col input-group">
              {(fieldsSetting[el.type].typeInp === "input" ||
                fieldsSetting[el.type].typeInp === undefined) && (
                <input
                  className="form-control"
                  type="text"
                  value={el.value}
                  onChange={(e) => {
                    const data =
                      iInGroup !== undefined
                        ? filters[i].elements.splice(iInGroup, 1)[0]
                        : filters.splice(i, 1)[0];
                    data.value = e.target.value;
                    if (iInGroup !== undefined) {
                      filters[i].elements.splice(iInGroup, 0, data);
                    } else {
                      filters.splice(i, 0, data);
                    }

                    setFilters([...filters]);
                  }}
                />
              )}
              {fieldsSetting[el.type].typeInp === "number" && (
                <input
                  className="form-control"
                  type="number"
                  value={el.value}
                  onChange={(e) => {
                    const data =
                      iInGroup !== undefined
                        ? filters[i].elements.splice(iInGroup, 1)[0]
                        : filters.splice(i, 1)[0];
                    data.value = e.target.value;
                    if (iInGroup !== undefined) {
                      filters[i].elements.splice(iInGroup, 0, data);
                    } else {
                      filters.splice(i, 0, data);
                    }
                    setFilters([...filters]);
                  }}
                />
              )}
              {fieldsSetting[el.type].typeInp === "select" && (
                <Select
                  styles={eqStyle}
                  options={
                    el.type === "select" &&
                    el.selectVars &&
                    el.selectVars.length
                      ? el.selectVars.map((variant) => ({
                          label: variant,
                          value: variant,
                        }))
                      : refDatas[el.nameDB].map((ref) => ({
                          label: ref.name ? ref.name : ref._id,
                          value: ref._id,
                        }))
                  }
                  onChange={(e) => {
                    const data =
                      iInGroup !== undefined
                        ? filters[i].elements.splice(iInGroup, 1)[0]
                        : filters.splice(i, 1)[0];
                    data.value = e.value;
                    if (iInGroup !== undefined) {
                      filters[i].elements.splice(iInGroup, 0, data);
                    } else {
                      filters.splice(i, 0, data);
                    }
                    setFilters([...filters]);
                  }}
                />
              )}
            </div>
          )}
        <div
          className="position-absolute end-0 block-group-btn pointer d-flex justify-content-center"
          style={{ zIndex: 10 }}
        >
          <span
            className=""
            id="btnGroupDrop1"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="bi bi-three-dots text-secondary rotate" />
          </span>

          <ul
            className="dropdown-menu"
            aria-labelledby="btnGroupDrop1"
          >
            <li>
              <p
                className="dropdown-item m-0"
                onClick={() => {
                  if (iInGroup !== undefined) {
                    filters[i].elements.splice(iInGroup, 1);
                  } else {
                    filters.splice(i, 1);
                  }
                  setFilters([...filters]);
                }}
              >
                <i className="bi bi-trash fs-13 me-2"></i>
                Удалить
              </p>
            </li>
            <li>
              <p
                className="dropdown-item m-0"
                onClick={() => {
                  if (iInGroup !== undefined) {
                    filters[i].elements.push({ ...el });
                  } else {
                    filters.push({ ...el });
                  }

                  setFilters([...filters]);
                }}
              >
                <i className="bi bi-files-alt fs-13 me-2"></i>
                Дублировать
              </p>
            </li>
            {iInGroup === undefined && (
              <li>
                <p
                  className="dropdown-item m-0"
                  onClick={() => {
                    filters.splice(i, 1, {
                      group: true,
                      typeAnd: !typeAnd,
                      elements: [el],
                    });
                    setFilters([...filters]);
                  }}
                >
                  <i className="bi bi-arrow-repeat fs-13 me-2"></i>
                  Сделать группой
                </p>
              </li>
            )}
          </ul>
        </div>
      </div>
    );
  };
  const filterHTML = [];

  filters.forEach((el, i) => {
    if (el.group) {
      const filterGroupElem = [];
      el.elements.forEach((elInGroup, iInGroup) => {
        filterGroupElem.push(createFilterLineHTML(elInGroup, i, iInGroup));
      });
      filterHTML.push(
        <div
          key={`${i}-filter`}
          className="pt-2 pb-2"
          style={{
            marginLeft: "-10px",
            marginRight: "-10px",
            marginBottom: "10px",
            paddingLeft: "10px",
            paddingRight: "10px",
            borderRadius: "4px",
            backgroundColor: "#F7F8F9",
          }}
        >
          <div className="d-flex justify-content-between">
            <div className="btn-group mb-3">
              <button
                type="button"
                className={`btn ${el.typeAnd ? "btn-primary" : "bg-light"}`}
                onClick={() => {
                  filters[i].typeAnd = true;
                  setFilters([...filters]);
                }}
              >
                И
              </button>
              <button
                type="button"
                className={`btn ${!el.typeAnd ? "btn-primary" : "bg-light"}`}
                onClick={() => {
                  filters[i].typeAnd = false;
                  setFilters([...filters]);
                }}
              >
                ИЛИ
              </button>
            </div>

            <span
              onClick={() => {
                filters.splice(i, 1);
                setFilters([...filters]);
              }}
            >
              <i className="bi bi-x text-danger rounded bg-white" />
            </span>
          </div>
          {filterGroupElem}
          <div className="w-100 mt-2  d-flex justify-content-center">
            <div
              className="block-group-btn pointer d-flex justify-content-center"
              onClick={() => {
                filters[i].elements.push({});
                setFilters([...filters]);
              }}
            >
              <i className="bi bi-plus text-primary" />
            </div>
          </div>
        </div>
      );
    } else {
      filterHTML.push(createFilterLineHTML(el, i));
    }
  });

  return (
    <>
      <div className="d-flex bg-light align-items-center mb-4 p-3">
        <h6 className="m-0">{showPreset ? "Пресеты фильтров" : "Фильтры"}</h6>
        <div className="ms-auto">
          {filterHTML.length > 0 ? (
            <>
              <button
                className="btn btn-outline-primary me-2"
                onClick={() => {
                  if (inInfoCMS) {
                    setData([]);
                  } else {
                    setFilters([]);
                    getData();
                  }
                }}
              >
                Сбросить
              </button>
              <div className="btn-group">
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={() => {
                    switch (typeFilter) {
                      case "save":
                        if (!userData.settings) {
                          userData.settings = {};
                        }

                        if (!userData.settings[cms])
                          userData.settings[cms] = {};

                        if (userData.settings[cms][nameColl]) {
                          if (userData.settings[cms][nameColl].presets)
                            userData.settings[cms][nameColl].presets.push({
                              name: namePreset,
                              filters: filters,
                            });
                          else
                            userData.settings[cms][nameColl].presets = [
                              {
                                name: namePreset,
                                filters: filters,
                              },
                            ];
                        } else {
                          userData.settings[cms][nameColl] = {
                            presets: [
                              {
                                name: namePreset,
                                filters: filters,
                              },
                            ],
                          };
                        }

                        api
                          .postProfile(userData)
                          .then((result) => {
                            delete result.role;
                            setUserData({ ...userData, ...result });
                            setFilters([]);
                            setFilterOn(false);
                          })
                          .catch((err) => {
                            console.error("err :>> ", err);
                          });
                        break;
                      case "all":
                        if (!userData.settings) {
                          userData.settings = {};
                        }
                        if (!userData.settings[cms])
                          userData.settings[cms] = {};
                        if (userData.settings[cms][nameColl]) {
                          if (userData.settings[cms][nameColl].presets)
                            userData.settings[cms][nameColl].presets.push({
                              name: namePreset,
                              filters: filters,
                            });
                          else
                            userData.settings[cms][nameColl].presets = [
                              {
                                name: namePreset,
                                filters: filters,
                              },
                            ];
                        } else {
                          userData.settings[cms][nameColl] = {
                            presets: [
                              {
                                name: namePreset,
                                filters: filters,
                              },
                            ],
                          };
                        }

                        api
                          .postProfile(userData)
                          .then((result) => {
                            delete result.role;
                            setUserData({ ...userData, ...result });
                            filtration();
                          })
                          .catch((err) => {
                            console.error("err :>> ", err);
                          });
                        break;

                      default:
                        filtration();
                        break;
                    }
                  }}
                >
                  {typeFilter === "filter"
                    ? "Применить"
                    : typeFilter === "save"
                    ? "Сохранить"
                    : "Сохранить и применить"}
                </button>
                {!inInfoCMS && (
                  <button
                    type="button"
                    className="btn btn-primary dropdown-toggle dropdown-toggle-split"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span className="bi bi-caret-down"></span>
                  </button>
                )}
                <ul className="dropdown-menu">
                  <li
                    onClick={() => {
                      setTypeFilter("save");
                    }}
                  >
                    <p className="dropdown-item m-0">Сохранить пресет</p>
                  </li>
                  <li
                    onClick={() => {
                      setTypeFilter("all");
                    }}
                  >
                    <p className="dropdown-item m-0">Сохранить и применить</p>
                  </li>
                </ul>
              </div>
            </>
          ) : userData?.settings[cms][nameColl]?.presets?.length ||
            showPreset ? (
            <button
              className="btn btn-primary"
              type="button"
              onClick={() => {
                setShowPreset((b) => !b);
              }}
            >
              {showPreset ? "Фильтры" : "Пресеты"}
            </button>
          ) : null}
        </div>
      </div>
      {typeFilter !== "filter" && (
        <div className=" input-group mb-3 pe-3 ps-3">
          <span className="input-group-text">Название</span>
          <input
            className="form-control"
            type="text"
            value={namePreset}
            onChange={(e) => {
              setNamePreset(e.target.value);
            }}
          />
        </div>
      )}
      <div className="p-3 pt-0">
        {filterHTML.length > 1 && (
          <div className="btn-group mb-3">
            <button
              type="button"
              className={`btn ${typeAnd ? "btn-primary" : "bg-light"}`}
              onClick={() => setTypeAnd(true)}
            >
              И
            </button>
            <button
              type="button"
              className={`btn ${!typeAnd ? "btn-primary" : "bg-light"}`}
              onClick={() => setTypeAnd(false)}
            >
              ИЛИ
            </button>
          </div>
        )}
        {showPreset ? (
          <PresetFilters
            presets={userData?.settings[cms][nameColl]?.presets}
            userData={userData}
            setUserData={setUserData}
            setFilters={setFilters}
            nameColl={nameColl}
            filtration={filtration}
          />
        ) : filterHTML.length ? (
          filterHTML
        ) : (
          "Вы можете использовать фильтры, группу фильтров или сохраненные пресеты"
        )}

        {!showPreset && (
          <div className="w-100 mt-4 d-flex justify-content-center">
            <div
              className="block-group-btn pointer d-flex justify-content-center"
              onClick={() => {
                setFilters([...filters, {}]);
              }}
            >
              <i className="bi bi-plus text-primary" />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

// FilterTable.propTypes = {
//   refDatas: PropTypes.array.isRequired,
//   nameColl: PropTypes.string.isRequired,
//   setData: PropTypes.func.isRequired,
//   filters: PropTypes.array.isRequired,
//   setFilters: PropTypes.func.isRequired,
//   typeAnd: PropTypes.bool.isRequired,
//   setTypeAnd: PropTypes.func.isRequired,
//   getData: PropTypes.func.isRequired,
//   typeModel: PropTypes.string.isRequired,
//   search: PropTypes.string.isRequired,
//   pageNumber: PropTypes.number.isRequired,
//   limitInPage: PropTypes.number.isRequired,
//   selectOps: PropTypes.object.isRequired,
//   fields: PropTypes.array.isRequired,
//   userData: PropTypes.object.isRequired,
//   setUserData: PropTypes.func.isRequired,
//   setFilterOn: PropTypes.func.isRequired,
//   cms: PropTypes.string.isRequired,
//   inInfoCMS: PropTypes.bool.isRequired,
//   schema: PropTypes.object.isRequired, // added schema prop validation
// };

export default FilterTable;
