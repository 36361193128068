import { createSelector } from "@reduxjs/toolkit";
import { selectHaveChangesInFieldVisibility } from "store/selectors/fieldVisibilitySelectors";
import { selectHaveChangesInSorting } from "store/selectors/sortingSelectors";
import { selectHaveChangesInFilters } from "store/selectors/filterSelectors";
import { RootState } from "store/store";

export const selectCurrentViewConfigurationId = (state: RootState) =>
  state.collection.pageInfo.currentViewConfigurationId;

const selectViewConfigurations = (state: RootState) =>
  state.collection.pageInfo.viewConfigurations || [];

const selectCurrentViewConfiguration = createSelector(
  selectCurrentViewConfigurationId,
  selectViewConfigurations,
  (currentViewConfigurationId, viewConfigurations) => {
    if (!currentViewConfigurationId) {
      return viewConfigurations[0];
    }

    return viewConfigurations.find(
      (viewConfiguration) =>
        viewConfiguration._id === currentViewConfigurationId
    );
  }
);

const selectHaveChangesInViewConfiguration = (state: RootState) =>
  selectHaveChangesInFieldVisibility(state) ||
  selectHaveChangesInSorting(state) ||
  selectHaveChangesInFilters(state);

export { selectCurrentViewConfiguration, selectHaveChangesInViewConfiguration };
