/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import ModalOvelay from "./ModalOverlay";

/**
 *
 * @param {{title:string,text:string, open:boolean, closePopUp:()=>void, deleteFunc:()=>void, additString:string}} param0
 * @returns
 */
const ModalDelete = ({
  title,
  text,
  open,
  closePopUp,
  deleteFunc,
  additString,
  disabled,
}) => {
  return (
    <ModalOvelay
      open={open}
      closePopUp={closePopUp}
    >
      <div
        className={`modal  fade ${open && "show"}`}
        tabIndex="-1"
      >
        <div className="modal-dialog modal-m modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{title}</h5>
              <button
                type="button"
                className="btn-close"
                onClick={closePopUp}
              ></button>
            </div>
            <div className="modal-body">
              <p className="m-0">{text}</p>
              {additString}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={closePopUp}
              >
                Отменить
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => deleteFunc() || closePopUp()}
                disabled={disabled ?? false}
              >
                Удалить
              </button>
            </div>
          </div>
        </div>
      </div>
    </ModalOvelay>
  );
};

export default ModalDelete;
