import { FC } from "react";

const HashIcon: FC = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.34125 11.0671C7.33328 11.1192 7.3289 11.1719 7.32813 11.2246C7.32813 11.4915 7.51188 11.6691 7.76563 11.6691C7.99838 11.6691 8.19613 11.5186 8.25125 11.2517L8.736 8.8866H9.7895C10.1579 8.8866 10.3294 8.68185 10.3294 8.40798C10.3294 8.13498 10.1649 7.94423 9.7895 7.94423H8.92763L9.37913 5.73573H10.486C10.8623 5.73573 11.0268 5.5371 11.0268 5.2571C11.0268 4.98323 10.8623 4.7986 10.486 4.7986H9.57075L9.98725 2.7826C9.99563 2.73521 10.0003 2.68723 10.0013 2.6391C10.002 2.57962 9.99076 2.52059 9.96832 2.46549C9.94588 2.4104 9.91265 2.36034 9.87058 2.31828C9.82851 2.27621 9.77846 2.24298 9.72336 2.22054C9.66826 2.1981 9.60924 2.1869 9.54975 2.1876C9.4396 2.1855 9.33219 2.22195 9.24607 2.29066C9.15995 2.35936 9.10055 2.45599 9.07813 2.56385L8.6205 4.7986H6.66488L7.08225 2.7826C7.08925 2.7476 7.09538 2.67935 7.09538 2.6391C7.09586 2.57916 7.0843 2.51973 7.0614 2.46433C7.03849 2.40893 7.00471 2.35869 6.96203 2.31659C6.91936 2.27449 6.86867 2.24138 6.81297 2.21922C6.75727 2.19707 6.69768 2.18631 6.63775 2.1876C6.52876 2.18697 6.42291 2.22411 6.3382 2.2927C6.2535 2.36129 6.19516 2.45711 6.17313 2.56385L5.71375 4.7986H4.73725C4.361 4.7986 4.19737 4.9911 4.19737 5.2641C4.19737 5.5371 4.361 5.73573 4.73725 5.73573H5.53L5.07937 7.94335H4.03288C3.6645 7.94335 3.5 8.13498 3.5 8.40798C3.5 8.68185 3.6645 8.8866 4.03375 8.8866H4.88775L4.43625 11.0671C4.42925 11.1021 4.42312 11.1765 4.42312 11.2246C4.42312 11.4915 4.60687 11.6691 4.86062 11.6691C5.0925 11.6691 5.29113 11.5186 5.34538 11.2517L5.831 8.8866H7.79275L7.34213 11.0671H7.34125ZM6.46625 5.72173H8.449L7.99837 7.96435H6.00163L6.46712 5.72173H6.46625Z"
        fill="inherit"
      />
    </svg>
  );
};

export default HashIcon;
