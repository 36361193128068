/* eslint-disable react/prop-types */
import { useCallback, useMemo, useState } from "react";
import Select from "components/Select/Select";
import { slugify } from "transliteration";

// TODO: Need to decouple this component into smaller pieces
export default function EditFieldModal({
  dataField,
  setDataField,
  componentsSelect,
  schemasSelect,
  data,
  doesNameDBDuplicateExist,
}) {
  const shouldRenderStandardValueInput = useMemo(() => {
    return dataField.typeField !== "text" && dataField.typeField !== "date";
  }, [dataField.typeField]);

  const refValueForSelect = useMemo(
    () => schemasSelect.find((option) => option.value === dataField.refTo),
    [dataField.refTo, schemasSelect],
  );

  const componentValueForSelect = useMemo(
    () =>
      componentsSelect.find(
        (option) => option.value?._id === dataField.component?._id,
      ),
    [dataField.component, componentsSelect],
  );

  const automationType = useMemo(() => {
    if (!dataField._id && !dataField.settingField) {
      return null;
    }

    const option = {
      value: "Slugifator",
      label: "slugifator",
    };

    switch (dataField.settingField) {
      case "=":
        option.label = option.value = "=";
        break;
      case ">":
        option.label = option.value = ">";
        break;
      case "<":
        option.label = option.value = "<";
        break;
      default:
        option.label = "Slugifator";
        option.value = "slugifator";
    }

    return option;
  }, [dataField?._id, dataField?.settingField]);

  const automatizationOptions = useMemo(() => {
    return data.fields
      .filter((option) => dataField.nameDB !== option.nameDB)
      .map((option) => {
        return {
          label: option?.name,
          value: option?.nameDB,
        };
      });
  }, [data.fields, dataField.nameDB]);

  const automationDefaultValue = useMemo(() => {
    const defaultOption = automatizationOptions.find(
      (option) => option.value === dataField?.data?.nameDBField,
    );

    return defaultOption;
  }, [automatizationOptions, dataField?.data?.nameDBField]);

  // for comparison automatization
  const comparisonDefaultValue = useMemo(() => {
    // to prevent error opening slugifator automatization
    // because it uses only one field, not an array
    if (!dataField?.data?.nameDBFields) {
      return [];
    }

    const optionField1 = data.fields.find(
      (field) => field.nameDB === dataField.data?.nameDBFields[0],
    );
    const optionField2 = data.fields.find(
      (field) => field.nameDB === dataField.data?.nameDBFields[1],
    );

    return [
      optionField1 && {
        label: optionField1?.name,
        value: optionField1?.nameDB,
      },
      optionField2 && {
        label: optionField2?.name,
        value: optionField2?.nameDB,
      },
    ];
  }, [data.fields, dataField.data?.nameDBFields]);

  const [dbName, setDbName] = useState(dataField.nameDB);

  // TODO: Might be better to move this function to some utils file
  const slugifyWithCamelCase = useCallback((str = "") => {
    let sluggedName = slugify(str, {
      replace: [["_", "-"]],
      separator: "-",
    });

    sluggedName = sluggedName.replace(/-.?-*/gi, function (str) {
      return str.toUpperCase().replace(/-/g, "");
    });

    return sluggedName;
  }, []);

  const [fieldName, setFieldName] = useState(dataField.name);

  const handleChangeComparisonField = useCallback(
    (index) => (e) => {
      const value = e.value;

      if (dataField.data?.nameDBFields) {
        dataField.data.nameDBFields[index] = value;
        dataField.data.nameDBFields = [...dataField.data.nameDBFields];
      } else {
        dataField.data = { nameDBFields: [null, null] };
        dataField.data.nameDBFields[index] = value;
      }

      setDataField({ ...dataField });
    },
    [dataField, setDataField],
  );

  return (
    <div className="modal-body edit-field">
      <div className="row">
        <div className="col col-6">
          <p className="flexim-headline">Name</p>
          <div className="input-group" style={{ marginBottom: "20px" }}>
            <input
              className="flexim-input"
              type="text"
              value={fieldName}
              onChange={(e) => {
                const inputValue = e.target.value;
                const isNewField = !data._id || !dataField._id;
                const slugifiedDBName = isNewField
                  ? slugifyWithCamelCase(inputValue)
                  : dbName;

                setFieldName(inputValue);
                // If the field is new, set the nameDB to the slugified name
                if (isNewField) {
                  setDbName(slugifiedDBName);
                }
                setDataField({
                  ...dataField,
                  name: inputValue,
                  nameDB: slugifiedDBName,
                });
              }}
            />
          </div>
          {"settingField" in dataField &&
            (dataField.typeField === "automat" ? (
              <>
                <p className="flexim-headline">Automation type</p>
                <div className="input-group" style={{ marginBottom: "20px" }}>
                  <Select
                    disabled={dataField._id}
                    options={[
                      { label: "Slugifator", value: "slugifator" },
                      { label: "=", value: "=" },
                      { label: ">", value: ">" },
                      { label: "<", value: "<" },
                    ]}
                    value={automationType}
                    className="select-modal"
                    onChange={(e) => {
                      dataField.settingField = e.value;
                      setDataField({ ...dataField, data: null });
                    }}
                  />
                </div>
                {dataField.settingField === "slugifator" && (
                  <>
                    <p className="flexim-headline">Field to slug</p>
                    <div
                      className="input-group"
                      style={{ marginBottom: "20px" }}
                    >
                      <Select
                        disabled={dataField._id}
                        options={automatizationOptions}
                        value={automationDefaultValue}
                        className="select-modal"
                        onChange={(e) => {
                          dataField.data = { nameDBField: e.value };
                          setDataField({ ...dataField });
                        }}
                      />
                    </div>
                  </>
                )}
                {(dataField.settingField === ">" ||
                  dataField.settingField === "<" ||
                  dataField.settingField === "=") && (
                  <>
                    <p className="flexim-headline">Field 1</p>
                    <div
                      className="input-group"
                      style={{ marginBottom: "20px" }}
                    >
                      <Select
                        disabled={dataField._id}
                        options={automatizationOptions}
                        value={comparisonDefaultValue[0]}
                        className="select-modal"
                        onChange={handleChangeComparisonField(0)}
                        isControlled
                      />
                    </div>
                    <p className="flexim-headline">Field 2</p>
                    <div
                      className="input-group"
                      style={{ marginBottom: "20px" }}
                    >
                      <Select
                        disabled={dataField._id}
                        options={automatizationOptions}
                        value={comparisonDefaultValue[1]}
                        className="select-modal"
                        onChange={handleChangeComparisonField(1)}
                        isControlled
                      />
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                <p className="flexim-headline">Type</p>
                <div
                  className={`form-check form-check__radio mb-2${
                    dataField.settingField === "input"
                      ? " form-check__radio--active"
                      : ""
                  }`}
                  onClick={(e) => {
                    dataField.settingField = "input";

                    setDataField({ ...dataField });
                  }}
                >
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                    checked={dataField.settingField === "input"}
                    onChange={(e) => {
                      dataField.settingField = e.target.checked
                        ? "input"
                        : "textarea";
                      setDataField({ ...dataField });
                    }}
                  />
                  <p
                    className="form-check-label fs-12"
                    htmlFor="flexRadioDefault1"
                  >
                    Short text
                    <br />
                    <span>
                      Titles, headlines, links etc. (involved in search)
                    </span>
                  </p>
                </div>
                <div
                  className={`form-check form-check__radio mb-2${
                    dataField.settingField === "textarea"
                      ? " form-check__radio--active"
                      : ""
                  }`}
                  onClick={(e) => {
                    dataField.settingField = "textarea";

                    setDataField({ ...dataField });
                  }}
                >
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault2"
                    checked={dataField.settingField === "textarea"}
                    onChange={(e) => {
                      dataField.settingField = e.target.checked
                        ? "textarea"
                        : "input";
                      setDataField({ ...dataField });
                    }}
                  />
                  <p className="form-check-label" htmlFor="flexRadioDefault2">
                    Long text
                    <br />
                    <span>
                      Descriptions, biographies etc. (not involved in the
                      search)
                    </span>
                  </p>
                </div>
                <div className="slash my-3"></div>
              </>
            ))}
          {"separator" in dataField && (
            <>
              <p className="flexim-headline">Divider</p>
              <div className="input-group" style={{ marginBottom: "20px" }}>
                <Select
                  options={[
                    { label: " , ", value: "," },
                    { label: " | ", value: "|" },
                  ]}
                  value={{
                    label: dataField.separator,
                    value: dataField.separator,
                  }}
                  onChange={(e) => {
                    dataField.separator = e.value;
                    setDataField({ ...dataField });
                  }}
                />
              </div>
            </>
          )}
          {dataField.typeField === "component" && (
            <>
              <p className="flexim-headline">Connect to collection</p>
              <div className="input-group" style={{ marginBottom: "20px" }}>
                <Select
                  disabled={dataField._id}
                  options={componentsSelect}
                  value={componentValueForSelect}
                  className="select-modal"
                  onChange={(e) => {
                    const componentName = e.value.name; // TODO: Need TypeScript; e.value: Server -> ComponentSchema

                    const slugifiedDBName = slugifyWithCamelCase(componentName);
                    setFieldName(componentName);
                    setDbName(slugifiedDBName);

                    setDataField({
                      ...dataField,
                      component: e.value,
                      name: componentName,
                      nameDB: slugifiedDBName,
                    });
                  }}
                />
              </div>
              <p className="flexim-headline">Type</p>
              <div
                className={`form-check form-check__radio mb-2${
                  !dataField.isMulti ? " form-check__radio--active" : ""
                }`}
                onClick={() => {
                  setDataField({ ...dataField, isMulti: false });
                }}
              >
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault1"
                  checked={!dataField.isMulti}
                  onChange={(e) => {
                    dataField.isMulti = !e.target.checked;
                    setDataField({ ...dataField });
                  }}
                />
                <p className="form-check-label" htmlFor="flexRadioDefault1">
                  Single component
                  <br />
                  <span>
                    For grouping fields such as full address, basic information,
                    etc.
                  </span>
                </p>
              </div>
              <div
                className={`form-check form-check__radio mb-2${
                  dataField.isMulti ? " form-check__radio--active" : ""
                }`}
                onClick={() => {
                  setDataField({ ...dataField, isMulti: true });
                }}
              >
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                  checked={dataField.isMulti}
                  onChange={(e) => {
                    dataField.isMulti = e.target.checked;
                    setDataField({ ...dataField });
                  }}
                />
                <p className="form-check-label" htmlFor="flexRadioDefault2">
                  Repeated component
                  <br />
                  <span>
                    For multiple occurrences (arrays) of ingredients, meta tags,
                    etc.
                  </span>
                </p>
              </div>
              <div className="slash my-3"></div>
            </>
          )}
          {dataField.typeField === "ref" && (
            <>
              <div className="mb-2">
                <p className="flexim-headline">Connect to collection</p>
                <Select
                  options={schemasSelect}
                  value={refValueForSelect}
                  onChange={(e) => {
                    dataField.refTo = e.value;
                    setDataField({ ...dataField });
                  }}
                />
              </div>
              <p className="flexim-headline">Type</p>
              <div
                className={`form-check form-check__radio mb-2${
                  !dataField.isMulti ? " form-check__radio--active" : ""
                }`}
                onClick={() => {
                  setDataField({ ...dataField, isMulti: false });
                }}
              >
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault1"
                  checked={!dataField.isMulti}
                  onChange={(e) => {
                    dataField.isMulti = !e.target.checked;
                    setDataField({ ...dataField });
                  }}
                />
                <p className="form-check-label" htmlFor="flexRadioDefault1">
                  Single connection
                  <br />
                  <span>Link to a single item</span>
                </p>
              </div>
              <div
                className={`form-check form-check__radio mb-2${
                  dataField.isMulti ? " form-check__radio--active" : ""
                }`}
                onClick={() => {
                  setDataField({ ...dataField, isMulti: true });
                }}
              >
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                  checked={dataField.isMulti}
                  onChange={(e) => {
                    dataField.isMulti = e.target.checked;
                    setDataField({ ...dataField });
                  }}
                />
                <p className="form-check-label" htmlFor="flexRadioDefault2">
                  Multiple connections
                  <br />
                  <span>A reference to a set of items</span>
                </p>
              </div>
              <div className="slash my-3"></div>
            </>
          )}
          {dataField.typeField === "image" && (
            <>
              <p className="flexim-headline">Type</p>
              <div
                className={`form-check form-check__radio mb-2${
                  !dataField.isMulti ? " form-check__radio--active" : ""
                }`}
                onClick={() => {
                  setDataField({ ...dataField, isMulti: false });
                }}
              >
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                  checked={!dataField.isMulti}
                  onChange={(e) => {
                    dataField.isMulti = !e.target.checked;
                    setDataField({ ...dataField });
                  }}
                />
                <p className="form-check-label" htmlFor="flexRadioDefault2">
                  Single Media
                  <br />
                  <span>Avatars, profile pictures, etc.</span>
                </p>
              </div>
              <div
                className={`form-check form-check__radio mb-2${
                  dataField.isMulti ? " form-check__radio--active" : ""
                }`}
                onClick={() => {
                  setDataField({ ...dataField, isMulti: true });
                }}
              >
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault1"
                  checked={dataField.isMulti}
                  onChange={(e) => {
                    dataField.isMulti = e.target.checked;
                    setDataField({ ...dataField });
                  }}
                />
                <p className="form-check-label" htmlFor="flexRadioDefault1">
                  Multiple Media <br />
                  <span>Sliders, carousels, etc.</span>
                </p>
              </div>
            </>
          )}
          {dataField.typeField === "select" && (
            <>
              <p className="flexim-headline">Options (one line — one option)</p>
              <div className="input-group" style={{ marginBottom: "20px" }}>
                <textarea
                  className="form-control flexim-input"
                  defaultValue={dataField.selectVars.reduce((str, el) => {
                    return str + el + "\n";
                  }, "")}
                  onChange={(e) => {
                    const options = e.target.value.split("\n");
                    setDataField({
                      ...dataField,
                      selectVars: options.filter((el) => el !== ""),
                    });
                  }}
                />
              </div>
            </>
          )}
          {/* {"defaultValue" in dataField && (
            <>
              {shouldRenderStandardValueInput && <p>Стандартное значение</p>}
              {dataField.typeField === "boolean" ? (
                <div className="btn-group">
                  <button
                    type="button"
                    className={`btn ${
                      dataField.defaultValue === true
                        ? "btn-primary"
                        : "bg-light"
                    }`}
                    onClick={() => {
                      dataField.defaultValue = true;
                      setDataField({ ...dataField });
                    }}
                  >
                    True
                  </button>
                  <button
                    type="button"
                    className={`btn ${
                      dataField.defaultValue === "" ? "btn-primary" : "bg-light"
                    }`}
                    onClick={() => {
                      dataField.defaultValue = "";
                      setDataField({ ...dataField });
                    }}
                  >
                    Null
                  </button>
                  <button
                    type="button"
                    className={`btn ${
                      dataField.defaultValue === false
                        ? "btn-primary"
                        : "bg-light"
                    }`}
                    onClick={() => {
                      dataField.defaultValue = false;
                      setDataField({ ...dataField });
                    }}
                  >
                    False
                  </button>
                </div>
              ) : shouldRenderStandardValueInput ? (
                <div className="input-group mb-2">
                  <input
                    className="form-control"
                    type="text"
                    value={dataField.defaultValue}
                    onChange={(e) => {
                      dataField.defaultValue = e.target.value;
                      setDataField({ ...dataField });
                    }}
                  />
                </div>
              ) : null}
            </>
          )} */}
          {/* {"regexpField" in dataField && (
            <>
              <p>Шаблон регулярного выражения</p>
              <div className="input-group mb-2">
                <input
                  className="form-control"
                  type="text"
                  value={dataField.regexpField}
                  onChange={(e) => {
                    dataField.regexpField = e.target.value;
                    setDataField({ ...dataField });
                  }}
                />
              </div>
            </>
          )} */}
        </div>
        <div className="col col-6">
          <p className="flexim-headline">Settings</p>
          {"requiredField" in dataField && (
            <>
              <div className="form-check" style={{ marginBottom: "20px" }}>
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="requiredField"
                  checked={dataField.requiredField}
                  onChange={(e) => {
                    dataField.requiredField = e.target.checked;
                    setDataField({ ...dataField });
                  }}
                />
                <label
                  className="form-check-label pointer"
                  htmlFor="requiredField"
                >
                  Required field
                </label>
                <p className="check-discl description m-0">
                  You can’t create a record if this field is not filled
                </p>
              </div>
            </>
          )}
          {/* {"uniqueField" in dataField && (
            <>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="uniqueField"
                  checked={dataField.uniqueField}
                  onChange={(e) => {
                    dataField.uniqueField = e.target.checked;
                    setDataField({ ...dataField });
                  }}
                />
                <label
                  className="form-check-label"
                  htmlFor="uniqueField"
                >
                  Уникальное поле
                </label>
              </div>
              <p className="check-discl description">
                Вы не можете создать запись, если уже существует запись с таки
                значением
              </p>
            </>
          )} */}

          <div className="form-check" style={{ marginBottom: "20px" }}>
            <input
              className="form-check-input"
              type="checkbox"
              id="closeField"
              checked={dataField.closeField}
              onChange={(e) => {
                dataField.closeField = e.target.checked;
                setDataField({ ...dataField });
              }}
            />
            <label className="form-check-label pointer" htmlFor="closeField">
              Closed field
            </label>
            <p className="check-discl description m-0">
              The field don’t participate in the REST API output
            </p>
          </div>
          {/* {"maxLengthField" in dataField && (
            <div className="row">
              <div className="col col-6">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="maxLengthField"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="maxLengthField"
                  >
                    Максимальная длина
                  </label>
                </div>
                <input
                  className="form-control"
                  type="number"
                  onChange={(e) => {
                    dataField.maxLengthField = e.target.value;
                    setDataField({ ...dataField });
                  }}
                />
              </div>
              <div className="col col-6">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="minLengthField"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="minLengthField"
                  >
                    Минимальная длина
                  </label>
                </div>
                <input
                  className="form-control"
                  type="number"
                  onChange={(e) => {
                    dataField.minLengthField = e.target.value;
                    setDataField({ ...dataField });
                  }}
                />
              </div>
            </div>
          )} */}

          {"isMulti" in dataField &&
            dataField.typeField !== "ref" &&
            dataField.typeField !== "image" &&
            dataField.typeField !== "component" && (
              <>
                <div className="form-check" style={{ marginBottom: "20px" }}>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="isMulti"
                    checked={dataField.isMulti}
                    disabled={dataField._id}
                    onChange={(e) => {
                      dataField.isMulti = e.target.checked;
                      setDataField({ ...dataField });
                    }}
                  />
                  <label
                    className={`form-check-label${
                      dataField._id ? "" : " pointer"
                    }`}
                    htmlFor="isMulti"
                  >
                    Multiselect
                  </label>
                  <p className="check-discl description m-0">
                    Select multiple options
                  </p>
                </div>
              </>
            )}

          <div className="row">
            <div className="col col-12">
              <label className="flexim-headline" htmlFor="nameDB">
                Name in DB
              </label>

              <input
                className="flexim-input"
                type="text"
                value={dbName}
                disabled
              />

              {doesNameDBDuplicateExist && (!data._id || !dataField._id) && (
                <span
                  style={{
                    paddingTop: "5px",
                    color: "#F04343",
                    fontSize: "14px",
                  }}
                >
                  This name is already used in the database
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
