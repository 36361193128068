import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { COLLECTION_CONTROL_PANEL } from "constants/index";
import { getVisibilityFieldTypes } from "helpers/getVisibilityFieldTypes";
import {
  fetchCollectionSchema,
  setCurrentViewConfiguration,
} from "store/thunks/collectionPageInfoThunks";
import type { Field } from "typings/filter";
import config from "config/config.json";
import { apiService } from "api/apiService";

export type VisibleField = {
  fieldId: string;
  id: Field["nameDB"];
  name: Field["name"];
  isVisible: boolean;
  type: Field["type"];
  order: number;
};

const initialState: {
  visibleFields: VisibleField[];
  initiallyVisibleFields: VisibleField[];
  visibleFieldsAfterExclusion: Field[];
} = {
  visibleFields: [],
  initiallyVisibleFields: [],
  visibleFieldsAfterExclusion: [],
};

export const fieldVisibilitySlice = createSlice({
  name: COLLECTION_CONTROL_PANEL.VISIBILITY,
  initialState,
  reducers: {
    toggleVisibility: (
      state,
      action: PayloadAction<{
        id: VisibleField["id"];
      }>
    ) => {
      const { id } = action.payload;
      const index = state.visibleFields.findIndex((f) => f.id === id);

      state.visibleFields[index].isVisible =
        !state.visibleFields[index].isVisible;
    },
    setVisibilityFieldsOrder: (
      state,
      action: PayloadAction<VisibleField[]>
    ) => {
      state.visibleFields = action.payload;
    },
    resetToInitialState: (state) => {
      state.visibleFields = state.initiallyVisibleFields;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCollectionSchema.fulfilled, (state, action) => {
        const { excludedFields } = config.collection.visibility;
        const {
          structuredData,
          viewConfigurations,
          currentViewConfigurationId,
        } = action.payload;

        const currentViewConfiguration = viewConfigurations.find(
          (viewConfiguration: any) =>
            viewConfiguration._id === currentViewConfigurationId
        );

        const visibilityFields = getVisibilityFieldTypes(structuredData).filter(
          // We don't want to be able to toggle visiblity of the name and slug fields
          (field) => !excludedFields.includes(field.nameDB)
        );

        let fields: VisibleField[] = [];
        if (currentViewConfiguration.fieldVisibilities.length > 0) {
          fields = visibilityFields.map((field, index) => {
            const fieldVisibility =
              currentViewConfiguration.fieldVisibilities.find(
                (fieldVisibility: any) =>
                  fieldVisibility.fieldId === field.fieldId
              );

            return {
              fieldId: field.fieldId,
              id: field.nameDB,
              name: field.name,
              isVisible: fieldVisibility ? fieldVisibility.visible : index < 8,
              type: field.type,
              order: fieldVisibility ? fieldVisibility.order : index,
            };
          });
        } else {
          fields = visibilityFields.map((field, index) => ({
            fieldId: field.fieldId,
            id: field.nameDB,
            name: field.name,
            isVisible: index < 8, // Only 8 fields are visible by default
            type: field.type,
            order: index,
          }));
        }

        state.visibleFields = fields;
        state.initiallyVisibleFields = fields;
        state.visibleFieldsAfterExclusion = visibilityFields;
      })
      .addCase(setCurrentViewConfiguration.fulfilled, (state, action) => {
        const currentViewConfiguration = action.payload;

        if (!currentViewConfiguration) return;

        const { fieldVisibilities } = currentViewConfiguration;

        const initialFields = state.visibleFieldsAfterExclusion.map(
          (field: any, index) => ({
            fieldId: field.fieldId,
            id: field.nameDB,
            name: field.name,
            isVisible: index < 8,
            type: field.type,
            order: index,
          })
        );

        const fields = createFields(fieldVisibilities, initialFields);

        state.visibleFields = fields;
        state.initiallyVisibleFields = fields;
      })
      .addMatcher(
        apiService.endpoints.updateViewConfiguration.matchFulfilled,
        (state, action) => {
          const { data } = action.payload;
          if (!data) return;
          const { fieldVisibilities } = data;

          const fields = createFields(fieldVisibilities, state.visibleFields);

          state.visibleFields = fields;
          state.initiallyVisibleFields = fields;
        }
      );
  },
});

const createFields = (fieldVisibilities: any[], initialFields: any[]) => {
  const fieldsFromVisibilities = fieldVisibilities.map((field: any) => ({
    fieldId: field.fieldId || field.fieldInfo.id,
    id: field.fieldInfo.nameDB,
    name: field.fieldInfo.name,
    isVisible: field.visible,
    type: field.fieldInfo.type,
    order: field.order,
  }));

  const filteredInitialFields = initialFields.filter(
    (initialField) =>
      !fieldVisibilities.some(
        (fieldVisibility) =>
          fieldVisibility.fieldInfo.id === initialField.fieldId
      )
  );

  return fieldsFromVisibilities.concat(filteredInitialFields);
};

export const {
  toggleVisibility,
  setVisibilityFieldsOrder,
  resetToInitialState,
} = fieldVisibilitySlice.actions;

export default fieldVisibilitySlice.reducer;
