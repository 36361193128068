/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback, useMemo } from "react";
import { ReactSortable } from "react-sortablejs";
import api from "func/api";
import ModalDelete from "../common/ModalDelete";

const getData = (setData) => {
  api
    .getSettingsSelect("menu")
    .then((menuSettings) => {
      setData(menuSettings.settings.menu);
    })
    .catch((err) => {
      console.log(`err`, err);
    });
};

const getIndex = (arr, name, isFolder, type) => {
  return arr.findIndex((el) => {
    return el.name === name && el.isFolder === isFolder && el.type === type;
  });
};

const NavElem = ({
  elem,
  pos,
  orderMenu,
  setOrderMenu,
  setChangeData,
  setOpenCreateFolder,
  closeAll,
  setDeleteFunc,
  setDeleteText,
}) => {
  const [showChilds, setShowChilds] = useState(false);
  const last = orderMenu.length - 1 === pos;
  useEffect(() => {
    if (closeAll > 0) setShowChilds(false);
  }, [closeAll]);

  let childs;
  if (elem.isFolder) {
    childs = elem.children?.map((el, iC) => {
      return (
        <div
          key={el.name}
          className="d-flex flex-row justify-content-between ps-3 pe-3 pt-3 no-dash"
        >
          <div className="child position-relative">
            <div className="nav-dash mt-2"></div>

            <span className="pe-1">{el.name}</span>
          </div>
          <div className="">
            {/* <i className="bi bi-pencil me-4 ms-3" onClick={()=>setChangeData({data:el, path:[i,'elems', iC]})}/> */}
            <span className="text-secondary me-4">{iC + 1}</span>
            <i className="bi bi-list" />
          </div>
        </div>
      );
    });
  }

  return (
    <div key={elem.name} className={!last ? "border-bottom" : ""}>
      <div className="d-flex flex-row justify-content-between p-3 pb-0 ">
        <div>
          {
            <i
              className={`bi ${
                elem.isFolder ? "bi-folder2" : "bi-circle"
              } me-4`}
            />
          }
          <span className="pe-1">{elem.name}</span>
        </div>
        <div className="">
          {elem.isFolder ? (
            <i
              className={`bi bi-chevron-down me-3 ${
                showChilds ? "rotate180i" : ""
              }`}
              onClick={() => setShowChilds((show) => !show)}
            />
          ) : null}
          {elem.isFolder ? (
            <i
              className="bi bi-trash me-3"
              onClick={() => {
                setDeleteText(
                  `При удалении папки  «${elem.name}» все элементы внутри нее будут перенесены в основной список навигации.`,
                );
                setDeleteFunc(() => {
                  return () => {
                    const elems = elem.children;
                    const index = getIndex(
                      orderMenu,
                      elem.name,
                      elem.isFolder,
                      elem.type,
                    );
                    orderMenu.splice(index, 1);
                    setOrderMenu([...elems, ...orderMenu]);
                    setDeleteText("");
                    setDeleteFunc(null);
                  };
                });
              }}
            />
          ) : null}
          {elem.isFolder ? (
            <i
              className="bi bi-pencil me-4 pointer"
              onClick={() =>
                setChangeData({ data: elem, path: pos }) ||
                setOpenCreateFolder(true)
              }
            />
          ) : null}
          <p className="text-secondary me-4 constr-number">{pos + 1}</p>
          <i className="bi bi-list folder-move" />
        </div>
      </div>
      {elem.isFolder ? (
        <div
          className={`ms-4 mb-4 ps-4 border-left accordion-collapse collapse ${
            showChilds ? "show" : ""
          }`}
        >
          <ReactSortable
            animation={200}
            delayOnTouchStart={true}
            delay={2}
            list={elem.children}
            setList={(elm) => {
              setOrderMenu((orderMenu) => {
                const index = getIndex(
                  orderMenu,
                  elem.name,
                  elem.isFolder,
                  elem.type,
                );
                orderMenu[index].children = elm;
                return [...orderMenu];
              });
            }}
            group="shared"
            handle=".bi-list"
            swapThreshold={0.65}
          >
            {childs}
          </ReactSortable>
        </div>
      ) : null}
    </div>
  );
};

const Navconstr = ({ updateDynamicMenu }) => {
  const [orderMenu, setOrderMenu] = useState([]);
  const [openCreateFolder, setOpenCreateFolder] = useState(false);
  const [changeData, setChangeData] = useState(null);
  const [closeAll, setCloseAll] = useState(0);

  const [deleteText, setDeleteText] = useState("");

  const [deleteFunc, setDeleteFunc] = useState(null);

  useEffect(() => {
    getData(setOrderMenu, api);
  }, []);

  const existingFolderNames = useMemo(() => {
    return orderMenu
      .map((e) => {
        const name = e.name?.toLowerCase();
        if (e.isFolder) {
          return [name, ...e.children.map((el) => el.name?.toLowerCase())];
        }
        return name;
      })
      .flat();
  }, [orderMenu]);

  const validateFolderName = useCallback(
    (name) => {
      if (!name) return false;
      return !existingFolderNames.includes(name?.toLowerCase());
    },
    [existingFolderNames],
  );

  const createFolder = (name) => {
    if (!validateFolderName(name)) return;

    if (changeData) {
      orderMenu[changeData.path].name = name;
      setChangeData(null);
    } else {
      orderMenu.unshift({
        isFolder: true,
        children: [],
        slug: name,
        name: name,
      });
    }

    setOrderMenu([...orderMenu]);
  };

  const closePopupCreateFolder = () => {
    setChangeData(null);
    setOpenCreateFolder(false);
  };

  let count = 0;

  const html = orderMenu.map((e, i) => {
    count++;
    if (e.isFolder) {
      e.children?.forEach((el) => {
        count++;
      });
    }

    return (
      <NavElem
        key={e.name}
        elem={e}
        pos={i}
        orderMenu={orderMenu}
        setOrderMenu={setOrderMenu}
        setOpenCreateFolder={setOpenCreateFolder}
        setChangeData={setChangeData}
        closeAll={closeAll}
        setDeleteFunc={setDeleteFunc}
        setDeleteText={setDeleteText}
      />
    );
  });

  return (
    <main>
      <div className="container-fluid ">
        <div className="row">
          <div className="col drop-bg">
            <div className="d-flex justify-content-between align-items-center mb-4 mt-4">
              <div>
                <h5 className="mb-0">Конструктор навигации</h5>
              </div>
              <div>
                <button
                  className="btn btn-outline-danger"
                  onClick={() => {
                    getData(setOrderMenu, api);
                  }}
                >
                  Отменить
                </button>
                <button
                  className="btn btn-primary ms-3"
                  onClick={() => {
                    api
                      .postSettings({ menu: orderMenu })
                      .then(() => {
                        updateDynamicMenu();
                      })
                      .catch((err) => {
                        console.log(`err`, err);
                      });
                  }}
                >
                  Сохранить
                </button>
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center mb-4">
              <div>
                <p className="mb-0">Элементов навигации: {count}</p>
              </div>
              <div>
                <button
                  className="btn btn-outline-dark"
                  onClick={() => setCloseAll(closeAll + 1)}
                >
                  Свернуть всё
                </button>
                <button
                  className="btn btn-outline-primary ms-3"
                  onClick={() => {
                    setOpenCreateFolder(true);
                  }}
                >
                  <i className="bi bi-plus text-primary me-2" />
                  Новая папка
                </button>
              </div>
            </div>

            <div className="border rounded bg-white mb-5">
              <ReactSortable
                animation={200}
                delayOnTouchStart={true}
                delay={2}
                list={orderMenu}
                setList={(newOrderArr) => {
                  setOrderMenu(newOrderArr);
                }}
                group="shared"
                swapThreshold={0.65}
                handle=".folder-move"
              >
                {html}
              </ReactSortable>
            </div>
          </div>
        </div>
      </div>
      {openCreateFolder && (
        <FolderCreateModal
          createFolder={createFolder}
          close={closePopupCreateFolder}
          validateFolderName={validateFolderName}
          changeData={changeData}
        />
      )}
      <ModalDelete
        title={"Удалить папку?"}
        open={deleteText}
        text={deleteText}
        closePopUp={() => {
          setDeleteText("");
          setDeleteFunc(null);
        }}
        deleteFunc={deleteFunc}
      />
    </main>
  );
};

const FolderCreateModal = ({
  createFolder,
  close,
  validateFolderName,
  changeData,
}) => {
  const [name, setName] = useState(changeData ? changeData.data.name : "");

  useEffect(() => {
    return () => {
      setName(null);
    };
  }, []);

  return (
    <div
      className="modal fade show"
      id="folderModal"
      tabIndex="-1"
      aria-labelledby="folderModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-folder">
        <div className="modal-content">
          <div className="modal-header bg-light">
            <h5 className="modal-title" id="uploadMediaModalLabel">
              {changeData !== null ? "Изменение" : "Создание"} папки
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={close}
            ></button>
          </div>
          <div className="modal-body p-0">
            <h6 className="p-4 border-bottom">Конфигурации</h6>
            <div className="row m-4">
              <div className="col-6">
                <label htmlFor="foldername" className="mb-1 ">
                  Имя
                </label>
                <input
                  id="foldername"
                  type="text"
                  className="form-control"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="modal-footer d-flex justify-content-end">
            <div>
              <button className="btn btn-secondary me-2" onClick={close}>
                Отменить
              </button>
              <button
                className="btn btn-primary"
                disabled={!validateFolderName(name)}
                onClick={() => createFolder(name) || close()}
              >
                {changeData !== null ? "Сохранить" : "Добавить"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navconstr;
