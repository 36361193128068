import { FC } from "react";
import { NavLink } from "react-router-dom";

import Button from "components/Button/Button";
import SubMenu from "components/Menu/SubMenu";
import { LogOutIcon, ProfileIcon, SettingsIcon, UsersIcon } from "icons";

import styles from "./Menu.module.scss";
import api from "func/api";

const StaticMenu: FC = () => {
  return (
    <div className={styles["menu__static-menu-wrapper"]}>
      <div>
        <SubMenu
          menuName="Settings"
          icon={<SettingsIcon />}
          childElements={[
            { name: "Menu", path: "front/main/settings/menu" },
            { name: "Media", path: "front/main/settings/media" },
            { name: "API keys", path: "front/main/settings/api-keys" },
          ]}
        />
        <SubMenu
          menuName="Users"
          icon={<UsersIcon />}
          childElements={[
            { name: "Roles", path: "front/main/settings/role" },
            { name: "List", path: "front/main/settings/user" },
          ]}
        />
        <NavLink to="front/main/profile">
          {({ isActive }) => (
            <Button
              icon={<ProfileIcon />}
              childrenWrapperClassName={
                !isActive ? styles["menu__button-text"] : ""
              }
              isActive={isActive}
            >
              Profile
            </Button>
          )}
        </NavLink>
      </div>
      <Button
        icon={<LogOutIcon />}
        childrenWrapperClassName={styles["menu__button-text"]}
        onClick={() => api.logout()}
      >
        Log out
      </Button>
    </div>
  );
};

export default StaticMenu;
