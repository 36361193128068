import { FC } from "react";

const EmailIcon: FC = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4677 6.31902C11.4677 3.72289 9.49984 1.91602 6.67096 1.91602C3.73971 1.91602 1.73071 3.94427 1.73071 6.90527C1.73071 9.96164 3.68634 11.8998 6.77246 11.8998C7.52671 11.8998 8.25034 11.7921 8.78846 11.6066V10.8524C8.41221 11.0265 7.60371 11.1394 6.78471 11.1394C4.22446 11.1394 2.57334 9.49439 2.57334 6.94114C2.57334 4.45264 4.25421 2.67027 6.59309 2.67027C8.98621 2.67027 10.6251 4.14727 10.6251 6.30677C10.6251 7.77852 10.1403 8.72964 9.38609 8.72964C8.95559 8.72964 8.71059 8.48464 8.71059 8.06464V4.55502H7.80759V5.28477H7.71134C7.47859 4.76414 6.94046 4.44127 6.31134 4.44127C5.08634 4.44127 4.23059 5.45802 4.23059 6.91139C4.23059 8.43127 5.06796 9.45414 6.31221 9.45414C7.01221 9.45414 7.55034 9.11289 7.80759 8.50302H7.90384C7.97471 9.08927 8.51896 9.50752 9.21896 9.50752C10.5945 9.50752 11.4677 8.26939 11.4677 6.31989V6.31902ZM5.18784 6.93502C5.18784 5.88764 5.66034 5.26639 6.46184 5.26639C7.27559 5.26639 7.79534 5.91214 7.79534 6.93502C7.79534 7.95789 7.26946 8.60977 6.44959 8.60977C5.66646 8.60977 5.18784 7.97539 5.18784 6.93502Z"
        fill="inherit"
      />
    </svg>
  );
};

export default EmailIcon;
