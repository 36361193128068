import { filterHandlers } from "./filterTypes";
import filterMappings from "./filterMappings";
import type { CommonFilter, FilterGroup } from "typings/filter";

function getFilterQuery(filter: CommonFilter) {
  const key = filter.filterOption
    ? filter.filterOption.toLowerCase().replace(/\s/g, "-")
    : undefined;

  const handlerKey =
    filterMappings[filter.filterOption as keyof typeof filterMappings] ||
    key ||
    filter.type;

  if (filterHandlers.hasOwnProperty(handlerKey)) {
    return (filterHandlers as any)[handlerKey](filter); // TODO: Fix any here
  }

  return null;
}

interface FilterObject {
  globalFilteringType: "OR" | "AND";
  savedFilters: CommonFilter[] | FilterGroup[];
}

// TODO: Fix any here
export function buildMongoQuery(filterObject: FilterObject) {
  const filters = filterObject.savedFilters as CommonFilter[];

  if (
    !filters ||
    filters.length === 0 ||
    filters.every((f) => !f.isSaved && !f.isGroup)
  ) {
    return {};
  }

  const globalFilteringType = filterObject.globalFilteringType.toUpperCase();

  const mongoFilters: any = filters
    .map((filter: CommonFilter) => {
      if (filter.isGroup) {
        const filterGroup = {} as FilterObject;
        filterGroup.globalFilteringType = filter.groupFilteringType;
        filterGroup.savedFilters = filter.children;

        return buildMongoQuery(filterGroup);
      } else if (filter.isSaved) {
        return getFilterQuery(filter);
      } else {
        return null;
      }
    }) // TODO: Fix any here
    .filter(Boolean);

  const objectToReturn =
    mongoFilters.length > 0
      ? { [`$${globalFilteringType.toLowerCase()}`]: mongoFilters }
      : {};

  return objectToReturn;
}
