import { FC } from "react";

const CopyIcon: FC = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 2.5H11C11.5304 2.5 12.0391 2.71071 12.4142 3.08579C12.7893 3.46086 13 3.96957 13 4.5V14.5C13 15.0304 12.7893 15.5391 12.4142 15.9142C12.0391 16.2893 11.5304 16.5 11 16.5H4C3.46957 16.5 2.96086 16.2893 2.58579 15.9142C2.21071 15.5391 2 15.0304 2 14.5V4.5C2 3.96957 2.21071 3.46086 2.58579 3.08579C2.96086 2.71071 3.46957 2.5 4 2.5ZM4 3.5C3.73478 3.5 3.48043 3.60536 3.29289 3.79289C3.10536 3.98043 3 4.23478 3 4.5V14.5C3 14.7652 3.10536 15.0196 3.29289 15.2071C3.48043 15.3946 3.73478 15.5 4 15.5H11C11.2652 15.5 11.5196 15.3946 11.7071 15.2071C11.8946 15.0196 12 14.7652 12 14.5V4.5C12 4.23478 11.8946 3.98043 11.7071 3.79289C11.5196 3.60536 11.2652 3.5 11 3.5H4Z"
        fill="inherit"
      />
      <path
        d="M6 0.5H13C13.5304 0.5 14.0391 0.710714 14.4142 1.08579C14.7893 1.46086 15 1.96957 15 2.5V12.5C15 13.0304 14.7893 13.5391 14.4142 13.9142C14.0391 14.2893 13.5304 14.5 13 14.5V13.5C13.2652 13.5 13.5196 13.3946 13.7071 13.2071C13.8946 13.0196 14 12.7652 14 12.5V2.5C14 2.23478 13.8946 1.98043 13.7071 1.79289C13.5196 1.60536 13.2652 1.5 13 1.5H6C5.73478 1.5 5.48043 1.60536 5.29289 1.79289C5.10536 1.98043 5 2.23478 5 2.5H4C4 1.96957 4.21071 1.46086 4.58579 1.08579C4.96086 0.710714 5.46957 0.5 6 0.5V0.5Z"
        fill="inherit"
      />
    </svg>
  );
};

export default CopyIcon;
