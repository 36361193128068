import jquery from "jquery";
import "popper.js";
import "jquery-ui";
import "bootstrap";

window.jQuery = jquery;
window.jquery = jquery;
window.$ = jquery;

// import "bootstrap/js/dropdown";
