/* eslint-disable react/prop-types */
import { useState, useEffect } from "react";
import { ReactSortable } from "react-sortablejs";
import Select from "react-select";
import { errorCatch } from "func/common";
import api from "func/api";
import { customStyles } from "comp/common/funcs/common";
import { fieldsSetting } from "comp/common/funcs/common";

/**
 *
 * @param {{nameColl:string | udnefined, selectOps:object[], fields:object[]}} param0
 * @returns
 */
function ExcelSettings({ nameColl, fields }) {
  const filterHTML = [];
  const [nameList, setNameList] = useState("");
  const [numberString, setNumberString] = useState(1);
  const [startWith, setStartWith] = useState();
  const [fieldsKV, setFrieldsKV] = useState([]);
  const [fullExcelSettings, setFullExcelSettings] = useState({});

  const chooseElems = fieldsKV.map((el) => el.key);

  const [selectOps, setSelectOps] = useState([]);

  useEffect(() => {
    api
      .getSchema(nameColl)
      .then((result) => {
        setSelectOps([]);

        result.fields.forEach((el) => {
          if (el.typeField !== "json") {
            setSelectOps((prev) => [
              ...prev,
              {
                label: el.name,
                value: el.nameDB,
                type: fieldsSetting[el.typeField] ? el.typeField : "overVar",
                selectVars: el.selectVars,
              },
            ]);
          }
        });
      })
      .catch((err) => console.error(`err`, err));

    api
      .getSettingsSelect("excel")
      .then((result) => {
        if (result.settings.excel && result.settings.excel[nameColl]) {
          setFrieldsKV(result.settings.excel[nameColl].fieldsKV);
          setNameList(result.settings.excel[nameColl].nameList);
          setNumberString(result.settings.excel[nameColl].numberString);
          setStartWith(result.settings.excel[nameColl].startWith);
        }

        setFullExcelSettings(result.settings.excel);
      })
      .catch((err) => {
        errorCatch(err);
      });
  }, [nameColl]);

  fieldsKV.forEach((el, i) => {
    const options = selectOps.filter((el) => !chooseElems.includes(el.value));
    if (i === 0) options.unshift({ value: "_id", label: "id", type: "input" });

    filterHTML.push(
      <div
        className={`row align-items-center position-relative mb-2 me-2 ${
          i === 0 ? "bg-light p-2 rounded ms-4" : "ps-5"
        }`}
        key={i}
      >
        {i !== 0 && (
          <div className="position-absolute start-0  pointer ">
            <i className="bi bi-list" />
          </div>
        )}
        <div className="col">
          <Select
            options={options}
            styles={customStyles}
            fields={fields}
            value={
              el.key
                ? { value: el.key, label: el.label, type: fields[el.label] }
                : null
            }
            onChange={(e) => {
              fieldsKV[i].key = e.value;
              fieldsKV[i].label = e.label;
              fieldsKV[i].typeField = e.type;

              setFrieldsKV([...fieldsKV]);
            }}
          ></Select>
        </div>
        {el.typeField === "overVar" && (
          <div className=" col input-group">
            <input
              className="form-control"
              type="text"
              value={fieldsKV[i].numName}
              onChange={(e) => {
                fieldsKV[i].numName = e.target.value;
                setFrieldsKV([...fieldsKV]);
              }}
            />
          </div>
        )}
        <div className=" col input-group">
          <input
            className="form-control"
            type="text"
            value={fieldsKV[i].value}
            onChange={(e) => {
              fieldsKV[i].value = e.target.value;
              setFrieldsKV([...fieldsKV]);
            }}
          />
        </div>

        {i !== 0 ? (
          <span
            className="position-absolute end-0 block-group-btn pointer d-flex justify-content-center"
            onClick={() => {
              fieldsKV.splice(i, 1);
              setFrieldsKV([...fieldsKV]);
            }}
          >
            <i className="bi bi-plus text-danger rotate" />
          </span>
        ) : (
          <p className="col-12 m-0 fs-14 text-secondary">
            Ключевое поле для сопоставления данных
          </p>
        )}
      </div>
    );
  });

  return (
    <>
      <div className="d-flex mb-4 bg-light align-items-center mb-4 p-3">
        <h6 className="m-0">Настройки экспорта и импорта</h6>
        <div className="ms-auto">
          <button
            className="btn btn-outline-primary me-2"
            onClick={() => {
              setFrieldsKV([]);
            }}
          >
            Сбросить
          </button>
          <button
            className="btn btn-primary"
            onClick={() => {
              const exportData = {};
              const importData = {};

              fieldsKV.forEach(({ key, value }) => {
                if (key) {
                  exportData[key] = value;
                  importData[value] = key;
                }
              });

              api
                .postSettings({
                  excel: {
                    ...fullExcelSettings,
                    [nameColl]: {
                      nameList,
                      numberString,
                      startWith,
                      exportData,
                      importData,
                      fieldsKV,
                    },
                  },
                })
                .then((result) => {
                  console.log(`result`, result);
                })
                .catch((err) => {
                  errorCatch(err);
                });
            }}
          >
            Применить
          </button>
        </div>
      </div>
      <div className="p-3">
        <div className=" col input-group mb-3">
          <span className="input-group-text">Имя листа</span>
          <input
            className="form-control"
            type="text"
            value={nameList}
            onChange={(e) => {
              setNameList(e.target.value);
            }}
          />
        </div>
        <div className=" col input-group mb-3">
          <span className="input-group-text">Номер строки с title</span>
          <input
            className="form-control"
            type="number"
            value={numberString}
            onChange={(e) => {
              setNumberString(e.target.value);
            }}
          />
        </div>
        <div className=" col input-group mb-3">
          <span className="input-group-text">Номер строки начала контента</span>
          <input
            className="form-control"
            type="number"
            value={startWith}
            onChange={(e) => {
              setStartWith(e.target.value);
            }}
          />
        </div>
        <div className="row align-items-center position-relative mb-2 me-2">
          <div className="col-1"></div>
          <div className="col-5 text-secondary fs-16">Поле в CMS</div>

          <div className=" col input-group text-secondary fs-16">
            Поле (title) в Exel
          </div>
        </div>
        {filterHTML[0]}
        <ReactSortable
          animation={200}
          list={fieldsKV.length ? fieldsKV.slice(1, fieldsKV.length) : []}
          setList={(newOrderArr) => {
            setFrieldsKV(
              fieldsKV[0] ? [fieldsKV[0], ...newOrderArr] : [...newOrderArr]
            );
          }}
          swapThreshold={0.65}
          handle=".bi-list"
        >
          {filterHTML.slice(1, filterHTML.length)}
        </ReactSortable>

        <div className="w-100 mt-4 d-flex justify-content-center">
          <div
            className="block-group-btn pointer d-flex justify-content-center"
            onClick={() => {
              setFrieldsKV([...fieldsKV, { key: "", value: "" }]);
            }}
          >
            <i className="bi bi-plus text-primary" />
          </div>
        </div>
      </div>
    </>
  );
}

export default ExcelSettings;
