import { FC, useCallback, useMemo, useState } from "react";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { StrictModeDroppable } from "components/StrictModeDroppable/StrictModeDroppable";
import TableControlPanel from "pages/Collection/components/TableControlPanel/TableControlPanel";
import AnimatedWrapper from "components/AnimatedWrapper/AnimatedWrapper";
import Button from "components/Button/Button";
import TableControlHeader from "components/TableControlHeader/TableControlHeader";
import FieldItem from "pages/Collection/components/FieldVisibility/FieldItem";
import { EyeFilledIcon, EyeSlashIcon } from "icons";
import iconsByType from "utils/iconsByType";
import { COLLECTION_CONTROL_PANEL } from "constants/index";

import { useAppSelector } from "hooks/useAppSelector";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useReorder } from "hooks/useReorder";

import { setActivePanel } from "store/slices/controlPanelSlice";
import {
  VisibleField,
  setVisibilityFieldsOrder,
} from "store/slices/fieldVisibilitySlice";
import { selectVisibleFields } from "store/selectors/fieldVisibilitySelectors";
import { selectActivePanel } from "store/selectors/controlPanelSelectors";

import styles from "./FieldVisibility.module.scss";
import BigSearch from "components/BigSearch/BigSearch";

const FieldVisibility: FC = () => {
  const dispatch = useAppDispatch();

  const activePanel = useAppSelector(selectActivePanel);
  const visibilityFields = useAppSelector(selectVisibleFields);

  const closeFieldVisibilityBar = useCallback(() => {
    dispatch(setActivePanel(null));
  }, [dispatch]);

  const isFieldVisibilityOpen = useMemo(
    () => activePanel === COLLECTION_CONTROL_PANEL.VISIBILITY,
    [activePanel]
  );

  const reorder = useReorder<VisibleField>();

  const [searchValue, setSearchValue] = useState("");
  const onDragEndHandler = useCallback(
    (result: DropResult) => {
      if (!result.destination || searchValue.length > 0) {
        return;
      }

      const items = reorder(
        visibilityFields,
        result.source.index,
        result.destination.index
      );

      dispatch(setVisibilityFieldsOrder(items));
    },
    [searchValue, visibilityFields, dispatch, reorder]
  );

  const filteredVisibilityFields = useMemo(() => {
    if (!searchValue) return visibilityFields;

    const lowerCasedSearchValue = searchValue.toLowerCase();
    return visibilityFields.filter((field) => {
      return (
        field.id.includes(lowerCasedSearchValue) ||
        field.name.toLowerCase().includes(lowerCasedSearchValue)
      );
    });
  }, [visibilityFields, searchValue]);

  const toggledFields = useMemo(() => {
    return visibilityFields.filter((field) => field.isVisible);
  }, [visibilityFields]);

  const closeFilterBar = useCallback(
    () => dispatch(setActivePanel(null)),
    [dispatch]
  );

  return (
    <AnimatedWrapper
      isOpen={isFieldVisibilityOpen}
      onClose={closeFieldVisibilityBar}
    >
      <TableControlPanel
        headerComponent={
          <TableControlHeader
            title="👀 Visibility"
            onClose={closeFilterBar}
          />
        }
        dividerMargins="15px 0 0"
      >
        <BigSearch
          className={styles.search}
          onSearch={setSearchValue}
          placeholder="Search by field name"
        />
        <div className={styles.counter}>
          <span>{toggledFields.length}/8</span>
          <span> shown in list</span>
        </div>
        <DragDropContext onDragEnd={onDragEndHandler}>
          <StrictModeDroppable
            droppableId="droppable"
            renderClone={(provided, snapshot, rubric) => {
              const field = visibilityFields[rubric.source.index];
              return (
                <div
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  ref={provided.innerRef}
                >
                  <Button
                    icon={
                      field.isVisible ? <EyeFilledIcon /> : <EyeSlashIcon />
                    }
                    withContextMenu
                    isActive
                    withDisabledStyle={
                      !field.isVisible && toggledFields.length === 8
                    }
                  >
                    <div className={styles["button-inner-content"]}>
                      <div className={styles.icon}>
                        {iconsByType[field.type]}
                      </div>
                      {field.name}
                    </div>
                  </Button>
                </div>
              );
            }}
          >
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {filteredVisibilityFields.map((field, index) => (
                  <FieldItem
                    field={field}
                    index={index}
                    key={field.id}
                    isDragDisabled={searchValue.length > 0}
                    isVisibilityToggleDisabled={
                      !field.isVisible && toggledFields.length === 8
                    }
                  />
                ))}
                {provided.placeholder}
              </div>
            )}
          </StrictModeDroppable>
        </DragDropContext>
      </TableControlPanel>
    </AnimatedWrapper>
  );
};

export default FieldVisibility;
