import { FC } from "react";

const ArrowRepeatIcon: FC = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5341 7.50002H15.4661C15.5136 7.50004 15.5601 7.51359 15.6002 7.53909C15.6403 7.56459 15.6722 7.60098 15.6924 7.644C15.7125 7.68702 15.72 7.73489 15.7139 7.782C15.7079 7.82912 15.6885 7.87352 15.6581 7.91002L13.6921 10.27C13.6686 10.2982 13.6393 10.3208 13.6061 10.3363C13.5729 10.3519 13.5367 10.3599 13.5001 10.3599C13.4635 10.3599 13.4273 10.3519 13.3941 10.3363C13.3609 10.3208 13.3315 10.2982 13.3081 10.27L11.3421 7.91002C11.3117 7.87352 11.2923 7.82912 11.2862 7.782C11.2802 7.73489 11.2876 7.68702 11.3078 7.644C11.3279 7.60098 11.3599 7.56459 11.4 7.53909C11.4401 7.51359 11.4866 7.50004 11.5341 7.50002ZM0.534086 9.50002H4.46609C4.51359 9.50001 4.56011 9.48645 4.60019 9.46096C4.64027 9.43546 4.67225 9.39907 4.6924 9.35605C4.71254 9.31303 4.72002 9.26516 4.71394 9.21804C4.70786 9.17093 4.68849 9.12652 4.65809 9.09002L2.69209 6.73002C2.66862 6.70189 2.63926 6.67925 2.60609 6.66372C2.57291 6.64819 2.53672 6.64014 2.50009 6.64014C2.46345 6.64014 2.42726 6.64819 2.39409 6.66372C2.36091 6.67925 2.33155 6.70189 2.30809 6.73002L0.342086 9.09002C0.311684 9.12652 0.29231 9.17093 0.286234 9.21804C0.280157 9.26516 0.287629 9.31303 0.307775 9.35605C0.327921 9.39907 0.359907 9.43546 0.399987 9.46096C0.440067 9.48645 0.486583 9.50001 0.534086 9.50002Z"
        fill="#2E3338"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00001 3.50023C6.44801 3.50023 5.06001 4.20723 4.14301 5.31823C4.10206 5.37131 4.05086 5.4156 3.99245 5.44849C3.93404 5.48138 3.86961 5.50219 3.803 5.50968C3.73638 5.51717 3.66895 5.51119 3.60469 5.49209C3.54043 5.47299 3.48068 5.44117 3.42897 5.39851C3.37726 5.35586 3.33465 5.30324 3.30368 5.24379C3.27272 5.18434 3.25402 5.11926 3.24871 5.05244C3.24339 4.98562 3.25157 4.91841 3.27275 4.85481C3.29394 4.79121 3.32769 4.73252 3.37201 4.68223C4.1042 3.79576 5.0756 3.13824 6.17067 2.78789C7.26575 2.43753 8.43841 2.40908 9.54918 2.70592C10.66 3.00276 11.6621 3.6124 12.4364 4.46232C13.2107 5.31224 13.7246 6.36668 13.917 7.50023H12.9C12.6691 6.37104 12.0554 5.35623 11.1625 4.62738C10.2697 3.89853 9.15256 3.50037 8.00001 3.50023ZM3.10001 9.50023C3.28932 10.4249 3.73636 11.2771 4.38949 11.9584C5.04261 12.6397 5.87517 13.1223 6.79097 13.3505C7.70677 13.5787 8.66845 13.5431 9.5649 13.2479C10.4613 12.9527 11.256 12.4099 11.857 11.6822C11.898 11.6292 11.9492 11.5849 12.0076 11.552C12.066 11.5191 12.1304 11.4983 12.197 11.4908C12.2636 11.4833 12.3311 11.4893 12.3953 11.5084C12.4596 11.5275 12.5193 11.5593 12.5711 11.602C12.6228 11.6446 12.6654 11.6972 12.6963 11.7567C12.7273 11.8161 12.746 11.8812 12.7513 11.948C12.7566 12.0148 12.7484 12.0821 12.7273 12.1457C12.7061 12.2093 12.6723 12.2679 12.628 12.3182C11.8958 13.2047 10.9244 13.8622 9.82934 14.2126C8.73427 14.5629 7.56161 14.5914 6.45083 14.2945C5.34006 13.9977 4.33791 13.3881 3.5636 12.5381C2.78928 11.6882 2.27538 10.6338 2.08301 9.50023H3.10001Z"
        fill="black"
      />
    </svg>
  );
};

export default ArrowRepeatIcon;
