import { FC, PropsWithChildren } from "react";
import type { CommonFilter } from "typings/filter";

import styles from "./FilterVariant.module.scss";

interface FilterWrapperProps extends PropsWithChildren {
  filter: CommonFilter;
}

const FilterWrapper: FC<FilterWrapperProps> = ({ filter, children }) => {
  const shouldShowStatus =
    filter &&
    (filter.filterOption === "Is Empty" ||
      filter.filterOption === "Is Not Empty");

  return (
    <>
      {shouldShowStatus ? (
        <div className={styles["text"]}>
          <span className={styles["filter-name"]}>{filter.name}: </span>
          {filter.filterOption}
        </div>
      ) : (
        children
      )}
    </>
  );
};

export default FilterWrapper;
