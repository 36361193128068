import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { SERVER_URL, API_VERSION } from "func/constants";
import { APIResponse } from "typings/apiState";

const baseURL = `${SERVER_URL}/api/${API_VERSION}/main`;

export const collectionApiService = createApi({
  reducerPath: "collectionApiService",
  baseQuery: fetchBaseQuery({
    baseUrl: baseURL,
    prepareHeaders(headers) {
      return headers;
    },
    credentials: "include",
  }),
  endpoints: (builder) => ({
    // endpoint for fetching collection elements
    fetchCollectionDocuments: builder.mutation<
      APIResponse<any>,
      {
        collectionId: string;
        body: any;
        params: { page: number; search: string };
      }
    >({
      query: ({ collectionId, body, params: { page, search } }) => ({
        url: `filter/collections/${collectionId}`,
        method: "POST",
        body,
        params: {
          page,
          search,
        },
      }),
    }),
  }),
});

export const { useFetchCollectionDocumentsMutation } = collectionApiService;
