import type { Dispatch, SetStateAction } from "react";
import type ICell from "interfaces/ICell";
import type ICellRenderStrategy from "interfaces/ICellRenderStrategy";
import { Link } from "react-router-dom";
import Search from "components/Search/Search";
import debounce from "utils/debounce";

interface IHeaderCellProps {
  setSearchQuery: Dispatch<SetStateAction<string>>;
}

export class FirstColumnCellRenderStrategy
  implements ICellRenderStrategy<ICell>
{
  renderHeaderCell(data: ICell, { setSearchQuery }: IHeaderCellProps) {
    const setSearchQueryWithDebounce = debounce(setSearchQuery, 300);

    return (
      <Search
        columnName={data.name}
        onSearch={setSearchQueryWithDebounce}
      />
    );
  }

  renderCell(data: ICell, props?: any) {
    return (
      <div
        className={`${props.styles["flex-cell"]} ${props.styles["first-column"]}`}
      >
        <Link
          to={props.pathToCollection}
          onClick={(event) => event.preventDefault()}
        >
          {props.value}
        </Link>
      </div>
    );
  }
}
