import { FC } from "react";

const HddIcon: FC = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 11.25C0 10.6533 0.237053 10.081 0.65901 9.65901C1.08097 9.23705 1.65326 9 2.25 9H15.75C16.3467 9 16.919 9.23705 17.341 9.65901C17.7629 10.081 18 10.6533 18 11.25V12.375C18 12.9717 17.7629 13.544 17.341 13.966C16.919 14.3879 16.3467 14.625 15.75 14.625H2.25C1.65326 14.625 1.08097 14.3879 0.65901 13.966C0.237053 13.544 0 12.9717 0 12.375V11.25ZM2.8125 12.375C2.96168 12.375 3.10476 12.3157 3.21025 12.2102C3.31574 12.1048 3.375 11.9617 3.375 11.8125C3.375 11.6633 3.31574 11.5202 3.21025 11.4148C3.10476 11.3093 2.96168 11.25 2.8125 11.25C2.66332 11.25 2.52024 11.3093 2.41475 11.4148C2.30926 11.5202 2.25 11.6633 2.25 11.8125C2.25 11.9617 2.30926 12.1048 2.41475 12.2102C2.52024 12.3157 2.66332 12.375 2.8125 12.375ZM5.0625 12.375C5.21168 12.375 5.35476 12.3157 5.46025 12.2102C5.56574 12.1048 5.625 11.9617 5.625 11.8125C5.625 11.6633 5.56574 11.5202 5.46025 11.4148C5.35476 11.3093 5.21168 11.25 5.0625 11.25C4.91332 11.25 4.77024 11.3093 4.66475 11.4148C4.55926 11.5202 4.5 11.6633 4.5 11.8125C4.5 11.9617 4.55926 12.1048 4.66475 12.2102C4.77024 12.3157 4.91332 12.375 5.0625 12.375Z"
        fill="inherit"
      />
      <path
        d="M1.02368 8.1045C1.41457 7.95228 1.83045 7.87445 2.24993 7.875H15.7499C16.1819 7.875 16.5959 7.956 16.9762 8.1045L14.8758 4.25475C14.7307 3.98852 14.5165 3.76632 14.2557 3.61151C13.995 3.4567 13.6974 3.375 13.3942 3.375H4.60568C4.30246 3.375 4.00484 3.4567 3.74412 3.61151C3.48339 3.76632 3.2692 3.98852 3.12406 4.25475L1.02368 8.1045Z"
        fill="inherit"
      />
    </svg>
  );
};

export default HddIcon;
