import { FC } from "react";

const PlusIcon: FC = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="22"
        height="22"
        rx="6"
        fill="#FDD8F2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 17.125C12.6245 17.125 14.1824 16.4797 15.331 15.331C16.4797 14.1824 17.125 12.6245 17.125 11C17.125 9.37555 16.4797 7.81763 15.331 6.66897C14.1824 5.52031 12.6245 4.875 11 4.875C9.37555 4.875 7.81763 5.52031 6.66897 6.66897C5.52031 7.81763 4.875 9.37555 4.875 11C4.875 12.6245 5.52031 14.1824 6.66897 15.331C7.81763 16.4797 9.37555 17.125 11 17.125ZM11 18C12.8565 18 14.637 17.2625 15.9497 15.9497C17.2625 14.637 18 12.8565 18 11C18 9.14348 17.2625 7.36301 15.9497 6.05025C14.637 4.7375 12.8565 4 11 4C9.14348 4 7.36301 4.7375 6.05025 6.05025C4.7375 7.36301 4 9.14348 4 11C4 12.8565 4.7375 14.637 6.05025 15.9497C7.36301 17.2625 9.14348 18 11 18Z"
        fill="#DB15A3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 7.5C11.116 7.5 11.2273 7.54609 11.3094 7.62814C11.3914 7.71019 11.4375 7.82147 11.4375 7.9375V10.5625H14.0625C14.1785 10.5625 14.2898 10.6086 14.3719 10.6906C14.4539 10.7727 14.5 10.884 14.5 11C14.5 11.116 14.4539 11.2273 14.3719 11.3094C14.2898 11.3914 14.1785 11.4375 14.0625 11.4375H11.4375V14.0625C11.4375 14.1785 11.3914 14.2898 11.3094 14.3719C11.2273 14.4539 11.116 14.5 11 14.5C10.884 14.5 10.7727 14.4539 10.6906 14.3719C10.6086 14.2898 10.5625 14.1785 10.5625 14.0625V11.4375H7.9375C7.82147 11.4375 7.71019 11.3914 7.62814 11.3094C7.54609 11.2273 7.5 11.116 7.5 11C7.5 10.884 7.54609 10.7727 7.62814 10.6906C7.71019 10.6086 7.82147 10.5625 7.9375 10.5625H10.5625V7.9375C10.5625 7.82147 10.6086 7.71019 10.6906 7.62814C10.7727 7.54609 10.884 7.5 11 7.5Z"
        fill="#DB15A3"
      />
    </svg>
  );
};

export default PlusIcon;
