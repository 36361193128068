export default function debounce<F extends (...args: any[]) => void>(
  func: F,
  waitFor: number
): (...args: Parameters<F>) => void {
  let timeout: number | undefined;

  return function (...args: Parameters<F>) {
    clearTimeout(timeout);
    timeout = window.setTimeout(() => {
      clearTimeout(timeout);
      func(...args);
    }, waitFor);
  };
}
