import { FC } from "react";

const ArrowCounterClockwiseIcon: FC = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.99993 3.375C10.2157 3.37547 11.3985 3.7698 12.3713 4.49892C13.3441 5.22804 14.0545 6.25272 14.3961 7.41946C14.7377 8.58621 14.6921 9.83223 14.2662 10.9709C13.8402 12.1095 13.0568 13.0795 12.0333 13.7356C11.0098 14.3917 9.8013 14.6985 8.58879 14.6101C7.37628 14.5217 6.22504 14.0429 5.30751 13.2453C4.38998 12.4477 3.75553 11.3744 3.49921 10.186C3.24289 8.99757 3.37848 7.75812 3.88568 6.65325C3.94089 6.51892 3.942 6.36844 3.88878 6.23331C3.83556 6.09818 3.73214 5.98887 3.60016 5.92826C3.46818 5.86765 3.31787 5.86043 3.18069 5.90812C3.04352 5.95581 2.9301 6.05471 2.86418 6.18413C2.25557 7.51004 2.09292 8.99745 2.4006 10.4236C2.70829 11.8497 3.46973 13.1377 4.57089 14.0947C5.67205 15.0518 7.05364 15.6263 8.50871 15.7322C9.96378 15.8381 11.414 15.4697 12.6422 14.6823C13.8703 13.8948 14.8103 12.7306 15.3212 11.3641C15.8322 9.99761 15.8867 8.50233 15.4765 7.10225C15.0663 5.70218 14.2136 4.47268 13.046 3.59792C11.8784 2.72317 10.4589 2.25025 8.99993 2.25V3.375Z"
        fill="inherit"
      />
      <path
        d="M9.00009 5.02423V0.600725C9.00007 0.547283 8.98482 0.494954 8.95614 0.449864C8.92745 0.404773 8.88651 0.368789 8.83811 0.346125C8.78972 0.323461 8.73586 0.315055 8.68286 0.321891C8.62986 0.328727 8.5799 0.350522 8.53884 0.384725L5.88384 2.59647C5.85219 2.62287 5.82672 2.6559 5.80925 2.69323C5.79177 2.73055 5.78271 2.77126 5.78271 2.81247C5.78271 2.85369 5.79177 2.8944 5.80925 2.93172C5.82672 2.96905 5.85219 3.00208 5.88384 3.02847L8.53884 5.24022C8.5799 5.27443 8.62986 5.29622 8.68286 5.30306C8.73586 5.3099 8.78972 5.30149 8.83811 5.27882C8.88651 5.25616 8.92745 5.22018 8.95614 5.17509C8.98482 5.13 9.00007 5.07767 9.00009 5.02423Z"
        fill="inherit"
      />
    </svg>
  );
};

export default ArrowCounterClockwiseIcon;
