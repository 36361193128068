import { FC, PropsWithChildren } from "react";

import styles from "./DataTable.module.scss";

interface TagProps extends PropsWithChildren {
  withUnderline?: boolean;
}

const Tag: FC<TagProps> = ({ children, withUnderline = false }) => {
  const isArray = Array.isArray(children);
  return isArray ? (
    children.map((child, index) => {
      return (
        <div
          key={index}
          className={`${styles.tag}`}
        >
          {child}
        </div>
      );
    })
  ) : (
    <div className={`${styles.tag}`}>
      <span className={`${withUnderline ? styles["tag--with-underline"] : ""}`}>
        {children}
      </span>
    </div>
  );
};

export default Tag;
