import { RootState } from "store/store";

export const selectVisibleFields = (state: RootState) =>
  state.collection.fieldVisibility.visibleFields;

export const selectFieldsAfterExclusion = (state: RootState) =>
  state.collection.fieldVisibility.visibleFieldsAfterExclusion;

export const isInInitialState = (state: RootState) => {
  const visibleFields = selectVisibleFields(state);
  const initiallyVisibleFields =
    state.collection.fieldVisibility.initiallyVisibleFields;

  if (!initiallyVisibleFields) return false;

  return (
    JSON.stringify(visibleFields) === JSON.stringify(initiallyVisibleFields)
  );
};

export const selectHaveChangesInFieldVisibility = (state: RootState) => {
  return (
    JSON.stringify(state.collection.fieldVisibility.initiallyVisibleFields) !==
    JSON.stringify(state.collection.fieldVisibility.visibleFields)
  );
};
