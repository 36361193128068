import { v4 as uuidv4 } from "uuid";

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import type { CommonFilter, FilterGroup, FilterState } from "typings/filter";
import { COLLECTION_CONTROL_PANEL } from "constants/index";
import { setCurrentViewConfiguration } from "store/thunks/collectionPageInfoThunks";
import { determineFilterOptionFromAPIOperator } from "utils/determineFilterOptionFromAPIOperator";
import { apiService } from "api/apiService";
import { stringifyFilters } from "utils/stringifyFilters";

const setAllFiltersEditMode = (
  filters: CommonFilter[],
  isInEditMode: boolean
) => {
  for (let i = 0; i < filters.length; i++) {
    filters[i].isInEditMode = isInEditMode;

    // Recursively set for children
    if (filters[i].children && filters[i].children.length > 0) {
      setAllFiltersEditMode(filters[i].children, isInEditMode);
    }
  }
};

// Функция-стражник для определения, является ли элемент группой фильтров
function isFilterGroup(filter: any): filter is FilterGroup {
  return filter.isGroup;
}

// Функция-стражник для определения, является ли элемент обычным фильтром
function isCommonFilter(filter: any): filter is CommonFilter {
  return filter.isInEditMode !== undefined;
}

// Функция для обновления или добавления обычного фильтра
function updateOrAddFilter(
  filters: Array<CommonFilter | FilterGroup>,
  filterToUpdate: CommonFilter
) {
  // Проверяем, есть ли у фильтра родитель
  if (filterToUpdate.parentId) {
    // Ищем родительский фильтр
    const parentFilter = filters.find(
      (f) => isFilterGroup(f) && f.id === filterToUpdate.parentId
    ) as FilterGroup | undefined;

    if (parentFilter) {
      // Если родительский фильтр найден, обновляем дочерний фильтр в его массиве children
      const existingChildIndex = parentFilter.children.findIndex(
        (child) => child.id === filterToUpdate.id
      );
      if (existingChildIndex !== -1) {
        parentFilter.children[existingChildIndex] = filterToUpdate;
      } else {
        parentFilter.children.push(filterToUpdate);
      }
    } else {
      // Если родительский фильтр не найден, добавляем фильтр в корневой список
      filters.push(filterToUpdate);
    }
  } else {
    // Если у фильтра нет parentId, обрабатываем как обычно
    const existingFilterIndex = filters.findIndex(
      (f) => f.id === filterToUpdate.id && !isFilterGroup(f)
    );
    if (existingFilterIndex !== -1) {
      filters[existingFilterIndex] = filterToUpdate;
    } else {
      filters.push(filterToUpdate);
    }
  }
}

// Функция для обновления или добавления группы фильтров
function updateOrAddFilterGroup(
  filters: Array<CommonFilter | FilterGroup>,
  filterGroupToUpdate: FilterGroup
) {
  const existingGroupIndex = filters.findIndex(
    (f) => f.id === filterGroupToUpdate.id && isFilterGroup(f)
  );
  if (existingGroupIndex !== -1) {
    filters[existingGroupIndex] = filterGroupToUpdate;
  } else {
    filters.push(filterGroupToUpdate);
  }
}

const initialState: FilterState = {
  body: {
    globalFilteringType: "AND",
    savedFilters: [],
  },
  filters: [],
  initialFiltersAsString: "[]",
};

export const filterSlice = createSlice({
  name: COLLECTION_CONTROL_PANEL.FILTERS,
  initialState,
  reducers: {
    setGlobalFilteringType: (
      state,
      action: PayloadAction<{ globalFilteringType: "OR" | "AND" }>
    ) => {
      state.body.globalFilteringType = action.payload.globalFilteringType;
    },
    addFilterVariant: (
      state,
      action: PayloadAction<{
        filterVariant: CommonFilter;
        filterGroupId: CommonFilter["id"];
      }>
    ) => {
      const { filterVariant, filterGroupId } = action.payload;

      setAllFiltersEditMode(state.filters as CommonFilter[], false);

      if (filterGroupId) {
        const filterGroupIndex = state.filters.findIndex(
          (f) => f.id === filterGroupId
        );

        if (filterGroupIndex !== -1) {
          // Ensure the filter group exists
          const filterGroup = state.filters[filterGroupIndex];

          // Initialize children array if it doesn't exist
          if (!filterGroup.children) {
            filterGroup.children = [];
          }

          filterGroup.children.push(filterVariant);
        }
      } else {
        state.filters.push(filterVariant);
      }
    },
    setFilterValue: (
      state,
      action: PayloadAction<{
        filter: CommonFilter;
        value: CommonFilter["value"];
      }>
    ) => {
      const { filter: targetFilter, value } = action.payload;

      const setFilterValueRecursive = (
        filters: CommonFilter[],
        targetFilterNameDB: string,
        value: CommonFilter["value"]
      ) => {
        for (let i = 0; i < filters.length; i++) {
          if (
            filters[i].nameDB === targetFilterNameDB &&
            filters[i].isInEditMode
          ) {
            filters[i].value = value;
            return true; // Filter found and updated
          }

          // Recursively check for children
          if (filters[i].children && filters[i].children.length > 0) {
            const isUpdated = setFilterValueRecursive(
              filters[i].children,
              targetFilterNameDB,
              value
            );
            if (isUpdated) return true; // Filter found and updated in children
          }
        }
        return false; // Filter not found
      };

      setFilterValueRecursive(
        state.filters as CommonFilter[],
        targetFilter.nameDB,
        value
      );
    },
    setActiveFilterById: (state, action: PayloadAction<{ id: string }>) => {
      const { id } = action.payload;

      const setEditModeRecursive = (
        filters: Array<CommonFilter | FilterGroup>,
        targetId: string
      ) => {
        for (const filter of filters) {
          // Если фильтр не является группой, проверяем его состояние и устанавливаем режим редактирования
          if (isCommonFilter(filter)) {
            if (filter.id === targetId) {
              filter.isInEditMode = true; // Только для фильтра с заданным id устанавливаем true
            } else {
              if (filter.isInEditMode) {
                // Если фильтр был в режиме редактирования, но не сохранён, возвращаем его в исходное состояние
                const savedFilter = findFilterRecursive(
                  state.body.savedFilters,
                  filter.id
                );
                if (savedFilter) {
                  Object.assign(filter, savedFilter);
                }
              }
              filter.isInEditMode = false; // Для всех остальных фильтров устанавливаем false
            }
          }

          // Применяем рекурсивно к дочерним элементам
          if (filter.children) {
            setEditModeRecursive(filter.children, targetId);
          }
        }
      };

      const findFilterRecursive = (
        filters: Array<CommonFilter | FilterGroup>,
        filterId: string
      ): CommonFilter | undefined => {
        for (const filter of filters) {
          if (isCommonFilter(filter) && filter.id === filterId) {
            return filter; // Найденный фильтр
          }
          // Поиск рекурсивно в детях
          if (filter.children) {
            const foundFilter = findFilterRecursive(filter.children, filterId);
            if (foundFilter) {
              return foundFilter;
            }
          }
        }
        return undefined; // Не найден
      };

      // Применяем функцию setEditModeRecursive к каждому фильтру верхнего уровня
      state.filters.forEach((filter) => {
        if (isFilterGroup(filter)) {
          setEditModeRecursive(filter.children, id);
        } else {
          setEditModeRecursive([filter], id);
        }
      });
    },
    setFilterOptionByFilterId: (
      state,
      action: PayloadAction<{ filter: CommonFilter; option: string }>
    ) => {
      const { filter: targetFilter, option } = action.payload;

      const setFilterOptionRecursive = (
        filters: CommonFilter[],
        targetFilterId: string,
        option: string
      ) => {
        for (let i = 0; i < filters.length; i++) {
          if (filters[i].id === targetFilterId && filters[i].isInEditMode) {
            filters[i].filterOption = option;
            return true; // Filter found and updated
          }

          // Recursively check for children
          if (filters[i].children && filters[i].children.length > 0) {
            const isUpdated = setFilterOptionRecursive(
              filters[i].children,
              targetFilterId,
              option
            );
            if (isUpdated) return true; // Filter found and updated in children
          }
        }
        return false; // Filter not found
      };

      setFilterOptionRecursive(
        state.filters as CommonFilter[],
        targetFilter.id,
        option
      );
    },
    clearFilters: (state) => {
      state.filters = [];
      state.body.savedFilters = [];
    },
    saveFilter: (
      state,
      action: PayloadAction<{ filter: CommonFilter | FilterGroup }>
    ) => {
      const { filter: targetFilter } = action.payload;

      const saveFilterRecursive = (
        filters: Array<CommonFilter | FilterGroup>,
        targetFilterId: string
      ) => {
        for (const filter of filters) {
          // Сначала проверяем, является ли элемент обычным фильтром
          if (isCommonFilter(filter) && filter.id === targetFilterId) {
            filter.isInEditMode = false;
            filter.isSaved = true;

            updateOrAddFilter(state.body.savedFilters, filter);
            return true; // Фильтр найден и обновлен
          }
          // Затем проверяем, является ли элемент группой фильтров
          else if (isFilterGroup(filter)) {
            // Обработка группы фильтров
            if (filter.id === targetFilterId) {
              updateOrAddFilterGroup(state.body.savedFilters, filter);
              return true; // Группа фильтров найдена и обновлена
            }
            // Рекурсивно проверяем детей группы фильтров
            if (filter.children) {
              const isSaved = saveFilterRecursive(
                filter.children,
                targetFilterId
              );
              if (isSaved) return true; // Фильтр найден и обновлен в детях
            }
          }
        }
        return false; // Фильтр не найден
      };

      saveFilterRecursive(state.filters, targetFilter.id);
    },
    setFilterAsGroupHost: (
      state,
      action: PayloadAction<{ id: CommonFilter["id"] }>
    ) => {
      const { id } = action.payload;
      const filterIndex = state.filters.findIndex((f) => f.id === id);
      const parentId = uuidv4();

      const groupObject: FilterGroup = {
        id: parentId,
        isGroup: true,
        children: [
          {
            ...(state.filters.find((f) => f.id === id) as CommonFilter),
            parentId,
          },
        ],
        groupFilteringType: "AND",
        isInEditMode: false,
      };

      state.filters[filterIndex] = groupObject;

      // Add the group to the saved filters
      const savedFilterIndex = state.body.savedFilters.findIndex(
        (f) => f.id === id
      );
      state.body.savedFilters[savedFilterIndex] = groupObject;
    },
    deleteFilterGroup: (
      state,
      action: PayloadAction<{ id: CommonFilter["id"] }>
    ) => {
      const { id } = action.payload;
      const filterIndex = state.filters.findIndex((f) => f.id === id);
      if (filterIndex !== -1) {
        state.filters.splice(filterIndex, 1);
      }

      const savedFilterIndex = state.body.savedFilters.findIndex(
        (f) => f.id === id
      );
      if (savedFilterIndex !== -1) {
        state.body.savedFilters.splice(savedFilterIndex, 1);
      }
    },
    deleteFilterById: (
      state,
      action: PayloadAction<{ id: CommonFilter["id"] }>
    ) => {
      const { id } = action.payload;

      const findFilterRecursive = (
        filters: CommonFilter[],
        id: CommonFilter["id"]
      ) => {
        for (let i = 0; i < filters.length; i++) {
          if (filters[i].id === id) {
            filters.splice(i, 1);
            return true; // Filter found and deleted
          }

          // Recursively check for children
          if (filters[i].children && filters[i].children.length > 0) {
            const isDeleted = findFilterRecursive(filters[i].children, id);
            if (isDeleted) return true; // Filter found and deleted in children
          }
        }
        return false; // Filter not found
      };

      findFilterRecursive(state.filters as CommonFilter[], id);
      findFilterRecursive(state.body.savedFilters as CommonFilter[], id);
    },
    setGroupFilteringType: (
      state,
      action: PayloadAction<{
        filterGroupId: CommonFilter["id"];
        groupFilteringType: FilterGroup["groupFilteringType"];
      }>
    ) => {
      const { filterGroupId, groupFilteringType } = action.payload;

      const filterGroupIndex = state.filters.findIndex(
        (f) => f.id === filterGroupId
      );

      if (filterGroupIndex !== -1) {
        // Ensure the filter group exists
        const filterGroup = state.filters[filterGroupIndex];

        filterGroup.groupFilteringType = groupFilteringType;
      }

      const savedFilterGroupIndex = state.body.savedFilters.findIndex(
        (f) => f.id === filterGroupId
      );

      if (savedFilterGroupIndex !== -1) {
        // Ensure the filter group exists
        const savedFilterGroup = state.body.savedFilters[savedFilterGroupIndex];

        savedFilterGroup.groupFilteringType = groupFilteringType;
      }
    },
    duplicateFilterById: (
      state,
      action: PayloadAction<{ id: CommonFilter["id"] }>
    ) => {
      const { id } = action.payload;

      const findFilterRecursive = (
        filters: CommonFilter[],
        id: CommonFilter["id"]
      ) => {
        for (let i = 0; i < filters.length; i++) {
          if (filters[i].id === id) {
            filters[i].isInEditMode = false;

            const newFilter = {
              ...filters[i],
              id: uuidv4(),
              isInEditMode: true,
            };

            filters.push(newFilter as CommonFilter);
            return true; // Filter found and duplicated
          }

          // Recursively check for children
          if (filters[i].children && filters[i].children.length > 0) {
            const isDuplicated = findFilterRecursive(filters[i].children, id);
            if (isDuplicated) return true; // Filter found and duplicated in children
          }
        }
        return false; // Filter not found
      };

      setAllFiltersEditMode(state.filters as CommonFilter[], false);

      findFilterRecursive(state.filters as CommonFilter[], id);
    },
    resetFiltersEditMode: (state) => {
      const resetEditModeRecursive = (
        filters: Array<CommonFilter | FilterGroup>
      ) => {
        for (const filter of filters) {
          // Сбросить состояние редактирования для всех обычных фильтров
          if (isCommonFilter(filter)) {
            if (filter.isInEditMode) {
              // Если фильтр был в режиме редактирования, возвращаем его в исходное состояние
              const savedFilter = findFilterRecursive(
                state.body.savedFilters,
                filter.id
              );
              if (savedFilter) {
                Object.assign(filter, savedFilter);
              }
            }
            filter.isInEditMode = false;
          }

          // Рекурсивно применить к дочерним элементам
          if (filter.children) {
            resetEditModeRecursive(filter.children);
          }
        }
      };

      const findFilterRecursive = (
        filters: Array<CommonFilter | FilterGroup>,
        filterId: string
      ): CommonFilter | undefined => {
        for (const filter of filters) {
          if (isCommonFilter(filter) && filter.id === filterId) {
            return filter; // Найденный фильтр
          }
          // Поиск рекурсивно в детях
          if (filter.children) {
            const foundFilter = findFilterRecursive(filter.children, filterId);
            if (foundFilter) {
              return foundFilter;
            }
          }
        }
        return undefined; // Не найден
      };

      // Применяем функцию resetEditModeRecursive к каждому фильтру верхнего уровня
      state.filters.forEach((filter) => {
        if (isFilterGroup(filter)) {
          resetEditModeRecursive(filter.children);
        } else {
          resetEditModeRecursive([filter]);
        }
      });
    },
    resetToInitialState: (state) => {
      try {
        const parsedFilters = JSON.parse(state.initialFiltersAsString).map(
          (filter: CommonFilter[]) => ({
            ...filter,
            isInEditMode: false,
          })
        );

        state.body.savedFilters = parsedFilters;
        state.filters = parsedFilters;
      } catch (error) {
        console.error(error);
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setCurrentViewConfiguration.fulfilled, (state, action) => {
        const currentViewConfiguration = action.payload;

        if (!currentViewConfiguration) return;

        const [savedFilters, filters] = createFilterableFields(
          currentViewConfiguration.filters
        );

        state.body.savedFilters = savedFilters;
        state.filters = filters;
        state.initialFiltersAsString = stringifyFilters(filters);
      })
      .addMatcher(
        apiService.endpoints.updateViewConfiguration.matchFulfilled,
        (state, action) => {
          const { data } = action.payload;
          if (!data) return;
          const { filters: filtersFromResponse } = data;

          const [savedFilters, filters] =
            createFilterableFields(filtersFromResponse);

          state.body.savedFilters = savedFilters;
          state.filters = filters;
          state.initialFiltersAsString = stringifyFilters(filters);
        }
      );
  },
});

// TODO: Fix types
const createFilterableFields = (
  filters: any[]
): [CommonFilter[], CommonFilter[]] => {
  const groups: any = [];
  const _filters = filters
    .map((f) => {
      if (f.groupId && f.groupFilteringType) {
        const group = {
          id: f.groupId,
          isGroup: true,
          children: [],
          groupFilteringType: f.groupFilteringType,
        };

        groups.push(group);
        return group;
      }

      const filterVariant = {
        id: uuidv4(),
        additionalOptions: f.fieldInfo.additionalOptions || {},
        fieldId: f.fieldInfo.id,
        filterOption: determineFilterOptionFromAPIOperator(
          f.operator,
          f.fieldInfo.type
        ),
        isGroup: false,
        parentId: f.groupId,
        isInEditMode: false,
        isMulti: f.fieldInfo.isMulti,
        isSaved: !f.isDraft,
        name: f.fieldInfo.name,
        nameDB: f.fieldInfo.nameDB,
        refTo: f.fieldInfo.refTo,
        type: f.fieldInfo.type,
        value: f.value,
      };

      if (f.groupId) {
        const group = groups.find((g: any) => g.id === f.groupId);
        if (group) {
          group.children.push(filterVariant);
        }
      } else {
        return filterVariant;
      }
    })
    .filter(Boolean);

  return [_filters.filter((f: any) => f.isSaved || f.isGroup), _filters] as any;
};

export const {
  setGlobalFilteringType,
  addFilterVariant,
  setFilterValue,
  setActiveFilterById,
  setFilterOptionByFilterId,
  clearFilters,
  saveFilter,
  setFilterAsGroupHost,
  deleteFilterGroup,
  deleteFilterById,
  setGroupFilteringType,
  duplicateFilterById,
  resetFiltersEditMode,
  resetToInitialState,
} = filterSlice.actions;

export default filterSlice.reducer;
