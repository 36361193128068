import { ReactElement } from "react";
import { CONTENT_TYPES } from "constants/content-types";

import {
  MediaIcon,
  ComponentsIcon,
  CollectionTypeIcon,
  SinglePageTypeIcon,
  FolderIcon,
} from "icons";

const COMPONENT_FOLDER = "component-folder";

export const determineIcon = (type: string): ReactElement => {
  switch (type) {
    case "media":
      return <MediaIcon />;
    case COMPONENT_FOLDER:
      return <ComponentsIcon />;
    case CONTENT_TYPES.COLLECTION:
      return <CollectionTypeIcon />;
    case CONTENT_TYPES.SINGLE_PAGE:
      return <SinglePageTypeIcon />;
    default:
      return <FolderIcon />;
  }
};
