import {
  FC,
  useMemo,
  Fragment,
  useState,
  useEffect,
  useRef,
  SetStateAction,
  Dispatch,
} from "react";
import {
  ColumnDef,
  getCoreRowModel,
  flexRender,
  useReactTable,
} from "@tanstack/react-table";
import { Tooltip } from "react-tooltip";

import Row from "./Row";

import { FirstColumnCellRenderStrategy } from "strategies/FirstColumnCellRenderStrategy";
import { TextCellRenderStrategy } from "strategies/TextCellRenderStrategy";
import { BooleanCellRenderStrategy } from "strategies/BooleanCellRenderStrategy";
import { ImageCellRenderStrategy } from "strategies/ImageCellRenderStrategy";
import { AutomatCellRenderStrategy } from "strategies/AutomatCellRenderStrategy";
import { SelectCellRenderStrategy } from "strategies/SelectCellRenderStrategy";
import { RefCellRenderStrategy } from "strategies/RefCellRenderStrategy";
import { DefaultCellRenderStrategy } from "strategies/DefaultCellRenderStrategy";
import { createGripButtonCellRenderStrategy } from "strategies/GripButtonCellRenderStrategy";

import { useAppSelector } from "hooks/useAppSelector";
import { getColumnTypeClassName } from "utils/getColumnTypeClassName";
import { hasVerticalScrollbar } from "utils/hasVerticalScrollbar";

import type ICellRenderStrategy from "interfaces/ICellRenderStrategy";

import config from "config/config.json";

import { getScrollbarWidth } from "utils/getScrollbarWidth";
import { selectVisibleFields } from "store/selectors/fieldVisibilitySelectors";

import styles from "./DataTable.module.scss";

interface DataTableProps {
  data: any[];
  fields: any[];
  pathToCollection: string;
  isLoading: boolean;
  setSearchQuery: Dispatch<SetStateAction<string>>;
}

const scrollBarWidth = getScrollbarWidth();

const DataTable: FC<DataTableProps> = ({
  data,
  fields,
  pathToCollection,
  isLoading,
  setSearchQuery,
}) => {
  const [tableData, setTableData] = useState(data);

  const listWrapperRef = useRef<HTMLDivElement>(null);
  const [withScrollbar, setWithScrollbar] = useState(true);

  const checkForScrollbar = () => {
    if (listWrapperRef.current) {
      setWithScrollbar(hasVerticalScrollbar(listWrapperRef.current));
    }
  };

  // Initialize ResizeObserver
  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        checkForScrollbar();
      }
    });

    if (listWrapperRef.current) {
      resizeObserver.observe(listWrapperRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    setTableData(data);
  }, [data]);

  // Define cell render strategies
  const cellRenderStrategies: { [key: string]: ICellRenderStrategy<any> } =
    useMemo(
      () => ({
        firstColumn: new FirstColumnCellRenderStrategy(), // first column (name)
        text: new TextCellRenderStrategy(), // rich text
        boolean: new BooleanCellRenderStrategy(), // boolean
        image: new ImageCellRenderStrategy(), // image
        automat: new AutomatCellRenderStrategy(), // automatization field (two types: boolean and slugifator)
        select: new SelectCellRenderStrategy(),
        ref: new RefCellRenderStrategy(),
        default: new DefaultCellRenderStrategy(), // default strategy for text
        gripButton: createGripButtonCellRenderStrategy(), // grip button
      }),
      []
    );

  const columns: ColumnDef<any>[] = useMemo(() => {
    const schemaBodyWithGrip = [
      // exclude fields we don't need to show in list view
      ...fields.filter(
        (element: any) =>
          element.typeField !== "password" &&
          element.typeField !== "component" &&
          element.typeField !== "arr" &&
          element.typeField !== "jsonType"
      ),
      { typeField: "gripButton", nameDB: "gripButton" },
    ];

    return schemaBodyWithGrip.map((element: any, index: number) => {
      const isFirstColumn = index === 0;

      const renderStrategy = isFirstColumn
        ? cellRenderStrategies.firstColumn
        : cellRenderStrategies[element.typeField] ||
          cellRenderStrategies.default;

      return {
        header: (props: any) => {
          const columnTypeClassName = getColumnTypeClassName(
            element.typeField,
            element.settingField,
            styles
          );

          return (
            <div
              className={`${styles["flex-cell"]} ${
                isFirstColumn ? styles["first-column"] : columnTypeClassName
              }`}
            >
              {renderStrategy.renderHeaderCell(element, {
                setSearchQuery,
              })}
            </div>
          );
        },
        accessorKey: element.nameDB,
        cell: (props: any) => {
          return (
            <>
              {renderStrategy &&
                typeof renderStrategy.renderCell === "function" &&
                renderStrategy.renderCell(element, {
                  ...props,
                  type: element.typeField,
                  value: props.cell.getValue(),
                  pathToCollection: `${pathToCollection}/${props.row.original._id}`,
                  styles,
                })}
            </>
          );
        },
        meta: {
          columnSchema: element,
        },
      };
    });
  }, [fields, setSearchQuery, cellRenderStrategies, pathToCollection]);

  const columnVisibilityFields = useAppSelector(selectVisibleFields);

  const columnVisibility = useMemo(() => {
    return columnVisibilityFields.reduce<Record<string, boolean>>(
      (acc, column) => {
        acc[column.id] = column.isVisible;
        return acc;
      },
      {}
    );
  }, [columnVisibilityFields]);

  const { defaultColumnName } = config.collection;
  const columnOrder = useMemo(() => {
    return columnVisibilityFields.reduce<string[]>(
      (acc, column) => {
        acc.push(column.id);
        return acc;
      },
      [defaultColumnName]
    );
  }, [columnVisibilityFields, defaultColumnName]);

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      columnVisibility,
      columnOrder,
    },
    getCoreRowModel: getCoreRowModel(),
  });

  const handleDeleteRow = (rowOriginalId: string) => {
    const updatedData = tableData.filter((row) => {
      return row._id !== rowOriginalId;
    });

    setTableData(updatedData);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles["flex-table"]}>
        {table.getHeaderGroups().map((headerGroup) => (
          <div
            key={headerGroup.id}
            className={styles["header-wrapper"]}
          >
            <div
              className={styles["flex-row"] + " " + styles["header"]}
              style={{ paddingRight: withScrollbar ? scrollBarWidth : 0 }}
            >
              {headerGroup.headers.map((header) => {
                return (
                  <Fragment key={header.id}>
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </Fragment>
                );
              })}
            </div>
          </div>
        ))}
        <div
          ref={listWrapperRef}
          className={styles["list-wrapper"]}
          style={{
            opacity: isLoading ? 0.5 : 1,
            pointerEvents: isLoading ? "none" : "all",
          }}
        >
          {table.getRowModel().rows.map((row) => {
            return (
              <Row
                key={row.id}
                row={row}
                handleDeleteRow={handleDeleteRow}
              />
            );
          })}
        </div>
      </div>
      <Tooltip
        className={styles.tooltip}
        id="data-table-tooltip"
      />
    </div>
  );
};

export default DataTable;
