const SERVER_URL =
  process.env.REACT_APP_FLEXIM_SERVER_URL || "http://127.0.0.1:3260";

const API_VERSION = process.env.REACT_APP_FLEXIM_API_VERSION || "v1";

const SOCKET_URL = process.env.REACT_APP_FLEXIM_SOCKET_URL || "127.0.0.1:3260";

console.log(`SERVER_URL`, SERVER_URL);

import { MediaIcon } from "icons";

//http://127.0.0.1:3060
//http://api.cms.dev.guys.agency

const settingsFields = {
  input: {
    badge: (
      <span className="badge me-3">
        <i className="bi bi-type" />
      </span>
    ),
    name: "Text",
    desc: "Small or long text, like a title",
    def: {
      name: "",
      nameDB: "",
      typeField: "input",
      settingField: "input",
      defaultValue: "",
      regexpField: "",
      requiredField: false,
      uniqueField: false,
      closeField: false,
      maxLengthField: 0,
      minLengthField: 0,
    },
  },
  email: {
    badge: (
      <span className="badge me-3">
        <i className="bi bi-at" />
      </span>
    ),
    name: "Email",
    desc: "Field with format validation of e-mail",
    def: {
      name: "",
      nameDB: "",
      typeField: "email",
      defaultValue: "",
      requiredField: false,
      uniqueField: false,
      closeField: false,
      maxLengthField: 0,
      minLengthField: 0,
    },
  },
  text: {
    badge: (
      <span className="badge me-3">
        <i className="bi bi-card-text" />
      </span>
    ),
    name: "Rich text",
    desc: "Big text with editing tools",
    def: {
      name: "",
      nameDB: "",
      typeField: "text",
      defaultValue: "",
      requiredField: false,
      uniqueField: false,
      closeField: false,
      maxLengthField: 0,
      minLengthField: 0,
    },
  },
  password: {
    badge: (
      <span className="badge me-3">
        <i className="bi bi-asterisk" />
      </span>
    ),
    name: "Password",
    desc: "Field with encryption",
    def: {
      name: "",
      nameDB: "",
      typeField: "password",
      defaultValue: "",
      requiredField: false,
      uniqueField: false,
      closeField: false,
      maxLengthField: 0,
      minLengthField: 0,
    },
  },
  number: {
    badge: (
      <span className="badge me-3">
        <i className="bi bi-sort-numeric-down" />
      </span>
    ),
    name: "Number",
    desc: "Whole, floating, decimal",
    def: {
      name: "",
      nameDB: "",
      typeField: "number",
      defaultValue: "",
      requiredField: false,
      uniqueField: false,
      closeField: false,
      maxLengthField: 0,
      minLengthField: 0,
    },
  },
  select: {
    badge: (
      <span className="badge me-3">
        <i className="bi bi-list" />
      </span>
    ),
    name: "Select",
    desc: "List of values",
    def: {
      name: "",
      nameDB: "",
      typeField: "select",
      selectVars: [],
      defaultValue: "",
      requiredField: false,
      uniqueField: false,
      closeField: false,
      isMulti: false,
    },
  },
  date: {
    badge: (
      <span className="badge me-3">
        <i className="bi bi-calendar-date" />
      </span>
    ),
    name: "Date",
    desc: "Date and time control",
    def: {
      name: "",
      nameDB: "",
      typeField: "date",
      defaultValue: "",
      requiredField: false,
      uniqueField: false,
      closeField: false,
    },
  },
  image: {
    badge: (
      <span className="badge me-3">
        <i className="bi bi-image" />
      </span>
    ),
    name: "Media",
    desc: "Photo and video files",
    def: {
      name: "",
      nameDB: "",
      typeField: "image",
      defaultValue: "",
      requiredField: false,
      closeField: false,
      isMulti: false,
    },
  },
  boolean: {
    badge: (
      <span className="badge me-3">
        <i className="bi bi-toggle-on" />
      </span>
    ),
    name: "Boolean",
    desc: "Yes or No, 1 or 0, True or False",
    def: {
      name: "",
      nameDB: "",
      typeField: "boolean",
      defaultValue: "",
      requiredField: false,
      uniqueField: false,
      closeField: false,
    },
  },
  jsonType: {
    badge: (
      <span className="badge me-3">
        <i className="bi bi-slash-square" />
      </span>
    ),
    name: "JSON",
    desc: "Data in JSON format",
    def: {
      name: "",
      nameDB: "",
      typeField: "jsonType",
      defaultValue: "",
      requiredField: false,
      uniqueField: false,
      closeField: false,
    },
  },
  ref: {
    badge: (
      <span className="badge me-3">
        <i className="bi bi-share" />
      </span>
    ),
    name: "Relation",
    desc: "Link to another collection",
    def: {
      name: "",
      nameDB: "",
      typeField: "ref",
      refTo: "",
      requiredField: false,
      uniqueField: false,
      closeField: false,
    },
  },
  component: {
    badge: (
      <span className="badge me-3">
        <i className="bi bi-layers" />
      </span>
    ),
    name: "Component",
    desc: "Group of fields",
    def: {
      name: "",
      nameDB: "",
      typeField: "component",
      requiredField: false,
      isMulti: false,
    },
  },
  arr: {
    badge: (
      <span className="badge me-3">
        <i className="bi bi-collection" />
      </span>
    ),
    name: "Array",
    desc: "Array of data",
    def: {
      name: "",
      nameDB: "",
      typeField: "arr",
      separator: "",
      uniqueField: false,
      closeField: false,
      maxLengthField: 0,
      minLengthField: 0,
      requiredField: false,
    },
  },
  automat: {
    badge: (
      <span className="badge me-3">
        <i className="bi bi-circle" />
      </span>
    ),
    name: "Auto generate",
    desc: "Slagifactor and conditions",
    def: {
      name: "",
      nameDB: "",
      typeField: "automat",
      settingField: "",
      // uniqueField: false,
      closeField: false,
      // maxLengthField: 0,
      // minLengthField: 0,
    },
  },
};

const CONSTANT_MENU = [
  {
    name: "Media",
    link: "media",
    type: "media",
    icon: <MediaIcon />,
    showIcon: true,
  },
];

export { SERVER_URL, API_VERSION, SOCKET_URL, settingsFields, CONSTANT_MENU };
