import { FC } from "react";
import { CloseIcon } from "icons";

import styles from "./CloseButton.module.scss";

interface CloseButtonProps {
  onClose: () => void;
  isActive?: boolean;
}

const CloseButton: FC<CloseButtonProps> = ({ onClose, isActive }) => {
  const wrapperClassName = `${styles.wrapper}${
    isActive ? ` ${styles.wrapper_active}` : ""
  }`;

  return (
    <div
      className={wrapperClassName}
      onClick={onClose}
    >
      <CloseIcon />
    </div>
  );
};

export default CloseButton;
