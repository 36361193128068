import { FC } from "react";

const ChevronDownIcon: FC = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.44024 4.06506C1.48088 4.02431 1.52916 3.99199 1.58231 3.96993C1.63546 3.94788 1.69244 3.93652 1.74999 3.93652C1.80754 3.93652 1.86452 3.94788 1.91767 3.96993C1.97082 3.99199 2.0191 4.02431 2.05974 4.06506L6.99999 9.00618L11.9402 4.06506C11.9809 4.02438 12.0292 3.99211 12.0824 3.9701C12.1355 3.94808 12.1925 3.93675 12.25 3.93675C12.3075 3.93675 12.3645 3.94808 12.4176 3.9701C12.4708 3.99211 12.5191 4.02438 12.5597 4.06506C12.6004 4.10573 12.6327 4.15402 12.6547 4.20717C12.6767 4.26032 12.688 4.31728 12.688 4.37481C12.688 4.43233 12.6767 4.48929 12.6547 4.54244C12.6327 4.59559 12.6004 4.64388 12.5597 4.68456L7.30974 9.93456C7.2691 9.9753 7.22082 10.0076 7.16767 10.0297C7.11452 10.0517 7.05753 10.0631 6.99999 10.0631C6.94244 10.0631 6.88546 10.0517 6.83231 10.0297C6.77916 10.0076 6.73088 9.9753 6.69024 9.93456L1.44024 4.68456C1.3995 4.64392 1.36717 4.59564 1.34512 4.54248C1.32306 4.48933 1.31171 4.43235 1.31171 4.37481C1.31171 4.31726 1.32306 4.26028 1.34512 4.20713C1.36717 4.15397 1.3995 4.1057 1.44024 4.06506Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ChevronDownIcon;
