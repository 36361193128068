import { FILTERABLE_FIELD_TYPES } from "constants/index";
import type { Field } from "typings/filter";

/**
 * Filters an array of fields and returns only the suitable for filtering fields.
 * @param fields - The array of fields to filter.
 * @returns An array of fields types suitable for filtering.
 */
export const getFilterableFieldTypes = (fields: Field[]): Field[] => {
  return fields.filter((field) => FILTERABLE_FIELD_TYPES.includes(field.type));
};
