import { FC, useState } from "react";
import { Row as ReactTableRow, flexRender } from "@tanstack/react-table";

import styles from "./Table.module.scss";

type RowProps = {
  row: ReactTableRow<any>;
  gridTemplateColumns: string;
};

const Row: FC<RowProps> = ({ row, gridTemplateColumns }) => {
  const [activeRow, setActiveRow] = useState<ReactTableRow<any> | null>(null);

  return (
    <div
      key={row.id}
      className={`${styles["row"]}${
        activeRow?.id === row.id ? ` ${styles["active"]}` : ""
      }`}
      style={{
        gridTemplateColumns,
      }}
    >
      {row.getVisibleCells().map((cell, index) => {
        return (
          <div
            key={cell.id}
            className={`${styles["cell"]} ${
              index === row.getVisibleCells().length - 1
                ? styles["action-cell"]
                : ""
            }`}
          >
            {flexRender(cell.column.columnDef.cell, {
              ...cell.getContext(),
              setActiveRow,
              activeRow,
            })}
          </div>
        );
      })}
    </div>
  );
};

export default Row;
