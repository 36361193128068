import {
  AutomatIcon,
  CardTextIcon,
  DateIcon,
  EmailIcon,
  HashIcon,
  ImageIcon,
  RefIcon,
  SelectIcon,
  TextIcon,
  ToggleOnIcon,
} from "icons/index";

const iconsByType: { [key: string]: React.ReactElement } = {
  text: <CardTextIcon />,
  number: <HashIcon />,
  boolean: <ToggleOnIcon />,
  image: <ImageIcon />,
  email: <EmailIcon />,
  input: <TextIcon />,
  select: <SelectIcon />,
  ref: <RefIcon />,
  automat: <AutomatIcon />,
  date: <DateIcon />,
};

export default iconsByType;
