import { FC } from "react";

const SearchIcon: FC = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4419 10.4422C10.5348 10.3492 10.6451 10.2755 10.7665 10.2252C10.8879 10.1748 11.018 10.1489 11.1494 10.1489C11.2809 10.1489 11.411 10.1748 11.5324 10.2252C11.6538 10.2755 11.7641 10.3492 11.8569 10.4422L15.7069 14.2922C15.8946 14.4797 16.0001 14.7341 16.0001 14.9994C16.0002 15.2646 15.895 15.5191 15.7074 15.7067C15.5199 15.8944 15.2656 15.9998 15.0003 15.9999C14.735 16 14.4806 15.8947 14.2929 15.7072L10.4429 11.8572C10.35 11.7643 10.2762 11.6541 10.2259 11.5327C10.1756 11.4113 10.1497 11.2811 10.1497 11.1497C10.1497 11.0183 10.1756 10.8882 10.2259 10.7668C10.2762 10.6454 10.35 10.5351 10.4429 10.4422H10.4419Z"
        fill="inherit"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.5 12C7.22227 12 7.93747 11.8577 8.60476 11.5813C9.27205 11.3049 9.87837 10.8998 10.3891 10.3891C10.8998 9.87837 11.3049 9.27205 11.5813 8.60476C11.8577 7.93747 12 7.22227 12 6.5C12 5.77773 11.8577 5.06253 11.5813 4.39524C11.3049 3.72795 10.8998 3.12163 10.3891 2.61091C9.87837 2.10019 9.27205 1.69506 8.60476 1.41866C7.93747 1.14226 7.22227 1 6.5 1C5.04131 1 3.64236 1.57946 2.61091 2.61091C1.57946 3.64236 1 5.04131 1 6.5C1 7.95869 1.57946 9.35764 2.61091 10.3891C3.64236 11.4205 5.04131 12 6.5 12ZM13 6.5C13 8.22391 12.3152 9.87721 11.0962 11.0962C9.87721 12.3152 8.22391 13 6.5 13C4.77609 13 3.12279 12.3152 1.90381 11.0962C0.684819 9.87721 0 8.22391 0 6.5C0 4.77609 0.684819 3.12279 1.90381 1.90381C3.12279 0.684819 4.77609 0 6.5 0C8.22391 0 9.87721 0.684819 11.0962 1.90381C12.3152 3.12279 13 4.77609 13 6.5Z"
        fill="inherit"
      />
    </svg>
  );
};

export default SearchIcon;
