import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface MenuState {
  menu: any | null; // TODO: Replace 'any' with actual menu type if possible
}

const initialState: MenuState = {
  menu: null,
};

export const menuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {
    // Use PayloadAction to automatically infer the type of the payload
    updateMenu: (state, action: PayloadAction<any>) => {
      // TODO: Replace 'any' with actual menu type if possible
      state.menu = action.payload;
    },
  },
});

export const { updateMenu } = menuSlice.actions;

export default menuSlice.reducer;
