import { FC } from "react";

import styles from "./VerticalDivider.module.scss";

interface VerticalDividerProps {
  className?: string;
}

const VerticalDivider: FC<VerticalDividerProps> = ({ className }) => {
  const finalClassName = `${styles.divider}${className ? ` ${className}` : ""}`;

  return <div className={finalClassName} />;
};

export default VerticalDivider;
