import { FC } from "react";

const PencilIcon: FC = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2064_91841)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.1497 0.132245C12.1962 0.0856821 12.2513 0.0487393 12.3121 0.0235328C12.3728 -0.00167363 12.4379 -0.0146484 12.5037 -0.0146484C12.5695 -0.0146484 12.6346 -0.00167363 12.6953 0.0235328C12.7561 0.0487393 12.8113 0.0856821 12.8577 0.132245L15.8577 3.13225C15.9043 3.17869 15.9412 3.23387 15.9664 3.29461C15.9916 3.35536 16.0046 3.42048 16.0046 3.48625C16.0046 3.55201 15.9916 3.61713 15.9664 3.67788C15.9412 3.73862 15.9043 3.7938 15.8577 3.84025L5.85771 13.8402C5.80973 13.8879 5.75257 13.9253 5.68971 13.9502L0.689712 15.9502C0.598848 15.9866 0.499308 15.9955 0.403432 15.9759C0.307556 15.9562 0.21956 15.9088 0.150353 15.8396C0.0811466 15.7704 0.0337731 15.6824 0.0141056 15.5865C-0.00556185 15.4906 0.00334166 15.3911 0.0397123 15.3002L2.03971 10.3002C2.06464 10.2374 2.10206 10.1802 2.14971 10.1322L12.1497 0.132245ZM11.2107 2.48625L13.5037 4.77925L14.7967 3.48625L12.5037 1.19325L11.2107 2.48625ZM12.7967 5.48625L10.5037 3.19325L4.00371 9.69324V9.98625H4.50371C4.63632 9.98625 4.7635 10.0389 4.85727 10.1327C4.95103 10.2265 5.00371 10.3536 5.00371 10.4862V10.9862H5.50371C5.63632 10.9862 5.7635 11.0389 5.85727 11.1327C5.95103 11.2265 6.00371 11.3536 6.00371 11.4862V11.9862H6.29671L12.7967 5.48625ZM3.03571 10.6612L2.92971 10.7672L1.40171 14.5882L5.22271 13.0602L5.32871 12.9542C5.23333 12.9186 5.1511 12.8547 5.09303 12.7711C5.03495 12.6874 5.00379 12.5881 5.00371 12.4862V11.9862H4.50371C4.3711 11.9862 4.24393 11.9336 4.15016 11.8398C4.05639 11.746 4.00371 11.6189 4.00371 11.4862V10.9862H3.50371C3.40189 10.9862 3.30252 10.955 3.21889 10.8969C3.13526 10.8389 3.07135 10.7566 3.03571 10.6612Z"
          fill="inherit"
        />
      </g>
    </svg>
  );
};

export default PencilIcon;
