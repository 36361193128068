import { Dispatch, SetStateAction, useCallback, useState } from "react";
import { parseFilterToQuery } from "comp/common/funcs/common";
import { STATUSES } from "constants/statuses";
import { SERVER_URL } from "func/constants";
import { createWebsocketConnection } from "helpers/createWebsocketConnection";

type ExcelhandlersProps = {
  collectionId: string;
  currentCMS: string;
};

const useExcelHandlers = ({ collectionId, currentCMS }: ExcelhandlersProps) => {
  const [statusImportExcel, setStatusImportExcel] = useState("download");
  const [statusExportExcel, setStatusExportExcel] = useState({
    status: "start",
  });
  const [openImportProgressPopup, setOpenImportProgressPopup] = useState(false);
  const [openExportProgressPopup, setOpenExportProgressPopup] = useState(false);

  const handleExportExcel = useCallback(() => {
    setOpenExportProgressPopup(true);

    const ws = createWebsocketConnection(
      {
        type: "client:exportExcel",
        payload: {
          nameColl: collectionId, // TODO: Rename nameColl to collectionId
          filtrs: parseFilterToQuery({ filters: [], typeAnd: true } as any), // TODO: Add actual filters and "typeAnd"
          cms: currentCMS, // TODO: Rename cms to currentCMS
        },
      },
      (message) => {
        try {
          const _message = JSON.parse(message.data);

          if (_message.type === "server:exportExcel") {
            const aLink = document.createElement("a");
            aLink.href = SERVER_URL + _message.payload;
            aLink.target = "_blank";
            aLink.download = "export.xlsx";
            aLink.click();

            setStatusExportExcel({ status: "finish" });

            ws.close();
          }
        } catch (err) {
          console.error(err);
        }
      }
    );
  }, [collectionId, currentCMS]);

  const handleImportExcel = useCallback(
    (e: any) => {
      setOpenImportProgressPopup(true);

      const file = e.target.files[0];
      let ws: WebSocket;

      const reader = new FileReader();
      reader.onload = (event) => {
        const arrayBuffer = (event.target && event.target.result) || null;
        const name = (e.target.files && e.target.files[0].name) || null;

        ws = createWebsocketConnection(
          {
            type: "client:importExcel",
            payload: {
              arrayBuffer,
              size: file.size,
              nameColl: collectionId, // TODO: Rename nameColl to collectionId
              name,
              cms: currentCMS, // TODO: Rename cms to currentCMS
            },
          },
          handleWebSocketMessage
        );
      };

      reader.readAsArrayBuffer(file);

      function handleWebSocketMessage(message: any) {
        try {
          const _message = JSON.parse(message.data);
          const { status } = _message.payload;

          if (_message.type === "server:importExcel") {
            handleStatus(status);

            if (status === STATUSES.CLOSE) {
              ws.close();
            }
          }
        } catch (err) {
          console.error(err);
          // TODO: Consider adding error handling in the UI here
        }
      }

      function handleStatus(status: string) {
        const statusHandlers = {
          [STATUSES.START]: () => setStatusImportExcel(STATUSES.START),
          [STATUSES.START2]: () => setStatusImportExcel(STATUSES.START2),
          [STATUSES.FINISH]: () => setStatusImportExcel(STATUSES.FINISH),
          [STATUSES.CLOSE]: () => setStatusImportExcel(STATUSES.CLOSE),
        };

        const handle = statusHandlers[status];
        if (handle) {
          handle();
        }
      }
    },
    [collectionId, currentCMS]
  );

  return {
    handleExportExcel,
    handleImportExcel,
    popup: {
      setOpenExportProgressPopup,
      setOpenImportProgressPopup,
      openExportProgressPopup,
      openImportProgressPopup,
      statusExportExcel,
      statusImportExcel,
    },
  };
};

export default useExcelHandlers;
