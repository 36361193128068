/* eslint-disable react/prop-types */
import React, { useRef, useState } from "react";
import { SERVER_URL, API_VERSION } from "../../func/constants";
import { copyToBuffer } from "../../func/common";
import api from "../../func/api";
import { ReactSortable } from "react-sortablejs";
import { uploadImage } from "helpers/uploadImage";
import RoundButton from "components/RoundButton/RoundButton";
import GripIcon from "icons/GripIcon";
import useDeleteData from "hooks/useDeleteData";

const ManyMedia = ({
  pics = [],
  name,
  getData,
  setEditMedia,
  onChangeArray,
  onDelete,
  isRequired,
}) => {
  const [showURL, setShowURL] = useState(false);

  const deletePic = (i) => {
    pics.splice(i, 1);
    onChangeArray(pics);
    onDelete(pics);
  };

  const inputFile = useRef(null);

  const picsHTML = pics?.map((pic, i) => {
    return (
      <ManyMediaElement
        pic={pic}
        i={i}
        setEditMedia={setEditMedia}
        key={pic.hash + i}
        deletePic={deletePic}
      />
    );
  });

  return (
    <div className="">
      <h6
        className={`flexim-field-name flexim-field-name--inline${
          isRequired ? " required" : ""
        }`}
      >
        {name}
      </h6>

      {picsHTML && (
        <ReactSortable
          animation={200}
          delayOnTouchStart={true}
          delay={2}
          list={pics}
          setList={(newOrderArr) => {
            onChangeArray(newOrderArr);
          }}
          group="shared"
          className="row g-3"
          style={{ marginBottom: "14px" }}
          swapThreshold={0.65}
          handle=".grip-button"
        >
          {picsHTML}
        </ReactSortable>
      )}

      {showURL ? (
        <div className="input-group mt-3 mb-3">
          <input
            type="text"
            className="form-control"
          />
          <span className="input-group-text pointer">Upload</span>
          <span
            className="input-group-text pointer"
            onClick={() => {
              setShowURL(false);
            }}
          >
            <i className="bi bi-x" />
          </span>
        </div>
      ) : (
        <div className="input-group">
          <RoundButton
            after={
              <i
                className="bi bi-camera-fill"
                style={{
                  fontSize: "18px",
                  alignSelf: "flex-end",
                  height: "24px",
                }}
              />
            }
            onClick={(e) => {
              inputFile.current.click();
            }}
          >
            Upload image
            <input
              ref={inputFile}
              type="file"
              name="uploadImg"
              accept="image/jpeg,image/png,image/svg+xml"
              onInput={async (e) => {
                await uploadImage({
                  event: e,
                  loadAllImagesCallback: getData,
                });
              }}
            />
          </RoundButton>
        </div>
      )}
    </div>
  );
};

const ManyMediaElement = ({ pic, i, setEditMedia, deletePic }) => {
  const endpointURL = `${SERVER_URL}/api/${API_VERSION}/main/media`;
  const { deleteData: deleteMediaRequest } = useDeleteData(endpointURL);

  const imgLink = `${SERVER_URL + "/" + pic.url}`;
  const linkImg = useRef(null);
  const btnClickEffect = (e) => {
    e.target.classList.add("text-success");
    setTimeout(() => {
      e.target.classList.remove("text-success");
    }, 2000);
  };
  return (
    <div className="col-4">
      <div className="flexim-multi-image__element">
        <div
          style={{
            backgroundImage: "url(" + imgLink + ")",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            backgroundPosition: "center",
            minHeight: "280px",
            marginBottom: "4px",
          }}
        >
          <div className="d-flex flex-column align-items-end">
            <div
              className="icon-badge text-center pointer"
              style={{
                borderRadius: "8px",
              }}
              onClick={async () => {
                if (pic._id) {
                  await deleteMediaRequest(pic._id);
                }

                deletePic(i);
              }}
            >
              <i
                className="bi bi-trash"
                style={{ color: "#F04343" }}
              />
            </div>
            <div
              className="icon-badge text-center pointer"
              style={{
                borderRadius: "8px",
              }}
              onClick={() => {
                setEditMedia(pic, i);
              }}
            >
              <i className="bi bi-pencil" />
            </div>
            <div
              className="icon-badge text-center pointer"
              style={{
                borderRadius: "8px",
              }}
              onClick={(e) => {
                copyToBuffer(imgLink);
                btnClickEffect(e);
              }}
            >
              <i className="bi bi-link-45deg" />
            </div>
            <div
              className="icon-badge text-center pointer"
              style={{
                borderRadius: "8px",
              }}
              onClick={() => {
                linkImg.current.click();
              }}
            >
              <i className="bi bi-box-arrow-in-down" />
              <a
                href={imgLink}
                download
                style={{ opacity: 0, visibility: "hidden" }}
                target="_blank"
                ref={linkImg}
                rel="noreferrer"
              ></a>
            </div>
          </div>
        </div>
        <div
          className="d-flex align-items-center"
          style={{ gap: "4px" }}
        >
          <input
            type="text"
            placeholder="Image alt"
            className="flexim-input"
            defaultValue={pic.alt}
            onChange={(e) => {
              pic.alt = e.target.value;
            }}
            onBlur={() => {
              api
                .postAltMedia({ _id: pic._id, alt: pic.alt })
                .then((result) => {})
                .catch((err) => {});
            }}
          />
          <div
            className="grip-button"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "26px",
              height: "26px",
              cursor: "pointer",
              fill: "#C2C2C2",
            }}
          >
            <GripIcon />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManyMedia;
