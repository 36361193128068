import { FC } from "react";

const XSquareIcon: FC = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="inherit"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 1H2C1.73478 1 1.48043 1.10536 1.29289 1.29289C1.10536 1.48043 1 1.73478 1 2V14C1 14.2652 1.10536 14.5196 1.29289 14.7071C1.48043 14.8946 1.73478 15 2 15H14C14.2652 15 14.5196 14.8946 14.7071 14.7071C14.8946 14.5196 15 14.2652 15 14V2C15 1.73478 14.8946 1.48043 14.7071 1.29289C14.5196 1.10536 14.2652 1 14 1ZM2 0C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2L0 14C0 14.5304 0.210714 15.0391 0.585786 15.4142C0.960859 15.7893 1.46957 16 2 16H14C14.5304 16 15.0391 15.7893 15.4142 15.4142C15.7893 15.0391 16 14.5304 16 14V2C16 1.46957 15.7893 0.960859 15.4142 0.585786C15.0391 0.210714 14.5304 0 14 0L2 0Z"
        fill="inherit"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.64689 4.64592C4.69334 4.59935 4.74851 4.56241 4.80926 4.5372C4.87001 4.512 4.93513 4.49902 5.00089 4.49902C5.06666 4.49902 5.13178 4.512 5.19253 4.5372C5.25327 4.56241 5.30845 4.59935 5.35489 4.64592L8.00089 7.29292L10.6469 4.64592C10.6934 4.59943 10.7486 4.56255 10.8093 4.53739C10.87 4.51223 10.9351 4.49929 11.0009 4.49929C11.0666 4.49929 11.1317 4.51223 11.1925 4.53739C11.2532 4.56255 11.3084 4.59943 11.3549 4.64592C11.4014 4.6924 11.4383 4.74759 11.4634 4.80833C11.4886 4.86907 11.5015 4.93417 11.5015 4.99992C11.5015 5.06566 11.4886 5.13076 11.4634 5.1915C11.4383 5.25224 11.4014 5.30743 11.3549 5.35392L8.70789 7.99992L11.3549 10.6459C11.4014 10.6924 11.4383 10.7476 11.4634 10.8083C11.4886 10.8691 11.5015 10.9342 11.5015 10.9999C11.5015 11.0657 11.4886 11.1308 11.4634 11.1915C11.4383 11.2522 11.4014 11.3074 11.3549 11.3539C11.3084 11.4004 11.2532 11.4373 11.1925 11.4624C11.1317 11.4876 11.0666 11.5005 11.0009 11.5005C10.9351 11.5005 10.87 11.4876 10.8093 11.4624C10.7486 11.4373 10.6934 11.4004 10.6469 11.3539L8.00089 8.70692L5.35489 11.3539C5.30841 11.4004 5.25322 11.4373 5.19248 11.4624C5.13174 11.4876 5.06664 11.5005 5.00089 11.5005C4.93515 11.5005 4.87005 11.4876 4.80931 11.4624C4.74857 11.4373 4.69338 11.4004 4.64689 11.3539C4.60041 11.3074 4.56353 11.2522 4.53837 11.1915C4.51321 11.1308 4.50026 11.0657 4.50026 10.9999C4.50026 10.9342 4.51321 10.8691 4.53837 10.8083C4.56353 10.7476 4.60041 10.6924 4.64689 10.6459L7.29389 7.99992L4.64689 5.35392C4.60033 5.30747 4.56339 5.2523 4.53818 5.19155C4.51297 5.13081 4.5 5.06568 4.5 4.99992C4.5 4.93415 4.51297 4.86903 4.53818 4.80828C4.56339 4.74754 4.60033 4.69236 4.64689 4.64592Z"
        fill="inherit"
      />
    </svg>
  );
};

export default XSquareIcon;
