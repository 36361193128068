import { useParams } from "react-router-dom";

function useRequiredParam(paramName: string) {
  const params = useParams();

  const paramValue = params[paramName];
  if (!paramValue) {
    throw new Error(`${paramName} is undefined. Please check your route.`);
  }

  return paramValue;
}

export default useRequiredParam;
