/**
 * Removes empty objects from the given object and returns a copy.
 *
 * @param obj - The object from which to remove empty objects.
 * @returns A copy of the object with empty objects removed.
 * @throws If an error occurs during the process.
 */
export default function removeEmptyObjectsAndReturnCopy(
  obj: Record<string, object>
) {
  try {
    if (obj == null || typeof obj !== "object") {
      // Возвращаем obj как есть, если он null, undefined или не объект.
      return obj;
    }

    // Создаём глубокую копию объекта, чтобы не изменять исходный объект.
    // Простое распространение свойств {...obj} не создаёт глубокую копию вложенных объектов,
    // поэтому для вложенных объектов потребуется рекурсивное копирование или использование
    // других методов, если объект содержит вложенные объекты.
    const copy: Record<string, object> = JSON.parse(JSON.stringify(obj));

    for (const key in copy) {
      if (Object.prototype.hasOwnProperty.call(copy, key)) {
        const value = copy[key];
        // Проверяем, является ли значение пустым объектом
        if (
          typeof value === "object" &&
          value !== null &&
          Object.keys(value).length === 0
        ) {
          delete copy[key];
        }
      }
    }
    return copy;
  } catch (error) {
    console.error("An error occurred:", error);
    throw new Error("Failed to process the object.");
  }
}
