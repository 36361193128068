import { Suspense, lazy } from "react";
import ErrorBoundary from "../error-boundary/ErrorBoundary";
import { isModalOpen, getModalMeta } from "store/selectors/modalSelectors";
import { closeModal } from "store/slices/modalSlice";
import { useAppSelector } from "hooks/useAppSelector";
import { useAppDispatch } from "hooks/useAppDispatch";

interface LazyComponentProps {
  filename: string;
}

/**
 * Renders a lazy-loaded component.
 * @param {LazyComponentProps} props - The component props.
 * @returns {JSX.Element} The rendered component.
 */
export function LazyComponent({ filename }: LazyComponentProps): JSX.Element {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector((state) => isModalOpen(state, filename));
  const meta = useAppSelector((state) => getModalMeta(state, filename));

  const handleModalClose = () => {
    dispatch(closeModal({ modalFileName: filename }));
  };

  const Component = lazy(() => import(`./${filename}/${filename}.tsx`));

  return (
    <Suspense fallback={null}>
      <ErrorBoundary>
        {filename ? (
          <Component
            isOpen={isOpen}
            onClose={handleModalClose}
            {...meta}
          />
        ) : null}
      </ErrorBoundary>
    </Suspense>
  );
}
