import { FC, useEffect, useState } from "react";
import api from "func/api";
import FilterWrapper from "./FilterWrapper";
import FilterSelect from "./FilterSelect";

const FilterRef: FC<any> = (props) => {
  const [options, setOptions] = useState<{ label: string; value: string }[]>(
    []
  );

  useEffect(() => {
    api
      .filterDatas({
        typeModel: "collections",
        model: props.filter.refTo,
      })
      .then((result) => {
        if (result.status === "success" && result.data.items.length) {
          const items = result.data.items;

          let key = Object.keys(items[0]);
          key.splice(key.indexOf("_id"), 1);
          const _options = items.map((el: any) => ({
            label: el["name"] || el[key[0]],
            value: el._id,
          }));
          setOptions([..._options]);
        }
      })
      .catch((err) => {
        console.error("err", err);
      });
  }, [props.filter.refTo]);

  return (
    <FilterWrapper filter={props.filter}>
      <FilterSelect
        value={options}
        filter={props.filter}
      />
    </FilterWrapper>
  );
};

export default FilterRef;
