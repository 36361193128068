import { createAsyncThunk } from "@reduxjs/toolkit";
import { selectViewConfigurationsResult } from "api/apiService";
import api from "func/api";
import { collectionIdSelector } from "store/selectors/collectionPageInfoSelectors";
import { setCurrentViewConfigurationId } from "store/slices/collectionPageInfoSlice";
import { RootState } from "store/store";
import type { APIResponse } from "typings/apiState";
import type { ViewConfiguration } from "pages/Collection/components/ViewConfigurations/interfaces/ViewConfiguration";
import type { Field } from "typings/filter";

type SetCurrentViewConfigurationPayload = {
  viewConfigurationId: string;
  configurations?: APIResponse<ViewConfiguration[]> | undefined;
};

export const setCurrentViewConfiguration = createAsyncThunk(
  "collectionPageInfo/setCurrentViewConfiguration",
  async (
    { viewConfigurationId, configurations }: SetCurrentViewConfigurationPayload,
    { getState, dispatch }
  ) => {
    const state: RootState = getState() as RootState;

    const collectionId = collectionIdSelector(state);
    const { data: selectedConfigurations } =
      selectViewConfigurationsResult(collectionId)(state); // TODO: Find out why this is sometimes undefined

    let foundConfiguration = selectedConfigurations?.data?.find(
      (_configurations) => _configurations._id === viewConfigurationId
    );

    if (
      !foundConfiguration &&
      configurations &&
      configurations.data &&
      configurations.data.length > 0
    ) {
      foundConfiguration = configurations?.data?.find(
        (_configurations) => _configurations._id === viewConfigurationId
      );
    }

    dispatch(setCurrentViewConfigurationId(viewConfigurationId));

    return foundConfiguration;
  }
);

export const fetchCollectionSchema = createAsyncThunk(
  "collectionPageInfo/fetchCollectionSchema",
  async (
    {
      collectionId,
      currentViewConfigurationId,
    }: {
      collectionId: string;
      currentViewConfigurationId: string | null;
    },
    { getState }
  ) => {
    const response = await api.getSchema(collectionId);
    const rawData = response.fields;
    const { name, nameDB, viewConfigurations } = response;

    // Process rawData to fit the frontend structure
    const structuredData = transformDataForFrontend(rawData);

    return {
      structuredData,
      originalFields: rawData,
      name,
      collectionId: nameDB,
      viewConfigurations,
      currentViewConfigurationId:
        currentViewConfigurationId ?? viewConfigurations[0]?._id,
    };
  }
);

function transformDataForFrontend(rawData: any[]): Field[] {
  return rawData.map((field) => {
    const {
      _id,
      name,
      nameDB,
      typeField,
      isMulti,
      selectVars,
      refTo,
      settingField,
    } = field;

    return {
      _id: _id,
      fieldId: _id,
      name,
      nameDB,
      type: typeField,
      settingField,
      refTo,
      isMulti,
      options: selectVars,
    };
  });
}
