import { FC, useCallback, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { selectActivePanel } from "store/selectors/controlPanelSelectors";
import { COLLECTION_CONTROL_PANEL } from "constants/index";
import AnimatedWrapper from "components/AnimatedWrapper/AnimatedWrapper";
import TableControlHeader from "components/TableControlHeader/TableControlHeader";
import TableControlPanel from "pages/Collection/components/TableControlPanel/TableControlPanel";
import { setActivePanel } from "store/slices/controlPanelSlice";
import Button from "components/Button/Button";
import { BorderlessPlusIcon } from "icons";
import HorizontalDivider from "components/HorizontalDivider/HorizontalDivider";
import iconsByType from "utils/iconsByType";
import SortingVariant from "pages/Collection/components/Sorting/SortingVariant";
import { selectSortableFields } from "store/selectors/sortingSelectors";
import FieldTypePicker from "components/FieldTypePicker/FieldTypePicker";
import { getSortableFieldTypes } from "helpers/getSortableFieldTypes";
import {
  addSortingVariant,
  clearSortingVariants,
  resetVariantsEditModeToPreviousState,
} from "store/slices/sortingSlice";
import type { SortableField } from "typings/sorting";
import styles from "./SortingPanel.module.scss";

import { optionsByFieldType, alphabeticalOptions } from "constants/index";

const SortingPanel: FC = () => {
  const dispatch = useAppDispatch();

  const activePanel = useAppSelector(selectActivePanel);
  const isSortingPanelOpen = useMemo(
    () => activePanel === COLLECTION_CONTROL_PANEL.SORTING,
    [activePanel]
  );

  const closeFieldVisibilityBar = useCallback(() => {
    dispatch(setActivePanel(null));
  }, [dispatch]);

  const [currentStep, setCurrentStep] = useState(1);

  const sortingVariants = useAppSelector(selectSortableFields);
  const isEmptyState = sortingVariants.length === 0 && currentStep === 1;

  const fields = useAppSelector((state) =>
    getSortableFieldTypes(state.collection.pageInfo.fields)
  );

  const handleFieldClick = useCallback(
    (field: SortableField) => {
      const key = field.type as keyof typeof optionsByFieldType;

      const fieldId = fields.find((f) => f.name === field.name)?._id || "";

      const initialField: SortableField = {
        id: uuidv4(),
        fieldId: fieldId,
        name: field.name,
        nameDB: field.nameDB,
        type: field.type,
        value: optionsByFieldType[key][0].value,
        isInEditMode: true,
        isSaved: false,
        options: optionsByFieldType[key] || alphabeticalOptions,
      };

      dispatch(addSortingVariant(initialField));

      setCurrentStep(1);
    },
    [dispatch, fields]
  );

  const handleOnClear = useCallback(() => {
    dispatch(clearSortingVariants());
  }, [dispatch]);

  return (
    <AnimatedWrapper
      isOpen={isSortingPanelOpen}
      onClose={closeFieldVisibilityBar}
    >
      <TableControlPanel
        headerComponent={
          <TableControlHeader
            title="🍡 Sort"
            withClearButton
            onClear={handleOnClear}
            showCancelButton={currentStep === 2}
            onCancel={() => setCurrentStep(1)}
            onClose={closeFieldVisibilityBar}
          />
        }
        emptyStateComponent={
          <span className={styles["empty-state"]}>
            No active sort right now. Add first
          </span>
        }
        isEmpty={isEmptyState}
        dividerMargins="15px 0 15px"
      >
        {currentStep === 1 && (
          <>
            {sortingVariants.map((sortingVariant) => {
              return (
                <SortingVariant
                  key={sortingVariant.id}
                  icon={iconsByType[sortingVariant.type]}
                  variant={sortingVariant}
                />
              );
            })}
            <HorizontalDivider
              style={{
                margin: isEmptyState ? "15px 0" : "10px 0",
              }}
            />
            <Button
              icon={<BorderlessPlusIcon />}
              squaredIcon
              onClick={() => {
                dispatch(resetVariantsEditModeToPreviousState());
                setCurrentStep(2);
              }}
            >
              Add sort
            </Button>
          </>
        )}
        {currentStep === 2 && (
          <FieldTypePicker
            onFieldClick={handleFieldClick}
            icons={iconsByType}
            fields={fields}
          />
        )}
      </TableControlPanel>
    </AnimatedWrapper>
  );
};

export default SortingPanel;
