/* eslint-disable react/prop-types */

import { Dispatch, SetStateAction, useCallback, useRef, useState } from "react";
import type ICellRenderStrategy from "interfaces/ICellRenderStrategy";
import { CopyIcon, GripIcon, TrashIcon } from "icons";
import RowContextMenu from "components/RowContextMenu/RowContextMenu";
import Button from "components/Button/Button";
import api from "func/api";
import useRequiredParam from "hooks/useRequiredParam";
import { PARAM_COLLECTION_ID } from "constants/index";

interface GripButtonCellRenderStrategyProps {
  data: null;
  props: {
    setIsRowActive: Dispatch<SetStateAction<boolean>>;
    row: {
      original: {
        _id: string;
      };
    };
    handleDeleteRow: (id: string) => void;
    styles: {
      [key: string]: string;
    };
  };
}

const GripButtonCellRenderStrategy: React.FC<
  GripButtonCellRenderStrategyProps
> = ({ props }) => {
  const [isContextMenuActive, setIsContextMenuActive] = useState(false);
  const [clickPosition, setClickPosition] = useState({ x: 0, y: 0 });

  const ref = useRef<HTMLDivElement>(null);

  const handleGripClick = (event: React.MouseEvent) => {
    event.stopPropagation();

    props.setIsRowActive((state: boolean) => !state);
    setIsContextMenuActive((state: boolean) => !state);

    // Get click position
    const clickPosition = { x: event.clientX, y: event.clientY };
    setClickPosition(clickPosition);
  };

  const closeContextMenu = useCallback(() => {
    props.setIsRowActive(false);
    setIsContextMenuActive(false);
  }, [props]);

  const collectionId = useRequiredParam(PARAM_COLLECTION_ID);

  const handleDelete = () => {
    api
      .deleteDatas({
        typeModel: "collections",
        model: collectionId,
        data: props.row.original,
      })
      .then(() => {
        props.handleDeleteRow(props.row.original._id);
      })
      .catch((err) => {
        console.error(`err`, err);
      });
  };

  return (
    <>
      <div
        ref={ref}
        className={`${props.styles["flex-cell"]} ${props.styles["content-column"]} ${props.styles["grip-column"]}`}
        onClick={handleGripClick}
      >
        <GripIcon />
      </div>
      {isContextMenuActive && (
        <RowContextMenu
          position={clickPosition}
          onClickOutside={closeContextMenu}
        >
          <Button
            icon={<CopyIcon />}
            disabled
          >
            Duplicate
          </Button>
          <Button
            icon={<TrashIcon />}
            onClick={(event) => {
              event.stopPropagation();
              handleDelete();
            }}
          >
            Delete
          </Button>
        </RowContextMenu>
      )}
    </>
  );
};

export function createGripButtonCellRenderStrategy(): ICellRenderStrategy<any> {
  return {
    renderHeaderCell() {
      return null;
    },
    renderCell(
      data: null,
      props: GripButtonCellRenderStrategyProps["props"]
    ): JSX.Element | null {
      return (
        <GripButtonCellRenderStrategy
          data={data}
          props={props}
        />
      );
    },
  };
}
