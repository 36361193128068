import { FC } from "react";

const GroupIcon: FC = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.82807 4.45605H2.19007C2.05115 4.45605 1.91376 4.48498 1.78665 4.54101C1.65954 4.59705 1.54549 4.67895 1.45179 4.7815C1.35808 4.88405 1.28677 5.00499 1.2424 5.13663C1.19803 5.26827 1.18157 5.4077 1.19407 5.54605L1.83107 12.5461C1.85352 12.7945 1.96803 13.0256 2.15213 13.194C2.33622 13.3623 2.57659 13.4558 2.82607 13.4561H9.00007V14.4561H2.82607C2.32711 14.4559 1.84624 14.2692 1.47787 13.9326C1.10949 13.5961 0.880224 13.134 0.835068 12.6371L0.198068 5.63705C0.155454 5.17384 0.276462 4.71033 0.540068 4.32705L0.500068 3.45605C0.500068 2.92562 0.710782 2.41691 1.08585 2.04184C1.46093 1.66677 1.96964 1.45605 2.50007 1.45605H6.17207C6.70246 1.45617 7.21108 1.66696 7.58607 2.04205L8.41407 2.87005C8.78905 3.24515 9.29768 3.45594 9.82807 3.45605H13.8101C14.088 3.45603 14.3629 3.51392 14.6172 3.62605C14.8715 3.73817 15.0996 3.90207 15.287 4.10728C15.4745 4.31248 15.6171 4.5545 15.7057 4.8179C15.7944 5.08129 15.8272 5.36028 15.8021 5.63705L15.5461 8.45605H14.5401L14.8051 5.54605C14.8176 5.40779 14.8011 5.26844 14.7568 5.13687C14.7125 5.0053 14.6413 4.8844 14.5477 4.78187C14.4541 4.67933 14.3402 4.59741 14.2132 4.54132C14.0862 4.48522 13.9489 4.45619 13.8101 4.45605H9.82807ZM6.87807 2.74905L7.58707 3.45605H2.19007C1.95007 3.45605 1.72007 3.49805 1.50607 3.57605L1.50007 3.43605C1.5053 3.17432 1.61297 2.92507 1.79994 2.74184C1.98691 2.55861 2.23828 2.456 2.50007 2.45605H6.17207C6.43726 2.45611 6.69158 2.56151 6.87907 2.74905H6.87807Z"
        fill="inherit"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5 10.4561C13.6326 10.4561 13.7598 10.5087 13.8536 10.6025C13.9473 10.6963 14 10.8234 14 10.9561V12.4561H15.5C15.6326 12.4561 15.7598 12.5087 15.8536 12.6025C15.9473 12.6963 16 12.8234 16 12.9561C16 13.0887 15.9473 13.2158 15.8536 13.3096C15.7598 13.4034 15.6326 13.4561 15.5 13.4561H14V14.9561C14 15.0887 13.9473 15.2158 13.8536 15.3096C13.7598 15.4034 13.6326 15.4561 13.5 15.4561C13.3674 15.4561 13.2402 15.4034 13.1464 15.3096C13.0527 15.2158 13 15.0887 13 14.9561V13.4561H11.5C11.3674 13.4561 11.2402 13.4034 11.1464 13.3096C11.0527 13.2158 11 13.0887 11 12.9561C11 12.8234 11.0527 12.6963 11.1464 12.6025C11.2402 12.5087 11.3674 12.4561 11.5 12.4561H13V10.9561C13 10.8234 13.0527 10.6963 13.1464 10.6025C13.2402 10.5087 13.3674 10.4561 13.5 10.4561Z"
        fill="inherit"
      />
    </svg>
  );
};

export default GroupIcon;
