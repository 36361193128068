/* eslint-disable react/prop-types */
const ExcelProgressExport = ({ statusExportExcel, setExportPopupExcel }) => {
  return (
    <div
      className="modal fade show"
      tabIndex="-1"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Прогресс</h5>
          </div>
          <div className="modal-body">
            {statusExportExcel.status === "start" && (
              <p>
                Отправка данных <br />{" "}
              </p>
            )}
            {statusExportExcel.status === "finish" && (
              <p>
                Экспорт завершен <br />{" "}
              </p>
            )}
            {statusExportExcel.status === "exportInfo" &&
              !statusExportExcel.startCreateExcel && (
                <p>
                  Документов обработано {statusExportExcel.index} (кратно 100){" "}
                  <br />{" "}
                </p>
              )}
            {statusExportExcel.status === "exportInfo" &&
              statusExportExcel.startCreateExcel && (
                <p>
                  Подготовка файла <br />{" "}
                </p>
              )}
          </div>
          {
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={() => {
                  setExportPopupExcel(false);
                }}
              >
                Close
              </button>
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export default ExcelProgressExport;
