/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import { slugify } from "transliteration";

const Automat = ({ data, dataObjInside, bodyInsaid, dispatch }) => {
  useEffect(
    () => {
      const FIELD_1 = parseInt(
        dataObjInside[bodyInsaid.data.nameDBFields?.[0]],
        10
      );
      const FIELD_2 = parseInt(
        dataObjInside[bodyInsaid.data.nameDBFields?.[1]],
        10
      );

      switch (bodyInsaid.settingField) {
        case "slugifator":
          if (dataObjInside[bodyInsaid.data.nameDBField] === null) {
            dataObjInside[bodyInsaid.nameDB] = "";
            break;
          }
          dataObjInside[bodyInsaid.nameDB] = slugify(
            dataObjInside[bodyInsaid.data.nameDBField],
            {
              replace: [["_", "-"]],
              separator: "-",
            }
          );
          break;
        case "=":
          dataObjInside[bodyInsaid.nameDB] = FIELD_1 === FIELD_2;
          break;
        case "<":
          dataObjInside[bodyInsaid.nameDB] = FIELD_1 < FIELD_2;
          break;
        case ">":
          dataObjInside[bodyInsaid.nameDB] = FIELD_1 > FIELD_2;
          break;
        case "+":
          dataObjInside[bodyInsaid.nameDB] = FIELD_1 + FIELD_2;
          break;
        case "*":
          dataObjInside[bodyInsaid.nameDB] = FIELD_1 * FIELD_2;
          break;

        default:
          break;
      }

      dispatch({ data });
    },
    // eslint-disable-next-line
    bodyInsaid.data.nameDBFields
      ? [
          dataObjInside[bodyInsaid.data.nameDBFields[0]],
          dataObjInside[bodyInsaid.data.nameDBFields[1]],
        ]
      : [dataObjInside[bodyInsaid.data.nameDBField]]
  );

  return (
    <>
      <h6 className="flexim-field-name">{bodyInsaid.name}</h6>
      <div className="input-group">
        <input
          className="flexim-input"
          type="text"
          value={`${dataObjInside[bodyInsaid.nameDB]}`}
          readOnly
          disabled
        />
      </div>
    </>
  );
};

export default Automat;
