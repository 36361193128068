import React, { useEffect, useState } from "react";
import api from "func/api";
import { errorCatch } from "func/common";
import $ from "jquery";
import { HddOutlineIcon } from "icons";
import RoundButton from "components/RoundButton/RoundButton";
import styles from "./SettingsMedia.module.scss";

const defaultState = {
  compress: true,
  compressValue: 80,
  adaptive: true,
  adaptiveValue: {
    large: 1000,
    medium: 750,
    small: 500,
    thumbnail: 236,
  },
};

const SettingsMedia = ({}) => {
  const [settings, setSettings] = useState(defaultState);

  useEffect(() => {
    api
      .getSettingsSelect("media")
      .then((result) => {
        if (result.settings.media) {
          setSettings(result.settings.media);
        }
      })
      .catch((err) => {
        errorCatch(err);
      });
  }, []);

  const editAdaptive =
    settings.adaptive &&
    Object.keys(defaultState.adaptiveValue).some((type) => {
      return defaultState.adaptiveValue[type] !== settings.adaptiveValue[type];
    });

  const editCompress = settings.compressValue !== 80;

  return (
    <main className={`main ${styles.body}`}>
      <div className={styles["container-fluid"]}>
        <div className={styles["row"]}>
          <div className={styles["page-header"]}>
            <h4 className={`m-0 ${styles.h4}`}>Media settings</h4>
          </div>
        </div>
        <div className={styles["row"]}>
          <div className={styles["col-8"]}>
            <div className={`card p-3 mb-4 ${styles.customcard}`}>
              <div className={styles["row"]}>
                <div className={styles["column"]}>
                  <h6 className={styles["h6"]}>Image compression</h6>
                  <div className="btn-group">
                    <button
                      type="button"
                      className={`btn ${
                        !settings.compress && !editCompress
                          ? styles.primary
                          : styles.light
                      } ${styles.customswitch}`}
                      onClick={() => {
                        if (settings.compress) {
                          settings.compress = false;
                          setSettings({ ...settings });
                        }
                      }}
                    >
                      OFF
                    </button>
                    <button
                      type="button"
                      className={`btn ${
                        settings.compress && !editCompress
                          ? styles.primary
                          : styles.light
                      } ${styles.customswitch}`}
                      onClick={() => {
                        if (
                          !settings.compress ||
                          settings.compressValue !== defaultState.compressValue
                        ) {
                          settings.compressValue = defaultState.compressValue;
                          settings.compress = true;
                          setSettings({ ...settings });
                        }
                      }}
                    >
                      ON
                    </button>
                    <button
                      type="button"
                      className={`btn ${
                        editCompress ? styles.primary : styles.light
                      } ${styles.customswitch}`}
                      data-bs-toggle="collapse"
                      data-bs-target="#optimSettings"
                      aria-expanded="false"
                      aria-controls="optimSettings"
                    >
                      <i className="bi bi-gear" />
                    </button>
                  </div>
                  <p className={styles["text-secondary"]}>
                    Fast loading but reduced quality
                  </p>
                  <div
                    className={`collapse ${styles.collapsenew}`}
                    id="optimSettings"
                  >
                    <div className={`input-group mb-2 ${styles.inputandname}`}>
                      <span className={styles["input-name"]}>
                        Image quality,%
                      </span>
                      <input
                        type="number"
                        className={`form-control ${styles.inputcompress}`}
                        placeholder="80%"
                        value={settings.compressValue}
                        max="100"
                        min="50"
                        onChange={(e) => {
                          settings.compress = true;
                          settings.compressValue = +e.target.value;
                          setSettings({ ...settings });
                        }}
                      />
                    </div>
                    <p className={styles["text-secondary"]}>
                      lower percentage, lower quality, but also faster load and
                      less weigh{" "}
                    </p>
                  </div>
                </div>
                <div className={styles["column"]}>
                  <h6 className={styles["h6"]}>Adaptive loading</h6>
                  <div className="btn-group">
                    <button
                      type="button"
                      className={`btn ${
                        !settings.adaptive && !editAdaptive
                          ? styles.primary
                          : styles.light
                      } ${styles.customswitch}`}
                      onClick={() => {
                        if (settings.adaptive) {
                          settings.adaptive = false;
                          setSettings({ ...settings });
                        }
                      }}
                    >
                      OFF
                    </button>
                    <button
                      type="button"
                      className={`btn ${
                        settings.adaptive && !editAdaptive
                          ? styles.primary
                          : styles.light
                      } ${styles.customswitch}`}
                      onClick={() => {
                        settings.adaptiveValue = defaultState.adaptiveValue;
                        settings.adaptive = true;
                        setSettings({ ...settings });
                      }}
                    >
                      ON
                    </button>
                    <button
                      type="button"
                      className={`btn ${
                        editAdaptive ? styles.primary : styles.light
                      } ${styles.customswitch}`}
                      data-bs-toggle="collapse"
                      data-bs-target="#adaptSettings"
                      aria-expanded="false"
                      aria-controls="adaptSettings"
                    >
                      <i className="bi bi-gear" />
                    </button>
                  </div>
                  <p className={styles["text-secondary"]}>
                    Different variants of image (large, medium, small)
                  </p>
                  <div
                    className={`collapse ${styles.collapsenew}`}
                    id="adaptSettings"
                  >
                    <div className={styles["container-horizontal"]}>
                      <div
                        className={`input-group mb-2 ${styles.inputandname}`}
                      >
                        <span className={styles["input-name"]}>Large</span>
                        <input
                          type="number"
                          className={`form-control ${styles.inputadapt}`}
                          placeholder="800 px"
                          value={settings.adaptiveValue?.large}
                          onChange={(e) => {
                            settings.adaptive = true;
                            settings.adaptiveValue.large = +e.target.value;
                            setSettings({ ...settings });
                          }}
                        />
                      </div>
                      <div
                        className={`input-group mb-2 ${styles.inputandname}`}
                      >
                        <span className={styles["input-name"]}>Medium</span>
                        <input
                          type="text"
                          className={`form-control ${styles.inputadapt}`}
                          placeholder="600 px"
                          value={settings.adaptiveValue?.medium}
                          onChange={(e) => {
                            settings.adaptive = true;
                            settings.adaptiveValue.medium = +e.target.value;
                            setSettings({ ...settings });
                          }}
                        />
                      </div>
                      <div
                        className={`input-group mb-2 ${styles.inputandname}`}
                      >
                        <span className={styles["input-name"]}>Small</span>
                        <input
                          type="text"
                          className={`form-control ${styles.inputadapt}`}
                          placeholder="300 px"
                          value={settings.adaptiveValue?.small}
                          onChange={(e) => {
                            settings.adaptive = true;
                            settings.adaptiveValue.small = +e.target.value;
                            setSettings({ ...settings });
                          }}
                        />
                      </div>
                      <div
                        className={`input-group mb-2 ${styles.inputandname}`}
                      >
                        <span className={styles["input-name"]}>Miniature</span>
                        <input
                          type="text"
                          className={`form-control ${styles.inputadapt}`}
                          placeholder="150 px"
                          value={settings.adaptiveValue?.thumbnail}
                          onChange={(e) => {
                            settings.adaptive = true;
                            settings.adaptiveValue.thumbnail = +e.target.value;
                            setSettings({ ...settings });
                          }}
                        />
                      </div>
                    </div>
                    <p className={styles["text-secondary"]}>
                      Specify the width of generated images in pixels
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles["button-box"]}>
              <RoundButton
                before={<HddOutlineIcon />}
                appearance={"inverted"}
                onClick={(e) => {
                  api
                    .postSettings({ media: settings })
                    .then((data) => {
                      $(e.target).addClass("bg-success");
                      setTimeout(() => {
                        $(e.target).removeClass("bg-success");
                      }, 2500);
                    })
                    .catch((err) => {
                      $(e.target).addClass("bg-danger");
                      setTimeout(() => {
                        $(e.target).removeClass("bg-danger");
                      }, 2500);
                      console.error(`err`, err);
                    });
                }}
              >
                Save
              </RoundButton>
          </div>
        </div>
      </div>
    </main>
  );
};

export default SettingsMedia;
