import React, { useState } from "react";
import PropTypes from "prop-types";
import $ from "jquery";

import { uploadImage } from "helpers/uploadImage";
import RoundButton from "components/RoundButton/RoundButton";

const EmptyMedia = (props) => {
  const { getData, setActivePic } = props;
  const [progress, setProgress] = useState(0);
  return (
    <div className="flexim-media-wrapper">
      <div className="flexim-upload-image-wrapper">
        <i className="bi bi-camera-fill bg-camera fs-60" />
        <RoundButton
          onClick={(e) => {
            $(e.target).find('input[type="file"]').click();
          }}
        >
          Upload image
          <input
            type="file"
            multiple
            name="uploadImg"
            accept="image/jpeg,image/png,image/svg+xml"
            onInput={(e) => {
              uploadImage({
                event: e,
                setProgress,
                loadAllImagesCallback: getData,
                setThumbnail: setActivePic,
              });
            }}
          />
        </RoundButton>
      </div>
      <div
        className="progress"
        style={{
          position: "absolute",
          bottom: "4px",
          width: "410px",
          left: "50%",
          transform: "translateX(-50%)",
        }}
      >
        <div
          className="progress-bar"
          role="progressbar"
          aria-valuenow={progress}
          aria-valuemin="0"
          aria-valuemax="100"
          style={{ width: `${progress}%` }}
        ></div>
      </div>
    </div>
  );
};

EmptyMedia.propTypes = {
  isCollection: PropTypes.bool,
  getData: PropTypes.func,
  openChooseMedia: PropTypes.func,
  setActivePic: PropTypes.func,
};

export default EmptyMedia;
