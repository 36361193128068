import {
  Dispatch,
  FC,
  SetStateAction,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

import styles from "./InputWithControls.module.scss";
import Input from "components/Input/Input";
import { CheckSquareIcon, XSquareIcon } from "icons/index";

interface InputWithControlsProps {
  onCancel: () => void;
  onSubmit: (value: string) => void;
  style?: React.CSSProperties;
  shouldFocus?: boolean;
  clearInput?: (setInputValue: Dispatch<SetStateAction<string>>) => void;
  initialValue?: string;
}

interface InputWithControlsRef {
  resetInput: () => void;
}

const InputWithControls = forwardRef<
  InputWithControlsRef,
  InputWithControlsProps
>(
  (
    { onCancel, onSubmit, style, shouldFocus = false, initialValue = "" },
    ref
  ) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [value, setValue] = useState(initialValue);

    useEffect(() => {
      if (shouldFocus) {
        inputRef.current?.focus();
        setValue(initialValue);
      }
    }, [shouldFocus, initialValue]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.value);
    };

    const resetInput = () => {
      setValue("");
    };

    useImperativeHandle(ref, () => ({
      resetInput,
    }));

    return (
      <div
        className={styles["wrapper"]}
        style={style}
      >
        <Input
          ref={inputRef}
          onChange={handleChange}
          value={value}
          placeholder="New view configuration"
          condensed
        />
        <div className={styles["controls"]}>
          <div
            className={styles["cancel-button"]}
            onClick={() => {
              onCancel();
              setValue("");
            }}
          >
            <XSquareIcon />
          </div>
          <div
            className={`${styles["submit-button"]}${
              !value ? ` ${styles["submit-button--disabled"]}` : ""
            }`}
            onClick={() => {
              onSubmit(value);
              setValue("");
            }}
          >
            <CheckSquareIcon />
          </div>
        </div>
      </div>
    );
  }
);

InputWithControls.displayName = "InputWithControls";

export default InputWithControls;
