import { FC, useState, ChangeEvent } from "react";

import styles from "./ToggleSwitch.module.scss";

interface ToggleSwitchProps<T extends string> {
  options: { id: T; label: string }[];
  selectedOption: T;
  onToggle: (selected: T) => void;
  variant?: "default" | "inverted" | "big";
  onGrayBackground?: boolean;
}

const ToggleSwitch = <T extends string>({
  options,
  selectedOption,
  onToggle,
  variant = "default",
  onGrayBackground,
}: ToggleSwitchProps<T>) => {
  const wrapperClassName = `${styles["toggle-switch"]} ${
    styles[`toggle-switch--${variant}`]
  }${
    onGrayBackground ? ` ${styles["toggle-switch--on-gray-background"]}` : ""
  }`;

  return (
    <div className={wrapperClassName}>
      {options.map((option) => (
        <label
          key={option.id}
          className={`${styles["label"]} ${
            selectedOption === option.id ? `${styles["label--active"]}` : ""
          }`}
        >
          <input
            type="radio"
            id={option.id}
            name="toggle-switch"
            value={option.id}
            checked={selectedOption === option.id}
            onChange={() => onToggle(option.id)}
            className={styles["input"]}
          />
          {option.label}
        </label>
      ))}
    </div>
  );
};

export default ToggleSwitch;
