import {
  FC,
  type SetStateAction,
  type Dispatch,
  useState,
  useEffect,
  useCallback,
} from "react";
import Input from "components/Input/Input";
import { SearchIcon, CloseIcon } from "icons";

import styles from "./Search.module.scss";

interface SearchProps {
  columnName: string;
  onSearch: Dispatch<SetStateAction<string>>;
}

const Search: FC<SearchProps> = ({ columnName, onSearch }) => {
  const [value, setValue] = useState("");
  const [isSearchActive, setIsSearchActive] = useState(false);

  const handleSearchActivate = useCallback(() => {
    setIsSearchActive(true);
  }, [setIsSearchActive]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  useEffect(() => {
    onSearch(value);
  }, [onSearch, value]);

  const handleSearchCancel = () => {
    setValue("");
    setIsSearchActive(false);
  };

  return (
    <div className={styles["wrapper"]}>
      <div className={styles["search"]}>
        {isSearchActive ? (
          <>
            <Input
              value={value}
              onChange={handleChange}
              focus={true}
            />
            <div
              className={styles["icon-wrapper"]}
              onClick={handleSearchCancel}
            >
              <CloseIcon />
            </div>
          </>
        ) : (
          <>
            <span>{columnName}</span>
            <div
              className={styles["icon-wrapper"]}
              onClick={handleSearchActivate}
            >
              <SearchIcon />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Search;
