import { CardTextIcon } from "icons";
import type ICell from "interfaces/ICell";
import type ICellRenderStrategy from "interfaces/ICellRenderStrategy";

export class TextCellRenderStrategy implements ICellRenderStrategy<ICell> {
  renderHeaderCell(data: ICell) {
    return (
      <div
        data-tooltip-id="data-table-tooltip"
        data-tooltip-content={data.name}
        data-tooltip-place="left"
      >
        <CardTextIcon />
      </div>
    );
  }

  renderCell(data: ICell, props?: any) {
    return (
      <div
        className={`${props.className} ${props.styles["flex-cell"]} ${
          /** TODO: Bug with undefined value (Cannot read properies on undefined (Reading lengthText)). 
              The critical error occured on the Collection page possibly after changing the schema in Constructor (adding RichText field)
          **/
          props.value?.lengthText > 0
            ? props.styles["flex-cell--with-content"]
            : ""
        } ${props.styles["icon-column"]}`}
      >
        <CardTextIcon />
      </div>
    );
  }
}
