import { settingsFields } from "func/constants";

import PropTypes from "prop-types";

export default function FieldTypeSelectorModal({ setDataField }) {
  const varsList = Object.keys(settingsFields).map((el) => (
    <div
      className="type-fields__elem"
      key={el}
      onClick={(e) => {
        setDataField(settingsFields[el].def);
      }}
    >
      {settingsFields[el].badge}
      <div style={{ maxHeight: "34px" }}>
        <p>{settingsFields[el].name}</p>
        <span>{settingsFields[el].desc}</span>
      </div>
    </div>
  ));

  return (
    <div className="modal-body type-fields">
      <h6>Choose field type</h6>
      <div className="type-fields__cont">{varsList}</div>
    </div>
  );
}

FieldTypeSelectorModal.propTypes = {
  setDataField: PropTypes.func.isRequired,
};
