import React, { useEffect, useState } from "react";
import api from "../func/api";
import $ from "jquery";
import { errorCatch } from "../func/common";

const Profile = ({}) => {
  const [state, setState] = useState([]);
  useEffect(() => {
    api
      .getUserData()
      .then((result) => {
        setState(result);
      })
      .catch((err) => {
        errorCatch(err);
      });
  }, []);

  return (
    <main className="main">
      <div className="container-fluid">
        <div className="row">
          <div className="row">
            <div className="col-12 d-flex justify-content-between align-items-center mb-4 mt-4">
              <div>
                <h4 className="m-0">Мой профиль</h4>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-8">
              <div className="card p-3 mb-4">
                <div className="d-flex justify-content-between align-items-center mb-2">
                  <h5 className="m-0">Общие настройки</h5>
                  {/* <h6 className="m-0">Роль: {state.role.name}</h6> */}
                </div>
                <div className="row flex-wrap">
                  <div className="col-6">
                    <div className="input-group mb-2">
                      <span className="input-group-text text-secondary">
                        Имя
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        value={state.name}
                        onChange={(e) => {
                          state.name = e.target.value;
                          setState({ ...state });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="input-group mb-2">
                      <span className="input-group-text text-secondary">
                        Логин
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        value={state.username}
                        onChange={(e) => {
                          state.username = e.target.value;
                          setState({ ...state });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="input-group mb-2">
                      <span className="input-group-text text-secondary">
                        Email
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        value={state.email}
                        onChange={(e) => {
                          state.email = e.target.value;
                          setState({ ...state });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="input-group mb-2">
                      <span className="input-group-text text-secondary">
                        Телефон
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        value={state.phone}
                        onChange={(e) => {
                          state.phone = e.target.value;
                          setState({ ...state });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card p-3 mb-4">
                <div className="mb-2">
                  <h5 className="m-0">Пароль</h5>
                </div>
                <div className="row flex-wrap">
                  <div className="col-6">
                    <div className="input-group mb-2">
                      <span className="input-group-text text-secondary">
                        Новый пароль
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => {
                          state.newPassword = e.target.value;
                          setState({ ...state });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="input-group mb-2">
                      <span className="input-group-text text-secondary">
                        Старый пароль
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => {
                          state.oldPassword = e.target.value;
                          setState({ ...state });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="card p-3 mb-4">
                <h6 className="mb-2">Доступы в админ. панели</h6>
                <table className="table table-striped border rounded mb-0">
                  <thead>
                    <tr>
                      <th>Раздел</th>
                      <th>Страницы</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Страницы</td>
                      <td>Страница1, Страница2, Страница3</td>
                    </tr>
                    <tr>
                      <td>Коллекции</td>
                      <td>
                       
                      </td>
                    </tr>
                    <tr>
                      <td>Конструктор</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>Настройки сайта</td>
                      <td>Медиа</td>
                    </tr>
                  </tbody>
                </table>
              </div> */}
            </div>
            <div className="col-4">
              {/* <div className="card p-3 mb-4">
                <h6 className="mb-2">Фото профиля</h6>
                <div className="">
                  <div className="d-flex flex-column align-items-end card-profile p-3 bg-secondary rounded">
                    <div className="icon-badge bg-white rounded text-center mb-2 pointer">
                      <i className="bi bi-trash-fill text-danger" />
                    </div>
                    <div className="icon-badge bg-white rounded text-center pointer">
                      <i className="bi bi-pencil-fill" />
                    </div>
                  </div>
                </div>
              </div> */}
              <div className=" card p-3">
                <button
                  className="btn btn-primary mb-2"
                  onClick={(e) => {
                    api
                      .postProfile(state)
                      .then((data) => {
                        if (!data) throw new Error("Save error");

                        $(e.target).addClass("bg-success");
                        setTimeout(() => {
                          $(e.target).removeClass("bg-success");
                        }, 2500);
                        setState(data);
                      })
                      .catch((err) => {
                        $(e.target).addClass("bg-danger");
                        setTimeout(() => {
                          $(e.target).removeClass("bg-danger");
                        }, 2500);
                        console.log(`err`, err);
                      });
                  }}
                >
                  Сохранить
                </button>
                {/* <button className="btn btn-light mb-2">Отменить и выйти</button> */}
                <p className="text-secondary m-0 fs-14">
                  Правлено: 01.12.2020 12:10 (danil)
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Profile;
