import { combineReducers } from "@reduxjs/toolkit";
import controlPanelReducer from "../slices/controlPanelSlice";
import filtersReducer from "../slices/filterSlice";
import fieldVisibilityReducer from "../slices/fieldVisibilitySlice";
import pageInfoReducer from "../slices/collectionPageInfoSlice";
import sortingReducer from "../slices/sortingSlice";

const collectionReducer = combineReducers({
  pageInfo: pageInfoReducer,
  controlPanel: controlPanelReducer,
  filters: filtersReducer,
  sorting: sortingReducer,
  fieldVisibility: fieldVisibilityReducer,
});

export default collectionReducer;
