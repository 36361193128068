import { useCallback } from "react";

export const useReorder = <T>() => {
  const reorder = useCallback(
    (list: T[], startIndex: number, endIndex: number) => {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);

      return result;
    },
    []
  );

  return reorder;
};
