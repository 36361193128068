import { FC } from "react";

import EndpointInput from "components/Endpoints/EndpointInput";

interface EndpointsProps {
  path: string;
  id?: string;
}

const Endpoints: FC<EndpointsProps> = ({ path, id }) => {
  return (
    <div className="card p-3 endpoints">
      <h6 className="mb-3">Endpoints</h6>
      <EndpointInput
        method="GET"
        path={path}
        id={id}
      />
      <EndpointInput
        method="POST"
        path={path}
        id={id}
      />
      {id && (
        <EndpointInput
          method="DELETE"
          path={path}
          id={id}
        />
      )}
    </div>
  );
};

export default Endpoints;
