import api from "../../../func/api";

const textVars = [
  // { $eq: "Равно", $ne: "Не равно", $regex: "Содержит", $not: "Не содержит" },
  { label: "Равно", value: "$eq" },
  { label: "Не равно", value: "$ne" },
  { label: "Содержит", value: "$regex" },
  { label: "Не содержит", value: "$not" },
  { label: "Не заполнено", value: "$or" },
];
const selectVars = [
  // { $eq: "Содержит", $ne: "Не содержит" },
  { label: "Содержит", value: "$eq" },
  { label: "Не содержит", value: "$ne" },
];
const numVars = [
  { $eq: "Равно", $ne: "Не равно", $lt: "Меньше", $gt: "Больше" },
  { label: "Равно", value: "$eq" },
  { label: "Не равно", value: "$ne" },
  { label: "Меньше", value: "$lt" },
  { label: "Больше", value: "$gt" },
];
const dateVars = [
  // { $eq: "Равно", $ne: "Не равно", $lt: "Раньше", $gt: "Позже" },
  { label: "Равно", value: "$eq" },
  { label: "Не равно", value: "$ne" },
  { label: "Раньше", value: "$lt" },
  { label: "Позже", value: "$gt" },
];
const overVars = [
  // { $eq: "Равно", $ne: "Не равно", $lt: "Раньше", $gt: "Позже" },
  { label: "Существует", value: "$exists" },
  { label: "Не существует", value: "-$exists" },
];

const fieldsSetting = {
  input: { i: "", vars: textVars, typeInp: "input" },
  email: { i: "", vars: textVars, typeInp: "input" },
  text: { i: "", vars: textVars, typeInp: "input" },
  password: { i: "", vars: textVars, typeInp: "input" },
  number: { i: "", vars: numVars, typeInp: "number" },
  select: { i: "", vars: selectVars, typeInp: "select" },
  date: { i: "", vars: dateVars, typeInp: "date" },
  boolean: { i: "", typeInp: "boolean" },
  json: { i: "" },
  ref: { i: "", typeInp: "input" },
  arr: { i: "", typeInp: "input" },
  overVar: { i: "", typeInp: "number", vars: overVars },
};

const eqStyle = {
  container: (styles) => {
    return {
      width: "100%",
      ...styles,
    };
  },
};

const customStyles = {
  menu: (provided, state) => {
    return {
      ...provided,
      borderBottom: "1px dotted pink",
    };
  },

  singleValue: (provided, { data }) => {
    return {
      ...provided,

      ":before": {
        content: `'${fieldsSetting[data.type || data.typeField]?.i ?? ""}'`,
        display: "inline-block;",
        fontFamily: "bootstrap-icons!important;",
        fontStyle: "normal;",
        fontWeight: "400!important;",
        fontFeatureSettings: "normal;",
        fontVariant: "normal;",
        textTransform: "none;",
        lineHeight: 1,
        marginRight: "5px",
        verticalAlign: "-.125em;",
        WebkitFontSmoothing: "antialiased;",
        MozOsxFontSmoothing: "grayscale",
      },
    };
  },
  container: (styles) => {
    return {
      ...styles,
    };
  },
};

const parseFilterDataToQuery = (filter) => {
  if (filter.nameDB)
    if (filter.typeEq)
      if (filter.typeEq.includes("$exists")) {
        const isHave = filter.typeEq === "$exists";
        if (isHave)
          return {
            $and: [
              { [filter.nameDB]: { [filter.typeEq]: 1 } },
              {
                [filter.nameDB]: +filter.value
                  ? { $size: +filter.value }
                  : { $not: { $size: 0 } },
              },
            ],
          };
        else
          return {
            $or: [
              { [filter.nameDB]: { $exists: 0 } },
              {
                [filter.nameDB]: { $size: 0 },
              },
            ],
          };
      } else if (filter.typeEq.includes("$or")) {
        return {
          $or: [
            {
              [filter.nameDB]: {
                $exists: false,
              },
            },
            {
              [filter.nameDB]: "",
            },
            {
              [filter.nameDB]: null,
            },
          ],
        };
      } else {
        return {
          [filter.nameDB]: {
            [filter.typeEq]: filter.value,
          },
        };
      }
    else
      return {
        [filter.nameDB]: filter.value,
      };
};

const parseFilterToQuery = ({ filters, typeAnd, search }) => {
  let filtPost = [];

  filters.forEach((filter) => {
    if (filter.group) {
      filtPost.push({
        [filter.typeAnd ? "$and" : "$or"]: filter.elements.map(
          parseFilterDataToQuery
        ),
      });
    } else {
      filtPost.push(parseFilterDataToQuery(filter));
    }
  });
  if (filtPost.length) filtPost = { [typeAnd ? "$and" : "$or"]: filtPost };
  else filtPost = {};
  if (search && filters.length) {
    filtPost = { $and: [filtPost, { $text: { $search: search } }] };
  } else if (search) {
    filtPost = { $text: { $search: search } };
  }

  return filtPost;
};

const getFiltReq = ({
  filters,
  typeAnd,
  typeModel,
  nameColl,
  setData,
  pageNumber,
  search,
  limitInPage,
}) => {
  const filtPost = parseFilterToQuery({ filters, typeAnd, search });
  api
    .filterDatas({
      typeModel,
      model: nameColl,
      data: {
        filter: filtPost,
        pagin: { skip: +limitInPage * (pageNumber - 1), limit: +limitInPage },
      },
    })
    .then((filtData) => {
      setData(filtData);
    })
    .catch((err) => console.log(`err`, err));
};

export {
  textVars,
  selectVars,
  numVars,
  dateVars,
  overVars,
  fieldsSetting,
  getFiltReq,
  eqStyle,
  customStyles,
  parseFilterToQuery,
};
