/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { ReactSortable } from "react-sortablejs";
import Button from "components/Button/Button";
import { BorderlessPlusIcon, GripIcon } from "icons";
import BaseToggler from "components/BaseToggler/BaseToggler";

const Arr = ({ dataObjInside, data, field, dispatch }) => {
  const [arr, setArr] = useState(
    dataObjInside[field.nameDB]
      ? dataObjInside[field.nameDB].map((el) => ({ value: el }))
      : []
  );
  const [blockView, setBlockView] = useState(
    dataObjInside[field.nameDB]
      ? dataObjInside[field.nameDB]?.length < 10
      : true
  );

  const textView = arr.map((e) => e.value).join(field.separator);

  const elemsHTML = arr.map((elem, i) => {
    return (
      <div
        key={i}
        className="block-group d-flex"
      >
        <span className="block-group-left pointer elem-hand">
          <GripIcon />
        </span>
        <input
          className="flexim-input block-group-input"
          value={elem.value}
          onChange={(e) => {
            elem.value = e.target.value;
            setArr([...arr]);
            e.target.style.width =
              Math.max((e.target.value.length + 1) * 8 + 40, 80) + "px";

            dataObjInside[field.nameDB] = arr.map((e) => e.value);
          }}
        ></input>
        <span
          className="block-group-right pointer"
          onClick={() => {
            arr.splice(i, 1);
            setArr([...arr]);
            dataObjInside[field.nameDB] = arr.map((e) => e.value);
          }}
        >
          <i className="bi bi-x" />
        </span>
      </div>
    );
  });

  useEffect(
    () => {
      if (!dispatch || !data) return;
      dispatch({ data });
    },
    // we don't need to run this effect when dispatch or data changes
    // eslint-disable-next-line
    [arr]
  );

  return (
    <>
      <div className="flexim-field-wrapper flexim-field-wrapper--align-baseline">
        <h6
          className={`flexim-field-name${
            field.requiredField ? " required" : ""
          }`}
        >
          {field.name}
        </h6>
        <div style={!blockView ? { width: "100%" } : {}}>
          <div
            style={{
              marginBottom: "14px",
            }}
          >
            <BaseToggler
              textLabel="Show in block"
              onChange={(e) => {
                setBlockView(e.target.checked);
              }}
              checked={blockView}
            />
          </div>

          <div style={{ marginBottom: "10px" }}>
            {blockView ? (
              <>
                {elemsHTML?.length ? (
                  <ReactSortable
                    list={arr}
                    setList={(newState) => {
                      setArr(newState);
                      dataObjInside[field.nameDB] = newState.map(
                        (e) => e.value
                      );
                    }}
                    style={{ display: "flex", flexWrap: "wrap", gap: "8px" }}
                    handle=".elem-hand"
                  >
                    {elemsHTML}
                  </ReactSortable>
                ) : null}
              </>
            ) : (
              <textarea
                className="flexim-input"
                value={textView}
                onChange={(e) => {
                  setArr(
                    e.target.value
                      .split(field.separator)
                      .map((el) => ({ value: el }))
                  );

                  dataObjInside[field.nameDB] = e.target.value
                    .split(field.separator)
                    .map((el) => el);
                }}
              />
            )}
          </div>
          {blockView && (
            <Button
              icon={<BorderlessPlusIcon />}
              className="block-group-button"
              squaredIcon
              onClick={() => {
                arr.push({ value: "" });
                setArr([...arr]);
              }}
            >
              Add new
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default Arr;
