import { FC } from "react";

const LogOutIcon: FC = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="22"
        height="22"
        rx="6"
        fill="#EAEAEA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.75 14.9375C12.75 15.0535 12.7039 15.1648 12.6219 15.2469C12.5398 15.3289 12.4285 15.375 12.3125 15.375H5.3125C5.19647 15.375 5.08519 15.3289 5.00314 15.2469C4.92109 15.1648 4.875 15.0535 4.875 14.9375V7.0625C4.875 6.94647 4.92109 6.83519 5.00314 6.75314C5.08519 6.67109 5.19647 6.625 5.3125 6.625H12.3125C12.4285 6.625 12.5398 6.67109 12.6219 6.75314C12.7039 6.83519 12.75 6.94647 12.75 7.0625V8.8125C12.75 8.92853 12.7961 9.03981 12.8781 9.12186C12.9602 9.20391 13.0715 9.25 13.1875 9.25C13.3035 9.25 13.4148 9.20391 13.4969 9.12186C13.5789 9.03981 13.625 8.92853 13.625 8.8125V7.0625C13.625 6.7144 13.4867 6.38056 13.2406 6.13442C12.9944 5.88828 12.6606 5.75 12.3125 5.75H5.3125C4.9644 5.75 4.63056 5.88828 4.38442 6.13442C4.13828 6.38056 4 6.7144 4 7.0625V14.9375C4 15.2856 4.13828 15.6194 4.38442 15.8656C4.63056 16.1117 4.9644 16.25 5.3125 16.25H12.3125C12.6606 16.25 12.9944 16.1117 13.2406 15.8656C13.4867 15.6194 13.625 15.2856 13.625 14.9375V13.1875C13.625 13.0715 13.5789 12.9602 13.4969 12.8781C13.4148 12.7961 13.3035 12.75 13.1875 12.75C13.0715 12.75 12.9602 12.7961 12.8781 12.8781C12.7961 12.9602 12.75 13.0715 12.75 13.1875V14.9375Z"
        fill="#989898"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.8722 11.3093C17.913 11.2687 17.9453 11.2204 17.9674 11.1673C17.9894 11.1141 18.0008 11.0571 18.0008 10.9996C18.0008 10.942 17.9894 10.885 17.9674 10.8319C17.9453 10.7787 17.913 10.7305 17.8722 10.6898L15.2473 8.06483C15.1651 7.98268 15.0537 7.93652 14.9375 7.93652C14.8213 7.93652 14.7099 7.98268 14.6277 8.06483C14.5456 8.14698 14.4994 8.2584 14.4994 8.37458C14.4994 8.49076 14.5456 8.60218 14.6277 8.68433L16.5064 10.5621H8.8125C8.69647 10.5621 8.58519 10.6082 8.50314 10.6902C8.42109 10.7723 8.375 10.8835 8.375 10.9996C8.375 11.1156 8.42109 11.2269 8.50314 11.3089C8.58519 11.391 8.69647 11.4371 8.8125 11.4371H16.5064L14.6277 13.3148C14.5456 13.397 14.4994 13.5084 14.4994 13.6246C14.4994 13.7408 14.5456 13.8522 14.6277 13.9343C14.7099 14.0165 14.8213 14.0626 14.9375 14.0626C15.0537 14.0626 15.1651 14.0165 15.2473 13.9343L17.8722 11.3093Z"
        fill="#989898"
      />
    </svg>
  );
};

export default LogOutIcon;
