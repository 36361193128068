import { FC } from "react";

const ComponentsIcon: FC = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="22"
        height="22"
        rx="6"
        fill="#EAEAEA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.78983 11L4.23133 12.3641C4.16144 12.4015 4.103 12.4571 4.06226 12.525C4.02152 12.593 4 12.6708 4 12.75C4 12.8292 4.02152 12.907 4.06226 12.975C4.103 13.0429 4.16144 13.0985 4.23133 13.1359L10.7938 16.6359C10.8571 16.6696 10.9277 16.6872 10.9995 16.6872C11.0712 16.6872 11.1418 16.6696 11.2051 16.6359L17.7676 13.1359C17.8375 13.0985 17.8959 13.0429 17.9366 12.975C17.9774 12.907 17.9989 12.8292 17.9989 12.75C17.9989 12.6708 17.9774 12.593 17.9366 12.525C17.8959 12.4571 17.8375 12.4015 17.7676 12.3641L15.2117 11L14.2816 11.4961L16.6336 12.75L11.0003 15.7539L5.36708 12.75L7.71908 11.4961L6.78895 11H6.78983Z"
        fill="#989898"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.7938 5.36383C10.8571 5.33013 10.9277 5.3125 10.9995 5.3125C11.0712 5.3125 11.1418 5.33013 11.2051 5.36383L17.7676 8.86383C17.8375 8.90117 17.8959 8.9568 17.9366 9.02476C17.9774 9.09272 17.9989 9.17047 17.9989 9.24971C17.9989 9.32895 17.9774 9.4067 17.9366 9.47466C17.8959 9.54262 17.8375 9.59824 17.7676 9.63558L11.2051 13.1356C11.1418 13.1693 11.0712 13.1869 10.9995 13.1869C10.9277 13.1869 10.8571 13.1693 10.7938 13.1356L4.23133 9.63558C4.16144 9.59824 4.103 9.54262 4.06226 9.47466C4.02152 9.4067 4 9.32895 4 9.24971C4 9.17047 4.02152 9.09272 4.06226 9.02476C4.103 8.9568 4.16144 8.90117 4.23133 8.86383L10.7938 5.36383ZM5.36708 9.24971L10.9995 12.2536L16.6327 9.24971L10.9995 6.24583L5.3662 9.24971H5.36708Z"
        fill="#989898"
      />
    </svg>
  );
};

export default ComponentsIcon;
