import { FC } from "react";

const AutomatIcon: FC = () => {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 13.625C8.62445 13.625 10.1824 12.9797 11.331 11.831C12.4797 10.6824 13.125 9.12445 13.125 7.5C13.125 5.87555 12.4797 4.31763 11.331 3.16897C10.1824 2.02031 8.62445 1.375 7 1.375C5.37555 1.375 3.81763 2.02031 2.66897 3.16897C1.52031 4.31763 0.875 5.87555 0.875 7.5C0.875 9.12445 1.52031 10.6824 2.66897 11.831C3.81763 12.9797 5.37555 13.625 7 13.625ZM7 14.5C8.85652 14.5 10.637 13.7625 11.9497 12.4497C13.2625 11.137 14 9.35652 14 7.5C14 5.64348 13.2625 3.86301 11.9497 2.55025C10.637 1.2375 8.85652 0.5 7 0.5C5.14348 0.5 3.36301 1.2375 2.05025 2.55025C0.737498 3.86301 0 5.64348 0 7.5C0 9.35652 0.737498 11.137 2.05025 12.4497C3.36301 13.7625 5.14348 14.5 7 14.5Z"
        fill="inherit"
      />
    </svg>
  );
};

export default AutomatIcon;
