import { FC } from "react";

const FilterIcon: FC = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.6875 1.6875C1.6875 1.53832 1.74676 1.39524 1.85225 1.28975C1.95774 1.18426 2.10082 1.125 2.25 1.125H15.75C15.8992 1.125 16.0423 1.18426 16.1477 1.28975C16.2532 1.39524 16.3125 1.53832 16.3125 1.6875V3.9375C16.3125 4.07622 16.2612 4.21004 16.1685 4.31325L11.25 9.7785V15.1875C11.2499 15.3055 11.2127 15.4205 11.1437 15.5162C11.0746 15.6119 10.9772 15.6835 10.8652 15.7207L7.49025 16.8457C7.40573 16.8739 7.31574 16.8816 7.22768 16.8681C7.13961 16.8547 7.056 16.8205 6.98372 16.7685C6.91144 16.7164 6.85256 16.6479 6.81192 16.5686C6.77129 16.4894 6.75007 16.4016 6.75 16.3125V9.7785L1.8315 4.31325C1.73881 4.21004 1.68752 4.07622 1.6875 3.9375V1.6875ZM2.8125 2.25V3.7215L7.731 9.18675C7.82369 9.28996 7.87498 9.42378 7.875 9.5625V15.5317L10.125 14.7825V9.5625C10.125 9.42378 10.1763 9.28996 10.269 9.18675L15.1875 3.7215V2.25H2.8125Z"
        fill="inherit"
      />
    </svg>
  );
};

export default FilterIcon;
