import { FC, PropsWithChildren, useCallback, useMemo, useState } from "react";
import {
  isSelectFilter,
  isBooleanFilter,
  type CommonFilter,
  SelectFilter,
} from "typings/filter";
import {
  GripIcon,
  CheckSquareIcon,
  GroupIcon,
  CopyIcon,
  TrashIcon,
} from "icons";
import FilterVariantOptions from "./FilterVariantOptions";
import FilterInput from "./FilterInput";
import FilterSelect from "./FilterSelect";

import styles from "./FilterVariant.module.scss";
import { useAppDispatch } from "hooks/useAppDispatch";
import {
  deleteFilterById,
  duplicateFilterById,
  saveFilter,
  setActiveFilterById,
  setFilterAsGroupHost,
  setFilterOptionByFilterId,
  setFilterValue,
} from "store/slices/filterSlice";
import SelectOption from "components/SelectOption/SelectOption";
import FilterBoolean from "./FilterBoolean";
import FilterWrapper from "./FilterWrapper";
import capitalizeFirstLetter from "utils/capitalizeFirstLetter";
import FilterRef from "pages/Collection/components/Filters/FilterRef";
import RowContextMenu from "components/RowContextMenu/RowContextMenu";
import Button from "components/Button/Button";
import type { Option } from "typings/filter";

interface FilterVariantProps extends PropsWithChildren {
  filter: CommonFilter;
  icon: JSX.Element;
  smallPaddings?: boolean;
  onGrayBackground?: boolean;
}

const FilterVariant: FC<FilterVariantProps> = ({
  filter,
  icon,
  smallPaddings = false,
  onGrayBackground,
}) => {
  const dispatch = useAppDispatch();

  // Context Menu Logic START
  const [isContextMenuActive, setIsContextMenuActive] = useState(false);
  const [clickPosition, setClickPosition] = useState({ x: 0, y: 0 });

  const handleGripClick = (event: React.MouseEvent) => {
    setIsContextMenuActive((state: boolean) => !state);

    // Get click position
    const clickPosition = { x: event.clientX, y: event.clientY };
    setClickPosition(clickPosition);
  };

  const closeContextMenu = useCallback(() => {
    setIsContextMenuActive(false);
  }, [setIsContextMenuActive]);
  // Context Menu Logic END

  const isInEditMode = useMemo(
    () => filter.isInEditMode,
    [filter.isInEditMode]
  );
  const currentFilterOption = useMemo(
    () => filter.filterOption,
    [filter.filterOption]
  );

  const handleOptionChange = (filterOption: string) => {
    dispatch(setFilterOptionByFilterId({ filter, option: filterOption }));
  };

  const typeStrategy: { [key: string]: (props: any) => React.ReactElement } = {
    input: (props) => {
      return (
        <FilterWrapper filter={props.filter}>
          <FilterInput {...props} />
        </FilterWrapper>
      );
    },
    text: (props) => (
      <FilterWrapper filter={props.filter}>
        <FilterInput {...props} />
      </FilterWrapper>
    ),
    email: (props) => {
      return (
        <FilterWrapper filter={props.filter}>
          <FilterInput {...props} />
        </FilterWrapper>
      );
    },
    number: (props) => {
      return (
        <FilterWrapper filter={props.filter}>
          <FilterInput
            {...props}
            type="number"
          />
        </FilterWrapper>
      );
    },
    select: (props) => {
      return (
        <FilterWrapper filter={props.filter}>
          <FilterSelect
            value={props.value}
            filter={props.filter}
          />
        </FilterWrapper>
      );
    },
    boolean: (props) => {
      return <FilterBoolean {...props} />;
    },
    image: (props) => (
      <div className={styles["text"]}>
        <span className={styles["filter-name"]}>
          {props.filter.name}: {props.filter.filterOption}
        </span>
      </div>
    ),
    ref: (props) => {
      return <FilterRef {...props} />;
    },
    automat: (props) => {
      return props.filter?.additionalOptions?.settingField === "slugifator" ? (
        <FilterWrapper filter={props.filter}>
          <FilterInput {...props} />
        </FilterWrapper>
      ) : (
        <FilterBoolean {...props} />
      );
    },

    default: (props) => <div>Default</div>,
  };

  const inputHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(setFilterValue({ filter, value: event.target.value }));
    },
    [dispatch, filter]
  );

  const handleStrategy: { [key: string]: ({ param }: any) => void } = {
    input: inputHandler,
    text: inputHandler,
    email: inputHandler,
    number: inputHandler,
    boolean: () => {},
    automat: inputHandler,
  };

  const setFilterToEditMode = useCallback(() => {
    dispatch(setActiveFilterById({ id: filter.id }));
  }, [dispatch, filter.id]);

  const globalWrapperClassName = useMemo(
    () => (isInEditMode ? styles["global-wrapper"] : ""),
    [isInEditMode]
  );

  const editModeWrapperClassName = useMemo(
    () =>
      `${styles["wrapper"]} ${styles["wrapper--active"]} ${
        isSelectFilter(filter) &&
        filter.isMulti &&
        filter.value &&
        filter.value.length > 0
          ? styles["wrapper--select"]
          : ""
      }`,
    [filter]
  );

  const wrapperClassName = useMemo(
    () => (isInEditMode ? editModeWrapperClassName : `${styles["wrapper"]}`),
    [isInEditMode, editModeWrapperClassName]
  );

  // TODO: refactor this
  const renderFilterValue = (
    option: any,
    filterType: CommonFilter["type"],
    filterOption: string
  ) => {
    if (filterOption === "Is Empty" || filterOption === "Is Not Empty") {
      return null;
    }

    const isNumberFilterType = filterType === "number";

    if (isSelectFilter(filter)) {
      if (filter.isMulti && Array.isArray(option)) {
        return option?.map((value: any, index: number) => {
          return ` "${value.label}"${index === option.length - 1 ? "" : ","}`;
        });
      } else {
        return ` "${option.label}"`;
      }
    }

    if (isBooleanFilter(filter)) {
      return capitalizeFirstLetter(filter.value);
    }

    return isNumberFilterType ? ` ${option}` : ` "${option}"`;
  };

  const isBooleanFilterType = filter.type === "boolean";

  const isAutomatFilterType = filter.type === "automat";

  const isSlugifatorFilterType =
    filter.additionalOptions.settingField === "slugifator";

  const createGroup = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      dispatch(setFilterAsGroupHost({ id: filter.id }));
    },
    [dispatch, filter.id]
  );

  const handleDeleteVariant = useCallback(() => {
    dispatch(deleteFilterById({ id: filter.id }));
  }, [dispatch, filter.id]);

  const isTopLevelFilter = useMemo(() => {
    return filter.parentId === null;
  }, [filter.parentId]);

  const handleDuplicateVariant = useCallback(() => {
    dispatch(duplicateFilterById({ id: filter.id }));
    setIsContextMenuActive(false);
  }, [filter.id, dispatch]);

  const shouldRenderSelectedoptions = useMemo(() => {
    return (
      isInEditMode &&
      isSelectFilter(filter) &&
      filter.isMulti &&
      filter.value &&
      filter.value.length > 0 &&
      !filter.filterOption.includes("Empty")
    );
  }, [isInEditMode, filter]);

  const onSelectedOptionRemove = useCallback(
    (optionToRemove: Option) => {
      const newSelectedOptions =
        (filter as SelectFilter).value?.filter(
          (option: Option) => option.value !== optionToRemove.value
        ) || [];
      dispatch(
        setFilterValue({
          value: newSelectedOptions as any,
          filter,
        })
      );
    },
    [dispatch, filter]
  );

  const onFilterSave = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      dispatch(saveFilter({ filter: filter }));
    },
    [dispatch, filter]
  );

  return (
    <div className={globalWrapperClassName}>
      <div
        className={`${wrapperClassName}${
          smallPaddings ? ` ${styles["wrapper--small-paddings"]}` : ""
        }${
          onGrayBackground ? ` ${styles["wrapper--on-gray-background"]}` : ""
        }${
          isContextMenuActive
            ? ` ${styles["wrapper--context-menu-active"]}`
            : ``
        }`}
        onClick={setFilterToEditMode}
      >
        {shouldRenderSelectedoptions && (
          <div className={styles["option-list"]}>
            {(filter as SelectFilter).value?.map((value) => (
              <SelectOption
                key={value.value}
                onClose={() => onSelectedOptionRemove(value)}
                label={value.label}
              />
            ))}
          </div>
        )}
        <div className={styles["flex-area"]}>
          <div className={styles["content"]}>
            <div className={styles["icon"]}>{icon}</div>
            {isInEditMode ? (
              <>
                {isBooleanFilterType ||
                (isAutomatFilterType && !isSlugifatorFilterType) ? (
                  <div className={styles["text"]}>
                    <span className={styles["filter-name"]}>{filter.name}</span>
                  </div>
                ) : null}
                {(typeStrategy[filter.type] || typeStrategy.default)({
                  filter: filter,
                  placeholder: filter.name,
                  value: filter.value,
                  onChange: handleStrategy[filter.type],
                })}
              </>
            ) : (
              <div className={styles["text"]}>
                <span
                  className={`${styles["filter-name"]}${
                    !filter.isSaved ? ` ${styles["filter-name--grey"]}` : ""
                  }`}
                >
                  {filter.name}
                  {filter.isSaved &&
                    (filter.type !== "number" ||
                      filter.filterOption.includes("Empty")) &&
                    ":"}{" "}
                </span>
                {filter.isSaved && (
                  <span>
                    {currentFilterOption}
                    {renderFilterValue(
                      filter.value,
                      filter.type,
                      filter.filterOption
                    )}
                  </span>
                )}
              </div>
            )}
          </div>
          <div className={styles["controls"]}>
            {filter.isInEditMode ? (
              <div
                className={`${styles["button"]} ${styles["button--trash"]}`}
                onClick={(e) => {
                  e.stopPropagation();
                  handleDeleteVariant();
                }}
              >
                <TrashIcon />
              </div>
            ) : (
              <div
                className={`${styles["button"]} ${styles["button--grip"]}${
                  isContextMenuActive ? ` ${styles["button--active"]}` : ``
                }`}
                onClick={(e) => {
                  e.stopPropagation();
                  handleGripClick(e);
                }}
              >
                <GripIcon />
              </div>
            )}
            <button
              className={styles["button"]}
              onClick={onFilterSave}
              disabled={
                isInEditMode &&
                !filter.value &&
                !filter.filterOption?.includes("Empty")
              }
            >
              <CheckSquareIcon />
            </button>
          </div>
        </div>
        {isContextMenuActive && (
          <RowContextMenu
            position={clickPosition}
            onClickOutside={closeContextMenu}
          >
            {isTopLevelFilter && (
              <Button
                icon={<GroupIcon />}
                onClick={createGroup}
              >
                Group
              </Button>
            )}
            <Button
              icon={<CopyIcon />}
              onClick={(e) => {
                e.stopPropagation();
                handleDuplicateVariant();
              }}
            >
              Duplicate
            </Button>
            <Button
              icon={<TrashIcon />}
              onClick={handleDeleteVariant}
            >
              Delete
            </Button>
          </RowContextMenu>
        )}
      </div>
      {isInEditMode && (
        <FilterVariantOptions
          selectedOption={currentFilterOption}
          type={filter.type}
          isSlugifator={isSlugifatorFilterType}
          onOptionChange={handleOptionChange}
        />
      )}
    </div>
  );
};

export default FilterVariant;
