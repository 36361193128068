import { useState, useEffect, useReducer, useCallback } from "react";
import { useNavigate, useLocation } from "react-router";

import api from "func/api";
import constrBlocks from "../../comp/pageConstructor/funcs/constrBlocks";
import UploadMediaModal from "../../comp/common/UploadMediaModal";
// import Endpoints from "components/Endpoints/Endpoints";
import RoundButton from "components/RoundButton/RoundButton";
import { HddOutlineIcon, HourglassIcon } from "icons/index";

/**
 * Creates the initial state data based on the given fields.
 * Basically, it creates an object with the field names as keys and the default values based on the field type.
 * @param fields - The fields used to create the initial state data.
 * @returns The initial state data object.
 */
const createInitialStateData = (fields: any) => {
  return fields.reduce((acc: any, field: any) => {
    if (field.typeField === "component") {
      if (field.isMulti) acc[field.nameDB] = [];
      else acc[field.nameDB] = {};
    } else if (field.typeField === "boolean") {
      acc[field.nameDB] = false;
    } else if (field.typeField === "automat") {
      acc[field.nameDB] = "";
    } else {
      acc[field.nameDB] =
        field.isMulti || field.typeField === "arr" ? [] : null;
    }

    return acc;
  }, {});
};

const changeState = (state: any, action: any) => {
  return { ...action.data };
};

const titlePlaceholder = "Here will be the name";

interface EditorProps {
  typeModel: string;
  nameColl: string;
  id: string;
  userData: any;
  router: any;
  cms: string;
}

const chooseTypePage = {
  pages: "getSinglePage",
  collections: "getSchema",
};

const Editor = ({ typeModel, nameColl, id, userData, cms }: EditorProps) => {
  const [data, dispatch] = useReducer(changeState, {});
  const [schema, setSchema] = useState({} as any);
  const [activeFields, setActiveFields] = useState([]);
  const [dataReady, setDataReady] = useState(false);
  const [openMediaModal, setOpenMediaModal] = useState(false);
  const [activePic, setActivePic] = useState(null);
  const [updateMedia, setUpdateMedia] = useState(null);

  const [namesRefColl, setNamesRefColl] = useState([]);
  const [dataRefColl, setDataRefColl] = useState({});
  const [defaultsNames, setDefaultsNames] = useState({});
  const [saveButtonAppearance, setSaveButtonAppearance] = useState("inverted");
  const [isSaving, setIsSaving] = useState(false);
  const [blocksHTML, setBlocksHTML] = useState([]);

  const isNew = id === "new";

  const navigate = useNavigate();
  const location = useLocation();

  const funcOpenMedia = useCallback((activePic: any, funcChange: any) => {
    setOpenMediaModal(true);
    setActivePic(activePic);
    setUpdateMedia(() => funcChange);
  }, []);

  const funcCloseMedia = useCallback(() => {
    setOpenMediaModal(false);
    setActivePic(null);
    setUpdateMedia(null);
  }, []);

  const funcChooseFromMedia = useCallback((funcChange: any) => {
    setUpdateMedia(() => funcChange);
  }, []);

  useEffect(() => {
    const getDatasCollForRef = () => {
      try {
        namesRefColl.forEach(async (nameCollForFetch) => {
          if (!defaultsNames[nameCollForFetch]) {
            const result = await api.getSchema(nameCollForFetch);
            (defaultsNames as any)[nameCollForFetch] =
              result.defaultField || "name"; // TODO: Find the variant for setting/displaying the default field
            setDefaultsNames({
              ...defaultsNames,
            });
          }
        });
      } catch (err) {
        console.error(err);
      }
    };

    getDatasCollForRef();
  }, [namesRefColl, defaultsNames]);

  useEffect(() => {
    (api as any)
      [(chooseTypePage as any)[typeModel]](nameColl)
      .then((result: any) => {
        if (isNew) {
          const newData = createInitialStateData(result.fields);
          dispatch({ data: newData });
          setSchema(result);
          setDataReady(true);
        } else {
          api
            .getData({ typeModel, model: nameColl, id } as any)
            .then(({ data: responseData }) => {
              if (responseData) {
                // Other fields not presented in the response data (for example if new fields were added to the schema)
                const otherFields = result.fields.filter(
                  (field: any) =>
                    !Object.keys(responseData).includes(field.nameDB),
                );

                const otherFieldsAsKeys = createInitialStateData(otherFields);

                dispatch({
                  data: {
                    ...responseData,
                    ...otherFieldsAsKeys,
                  },
                });

                setSchema(result);
                setDataReady(true);
              } else {
                // For New page
                const newData = createInitialStateData(result.fields);

                dispatch({ data: newData });
                setSchema(result);
                setDataReady(true);
              }
            })
            .catch((err) => {
              console.error(err);
            });
        }
      })
      .catch((err: Error) => {
        console.error(err);
      });
  }, [nameColl, id, typeModel, isNew, dispatch, setDataReady, setSchema]);

  const changeSaveButtonAppearance = useCallback((apperance: string) => {
    const delay = 2500;
    setSaveButtonAppearance(apperance);
    const timer = setTimeout(() => {
      setSaveButtonAppearance("inverted");
      clearTimeout(timer);
    }, delay);
  }, []);

  const onSave = useCallback(async () => {
    if (isSaving) return;
    setIsSaving(true);

    const idFromPath = location.pathname.split("/").pop();

    const dataToSave = { ...data };
    if (!dataToSave._id && idFromPath !== "new") {
      dataToSave._id = idFromPath;
    }

    try {
      const nameCollToSave =
        typeModel === "pages" ? `sp_${nameColl}` : nameColl;

      // data._id will be undefined if it is a new page not presented in the database
      const request =
        (!data._id && idFromPath === "new") ||
        (!data._id && typeModel === "pages")
          ? api.postDatas(typeModel, nameCollToSave, data)
          : api.putDatas(typeModel, nameCollToSave, dataToSave);

      const response = await request;

      if (isNew && typeModel === "collections" && response.status !== "error") {
        const path = `${location.pathname.replace(
          "new",
          `${response.data._id}`,
        )}`;
        navigate(path);
      } else {
        if (response.status !== "error") {
          dispatch({ data: response.data });
        }
      }
      changeSaveButtonAppearance(
        response.status === "success" ? "positive" : "negative-high-contrast",
      );
    } catch (err) {
      changeSaveButtonAppearance("negative-high-contrast");
      console.error(err);
    } finally {
      setIsSaving(false);
    }
  }, [
    data,
    typeModel,
    nameColl,
    isNew,
    location.pathname,
    navigate,
    changeSaveButtonAppearance,
    isSaving,
  ]);

  useEffect(() => {
    if (schema.fields) {
      setActiveFields(
        schema.fields.reduce((activeArr: any[], el: any) => {
          if (el.visible) {
            activeArr.push(el);
            return activeArr;
          }
          return activeArr;
        }, []),
      );
    }
  }, [schema.fields]);

  useEffect(() => {
    if (activeFields.length && dataReady && schema.cols) {
      const constrBlocksDataHtml = constrBlocks({
        body: activeFields,
        cols: schema.cols,
        data,
        dispatch,
        namesRefColl,
        setNamesRefColl,
        dataRefColl,
        setDataRefColl,
        funcOpenMedia,
        funcChooseFromMedia,
        defaultsNames,
        onSave,
      } as any);
      setBlocksHTML(constrBlocksDataHtml[0] as any);
    }
  }, [
    activeFields,
    dataReady,
    schema.cols,
    data,
    dispatch,
    namesRefColl,
    setNamesRefColl,
    dataRefColl,
    setDataRefColl,
    funcOpenMedia,
    funcChooseFromMedia,
    defaultsNames,
    onSave,
  ]);

  const title =
    typeModel === "pages" ? schema.name : data.name || titlePlaceholder;

  const shouldAddStylesForEmptyTitle = title === titlePlaceholder;

  return (
    <main className="main collection">
      <div className="">
        <div className="position-relative">
          <div className="">
            <div className="flexim-workspace">
              <div className="flexim-inner-workspace">
                <div
                  className="d-flex justify-content-between"
                  style={{ marginBottom: "60px" }}
                >
                  <h1
                    className={`flexim-editor-title${
                      shouldAddStylesForEmptyTitle
                        ? " flexim-editor-title--empty"
                        : ""
                    }`}
                  >
                    {title}
                  </h1>
                  <RoundButton
                    before={isSaving ? <HourglassIcon /> : <HddOutlineIcon />}
                    appearance={saveButtonAppearance as any}
                    onClick={onSave}
                  >
                    Save
                  </RoundButton>
                </div>

                <div className="mb-4">
                  <div className="card-body">{blocksHTML}</div>
                </div>

                {/* {id !== "new" && userData?.role?.endpoints && (
                  <Endpoints
                    path={`/data/${cms}/${typeModel}/${nameColl}`}
                    id={id}
                  />
                )} */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {openMediaModal && (
        <UploadMediaModal
          activePic={activePic}
          setActivePic={setActivePic}
          open={openMediaModal}
          close={funcCloseMedia}
          getData={updateMedia}
          editMediaDefault={true}
        />
      )}
    </main>
  );
};

export default Editor;
