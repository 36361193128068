import { FC, PropsWithChildren, useCallback, useMemo, useState } from "react";

import { GripIcon, CheckSquareIcon, TrashIcon } from "icons";

import { useAppDispatch } from "hooks/useAppDispatch";

import RowContextMenu from "components/RowContextMenu/RowContextMenu";
import Button from "components/Button/Button";
import FieldTypeOption from "components/FieldTypeOption/FieldTypeOption";
import {
  deleteVariant,
  resetVariantsEditModeToPreviousState,
  saveVariant,
  setVariantToEditMode,
  setVariantValue,
} from "store/slices/sortingSlice";

import type { Option } from "typings/filter";

import styles from "../Filters/FilterVariant.module.scss";

interface Editable {
  isInEditMode: boolean;
  isSaved: boolean;
}

interface CommonVariant extends Editable {
  id: string; // 1
  name: string; // Name
  type: string; // input
  value: string; // "asc"
  options: Option[];
}

interface SortingVariantProps<T extends CommonVariant>
  extends PropsWithChildren {
  variant: T;
  icon: JSX.Element;
}

const SortingVariant: FC<SortingVariantProps<CommonVariant>> = ({
  variant,
  icon,
}) => {
  const dispatch = useAppDispatch();

  // Context Menu Logic START
  const [isContextMenuActive, setIsContextMenuActive] = useState(false);
  const [clickPosition, setClickPosition] = useState({ x: 0, y: 0 });

  const handleGripClick = (event: React.MouseEvent) => {
    setIsContextMenuActive((state: boolean) => !state);

    // Get click position
    const clickPosition = { x: event.clientX, y: event.clientY };
    setClickPosition(clickPosition);
  };

  const closeContextMenu = useCallback(() => {
    setIsContextMenuActive(false);
  }, [setIsContextMenuActive]);
  // Context Menu Logic END

  const isInEditMode = useMemo(
    () => variant.isInEditMode,
    [variant.isInEditMode]
  );

  const handleOptionChange = (sortableOption: Option) => {
    dispatch(setVariantValue({ id: variant.id, value: sortableOption.value }));
  };

  const setVarianToEditMode = useCallback(() => {
    dispatch(resetVariantsEditModeToPreviousState());
    dispatch(setVariantToEditMode(variant.id));
  }, [dispatch, variant.id]);

  const globalWrapperClassName = useMemo(
    () => (isInEditMode ? styles["global-wrapper"] : ""),
    [isInEditMode]
  );

  const wrapperClassName = useMemo(
    () =>
      isInEditMode
        ? `${styles["wrapper"]} ${styles["wrapper--active"]}`
        : `${styles["wrapper"]}`,
    [isInEditMode]
  );

  const handleDeleteVariant = useCallback(() => {
    dispatch(deleteVariant(variant.id));
  }, [dispatch, variant.id]);

  const onVariantSave = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      dispatch(saveVariant(variant.id));
    },
    [dispatch, variant.id]
  );

  const chosenOption = useMemo(() => {
    const option = variant.options.find(
      (option) => option.value === variant.value
    );
    return option?.label || "";
  }, [variant.options, variant.value]);

  return (
    <div className={globalWrapperClassName}>
      <div
        className={`${wrapperClassName}${
          isContextMenuActive
            ? ` ${styles["wrapper--context-menu-active"]}`
            : ``
        }`}
        onClick={setVarianToEditMode}
      >
        <div className={styles["flex-area"]}>
          <div className={styles["content"]}>
            <div className={styles["icon"]}>{icon}</div>
            {isInEditMode ? (
              <div className={styles["text"]}>
                <span className={styles["filter-name"]}>
                  {variant.name}: {chosenOption}
                </span>
              </div>
            ) : (
              <div className={styles["text"]}>
                <span
                  className={`${styles["filter-name"]}${
                    !variant.isSaved ? ` ${styles["filter-name--grey"]}` : ""
                  }`}
                >
                  {variant.name}
                  {variant.isSaved && ": "}
                </span>
                {variant.isSaved && <span>{chosenOption}</span>}
              </div>
            )}
          </div>
          <div className={styles["controls"]}>
            {variant.isInEditMode ? (
              <div
                className={`${styles["button"]} ${styles["button--trash"]}`}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  handleDeleteVariant();
                }}
              >
                <TrashIcon />
              </div>
            ) : (
              <div
                className={`${styles["button"]} ${styles["button--grip"]}${
                  isContextMenuActive ? ` ${styles["button--active"]}` : ``
                }`}
                onClick={(e) => {
                  e.stopPropagation();
                  handleGripClick(e);
                }}
              >
                <GripIcon />
              </div>
            )}
            <button
              className={styles["button"]}
              onClick={onVariantSave}
              disabled={isInEditMode && !variant.value}
            >
              <CheckSquareIcon />
            </button>
          </div>
        </div>
        {isContextMenuActive && (
          <RowContextMenu
            position={clickPosition}
            onClickOutside={closeContextMenu}
          >
            <Button
              icon={<TrashIcon />}
              onClick={handleDeleteVariant}
            >
              Delete
            </Button>
          </RowContextMenu>
        )}
      </div>
      {isInEditMode &&
        variant.options.map((option) => (
          <FieldTypeOption
            key={option.value}
            option={option}
            isActive={option.value === variant.value}
            onClick={handleOptionChange}
          />
        ))}
    </div>
  );
};

export default SortingVariant;
