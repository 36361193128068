import { FC } from "react";

const FolderIcon: FC = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="22"
        height="22"
        rx="6"
        fill="#F0D8FF"
      />
      <path
        d="M12.5995 7.5C11.9037 7.49962 11.2365 7.22298 10.7445 6.73088L10.0182 6.00637C9.85462 5.8427 9.63281 5.75051 9.40137 5.75H6.1875C5.95829 5.74996 5.73821 5.83986 5.57459 6.00038C5.41096 6.16089 5.31685 6.3792 5.3125 6.60837L5.35275 7.5H4.47775L4.4375 6.625C4.4375 6.16087 4.62187 5.71575 4.95006 5.38756C5.27825 5.05937 5.72337 4.875 6.1875 4.875H9.4005C9.86459 4.8751 10.3096 5.05954 10.6377 5.38775L11.3622 6.11225C11.6904 6.44046 12.1354 6.6249 12.5995 6.625V7.5Z"
        fill="#9B42F0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0837 7.5H5.9162C5.79465 7.49999 5.67443 7.52531 5.56321 7.57434C5.45198 7.62337 5.35219 7.69503 5.2702 7.78476C5.18821 7.87449 5.12581 7.98032 5.08699 8.0955C5.04816 8.21069 5.03376 8.33269 5.0447 8.45375L5.60207 14.5788C5.62172 14.7962 5.72192 14.9984 5.883 15.1457C6.04408 15.293 6.25441 15.3748 6.4727 15.375H15.5272C15.7455 15.3748 15.9558 15.293 16.1169 15.1457C16.278 14.9984 16.3782 14.7962 16.3978 14.5788L16.9552 8.45375C16.9661 8.33269 16.9517 8.21069 16.9129 8.0955C16.8741 7.98032 16.8117 7.87449 16.7297 7.78476C16.6477 7.69503 16.5479 7.62337 16.4367 7.57434C16.3255 7.52531 16.2052 7.49999 16.0837 7.5ZM5.9162 6.625C5.67302 6.62497 5.4325 6.67563 5.20999 6.77374C4.98748 6.87185 4.78786 7.01526 4.62386 7.19482C4.45986 7.37438 4.33508 7.58614 4.25749 7.81661C4.1799 8.04708 4.15119 8.29119 4.1732 8.53338L4.73057 14.6584C4.77008 15.0932 4.97069 15.4975 5.29302 15.792C5.61535 16.0865 6.03611 16.2498 6.4727 16.25H15.5272C15.9638 16.2498 16.3845 16.0865 16.7069 15.792C17.0292 15.4975 17.2298 15.0932 17.2693 14.6584L17.8267 8.53338C17.8487 8.29119 17.82 8.04708 17.7424 7.81661C17.6648 7.58614 17.54 7.37438 17.376 7.19482C17.212 7.01526 17.0124 6.87185 16.7899 6.77374C16.5674 6.67563 16.3269 6.62497 16.0837 6.625H5.9162Z"
        fill="#9B42F0"
      />
    </svg>
  );
};

export default FolderIcon;
