import { FC } from "react";

import styles from "./RoundButton.module.scss";

interface RoundButtonProps extends React.PropsWithChildren {
  appearance:
    | "accent"
    | "neutral"
    | "outlined"
    | "inverted"
    | "positive"
    | "negative"
    | "negative-high-contrast";
  size: "default" | "small" | "full-width";
  disabled: boolean;
  style?: React.CSSProperties;
  onClick?: () => void;
  icon?: React.ReactNode;
  before?: React.ReactNode;
  after?: React.ReactNode;
}

const RoundButton: FC<Partial<RoundButtonProps>> = ({
  appearance = "accent",
  size = "default",
  disabled = false,
  style,
  children,
  onClick,
  icon,
  before,
  after,
}) => {
  const className = `${styles["button"]} ${styles[`button--${appearance}`]} ${
    styles[`button--${size}`]
  }`;

  return (
    <button
      className={className}
      style={style}
      disabled={disabled}
      onClick={onClick}
    >
      {before && (
        <div
          className={`${styles["icon-wrapper"]} ${styles["icon-wrapper--before"]}`}
        >
          {before}
        </div>
      )}
      {children}
      {icon && <div className={styles["icon-wrapper"]}>{icon}</div>}
      {after && (
        <div
          className={`${styles["icon-wrapper"]} ${styles["icon-wrapper--after"]}`}
        >
          {after}
        </div>
      )}
    </button>
  );
};

export default RoundButton;
