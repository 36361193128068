import { FC, useRef, useEffect } from "react";

import HorizontalDivider from "components/HorizontalDivider/HorizontalDivider";

import styles from "./TableControlPanel.module.scss";

interface TableControlPanelProps extends React.PropsWithChildren {
  headerComponent?: React.ReactNode | null;
  emptyStateComponent?: React.ReactNode | null;
  isEmpty?: boolean;
  dividerMargins?: string;
}

const TableControlPanel: FC<TableControlPanelProps> = ({
  headerComponent = null,
  emptyStateComponent = null,
  isEmpty = false,
  dividerMargins = "15px 0",
  children,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const updateHeight = () => {
      if (wrapperRef.current) {
        const rect = wrapperRef.current.getBoundingClientRect();
        const viewportHeight = window.innerHeight;
        const remainingHeight = viewportHeight - rect.top;

        wrapperRef.current.style.height = `${remainingHeight}px`;
        wrapperRef.current.style.minHeight = "300px";
      }
    };

    updateHeight();

    window.addEventListener("resize", updateHeight);

    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  return (
    <div
      ref={wrapperRef}
      className={styles.wrapper}
    >
      <div className={styles["header-wrapper"]}>
        {headerComponent}
        <HorizontalDivider
          style={{
            margin: dividerMargins,
          }}
        />
      </div>
      {isEmpty && emptyStateComponent}
      {children}
    </div>
  );
};

export default TableControlPanel;
