/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { Route, Routes, Navigate, Outlet, useParams } from "react-router";

import api from "./func/api";
import "./style.scss";
import Menu from "./components/Menu/Menu";
import Login from "./comp/Login";
import Constructor from "./comp/pageConstructor/Constructor";
import Navconstr from "./comp/pageConstructor/Navconstr";
import Person from "./comp/pageConstructor/Person";
import PersonList from "./comp/pageConstructor/PersonList";
import RoleList from "./comp/pageConstructor/RoleList";
import Role from "./comp/pageConstructor/Role";
import Profile from "./comp/Profile";
import SettingsMedia from "./comp/pageConstructor/SettingsMedia";

import Collection from "pages/Collection/Collection";
import Editor from "./pages/Editor/Editor";

import Media from "./comp/Media";

import { withRouter } from "./withRouter";

// import "bootstrap/dist/css/bootstrap.css";
import InfoCMSList from "./comp/pageConstructor/InfoCMSList";
import InfoCMS from "./comp/pageConstructor/InfoCMS";
import { errorCatch } from "./func/common";

import useDynamicMenu from "hooks/useDynamicMenu";
import Apikeys from "pages/Apikeys/Apikeys";

function App() {
  const [userData, setUserData] = useState(null);
  const [cmsData, setCmsData] = useState(null);

  const userCheck = userData !== undefined && userData !== null; // this is a boolean value that will be true if the user is logged in and false if not logged in

  const getCMSData = (slug) => {
    api
      .getInfoCMS(slug)
      .then((result) => {
        setCmsData(result);
      })
      .catch((err) => {
        errorCatch(err);
      });
  };

  useEffect(() => {
    api
      .getUserData()
      .then((result) => {
        setUserData(result);
      })
      .catch((err) => {
        console.log(`err`, err);
        setUserData(null);
      });
  }, []);

  useEffect(() => {
    getCMSData();
  }, [userCheck]);

  return (
    <div className="main-wrapper">
      <div className="wrapper flex-nowrap position-relative">
        {userCheck && cmsData && <Menu />}
        <Routes>
          <Route
            path="/login"
            element={<Login />}
          />
          <Route
            path="*"
            element={!userCheck ? <Navigate to="/login" /> : <Outlet />}
          />
          <Route
            path="/front/:cms/*"
            element={
              <FrontCMS
                userData={userData}
                setUserData={setUserData}
                cmsData={cmsData}
                getCMSData={getCMSData}
              />
            }
          />
          <Route
            path="/"
            element={
              userData ? (
                <Navigate
                  to={`/front/${Object.keys(userData.cmsAccess).find(
                    (key) => userData.cmsAccess[key]
                  )}`}
                />
              ) : (
                <Login />
              )
            }
          />
        </Routes>
      </div>
    </div>
  );
}

function FrontCMS({ userData, setUserData, cmsData, getCMSData }) {
  const params = useParams();
  const { updateDynamicMenu } = useDynamicMenu(cmsData?.slug || params.cms);

  if (!cmsData || (cmsData && cmsData.slug !== params.cms)) {
    getCMSData(params.cms);
  }

  useEffect(() => {
    updateDynamicMenu();
  }, [updateDynamicMenu]);

  return (
    <Routes>
      {/* Constructor routes */}
      <Route
        path="constructor"
        element={
          <WrapperConstructor
            cms={params.cms}
            updateDynamicMenu={updateDynamicMenu}
          />
        }
      >
        <Route
          path=":slug"
          element={<Outlet />}
        />
      </Route>

      {/* Page routes */}
      <Route
        path="pages/:namecoll"
        element={
          <WrapperEditor
            cms={params.cms}
            typeModel={"pages"}
            userData={userData}
          />
        }
      />
      <Route
        path="single-pages/:namecoll"
        element={
          <WrapperEditor
            cms={params.cms}
            typeModel={"pages"}
            userData={userData}
          />
        }
      />

      {/* Collection routes */}
      <Route
        path="collections/:namecoll/:id"
        element={
          <WrapperEditor
            cms={params.cms}
            typeModel={"collections"}
            userData={userData}
            router={params}
          />
        }
      />
      <Route
        path="collections"
        element={
          <WrapperCollection
            cms={params.cms}
            typeModel={"collections"}
            userData={userData}
            setUserData={setUserData}
            cmsData={cmsData}
          />
        }
      >
        <Route
          path=":namecoll"
          element={<Outlet />}
        />
      </Route>

      {/* Menu routes */}
      <Route
        path="settings/menu"
        element={
          <Navconstr
            updateDynamicMenu={updateDynamicMenu}
            cms={params.cms}
          />
        }
      />

      {/* Media routes */}

      <Route
        path="media"
        element={
          <Media
            routProps={params}
            cms={params.cms}
          />
        }
      />

      {/* Settings routes */}
      <Route
        path="settings/media"
        element={<SettingsMedia cms={params.cms} />}
      />

      <Route
        path="profile"
        element={<Profile />}
      />
      <Route
        path="settings/user"
        element={<PersonList cms={params.cms} />}
      />
      <Route
        path="settings/user/:id"
        element={
          <WrapperPerson
            cms={params.cms}
            userData={userData}
          />
        }
      />
      <Route
        path="settings/role"
        element={<RoleList cms={params.cms} />}
      />
      <Route
        path="settings/role/:id"
        element={<WrapperRole cms={params.cms} />}
      />
      <Route
        path="settings/info-cms"
        element={<InfoCMSList cms={params.cms} />}
      />
      <Route
        path="settings/info-cms/:id"
        element={<WrappeInfoCMS cms={params.cms} />}
      />
      <Route
        path="settings/api-keys"
        element={<Apikeys />}
      />
    </Routes>
  );
}

const WrapperConstructor = ({ cms, updateDynamicMenu }) => {
  const { slug } = useParams();

  return (
    <Constructor
      cms={cms}
      slug={slug}
      updateDynamicMenu={updateDynamicMenu}
    />
  );
};

const WrapperCollection = ({
  cms,
  typeModel,
  userData,
  setUserData,
  cmsData,
}) => {
  return (
    <Collection
      cms={cms}
      typeModel={typeModel}
      userData={userData}
      setUserData={setUserData}
      cmsData={cmsData}
    />
  );
};

const WrapperEditor = ({ cms, typeModel, userData }) => {
  const { namecoll, id } = useParams();
  return (
    <Editor
      cms={cms}
      typeModel={typeModel}
      nameColl={namecoll}
      id={id}
      userData={userData}
    />
  );
};

const WrapperRole = ({ cms }) => {
  const { id } = useParams();
  return (
    <Role
      id={id}
      cms={cms}
    />
  );
};

const WrapperPerson = ({ cms, userData }) => {
  const { id } = useParams();
  return (
    <Person
      id={id}
      cms={cms}
      userData={userData}
    />
  );
};

const WrappeInfoCMS = ({ cms }) => {
  const { id } = useParams();
  return (
    <InfoCMS
      id={id}
      cms={cms}
    />
  );
};

export default withRouter(App);
