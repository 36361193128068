import { FC, useCallback, useMemo } from "react";
import Button from "components/Button/Button";
import HorizontalDivider from "components/HorizontalDivider/HorizontalDivider";
import ToggleSwitch from "components/ToggleSwitch/ToggleSwitch";
import { TrashIcon, BorderlessPlusIcon } from "icons";
import FilterVariant from "pages/Collection/components/Filters/FilterVariant";
import type { FilterGroup as IFilterGroup } from "typings/filter";
import {
  deleteFilterGroup,
  setGroupFilteringType,
} from "store/slices/filterSlice";
import { useAppDispatch } from "hooks/useAppDispatch";
import iconsByType from "utils/iconsByType";

import styles from "./FilterGroup.module.scss";

interface FilterGroupProps {
  filterGroup: IFilterGroup;
  onAddFilterClick: () => void;
}

const FilterGroup: FC<FilterGroupProps> = ({
  filterGroup,
  onAddFilterClick,
}) => {
  const dispatch = useAppDispatch();

  type FilterType = "OR" | "AND";
  // Options for the toggle switch
  const options = [
    { id: "AND" as FilterType, label: "AND" },
    { id: "OR" as FilterType, label: "OR" },
  ];

  const handleDeleteGroup = useCallback(
    (id: string) => {
      dispatch(deleteFilterGroup({ id }));
    },
    [dispatch]
  );

  const handleToggle = useCallback(
    (filterType: FilterType) => {
      dispatch(
        setGroupFilteringType({
          filterGroupId: filterGroup.id,
          groupFilteringType: filterType,
        })
      );
    },
    [dispatch, filterGroup.id]
  );

  const groupFilteringType = useMemo(() => {
    return filterGroup.groupFilteringType;
  }, [filterGroup.groupFilteringType]);

  return (
    <div
      className={styles["wrapper"]}
      key={filterGroup.id}
    >
      <div className={styles["header"]}>
        <div>
          <div className={styles["title"]}>Group</div>
          <ToggleSwitch
            options={options}
            selectedOption={groupFilteringType}
            onToggle={handleToggle}
            onGrayBackground
          />
        </div>
        <div
          className={styles["delete-button"]}
          onClick={() => {
            handleDeleteGroup(filterGroup.id);
          }}
        >
          <TrashIcon />
        </div>
      </div>

      {filterGroup.children.map((filter) => (
        <FilterVariant
          key={filter.id}
          filter={filter}
          icon={iconsByType[filter.type]}
          smallPaddings
          onGrayBackground
        />
      ))}
      <HorizontalDivider
        style={{
          margin: "3px 0",
        }}
      />
      <Button
        className={styles["add-filter-button"]}
        icon={<BorderlessPlusIcon />}
        squaredIcon
        onClick={onAddFilterClick}
      >
        Add filter
      </Button>
    </div>
  );
};

export default FilterGroup;
