import { FC } from "react";

const DateIcon: FC = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.0625 0C3.17853 0 3.28981 0.0460936 3.37186 0.128141C3.45391 0.210188 3.5 0.321468 3.5 0.4375V0.875H10.5V0.4375C10.5 0.321468 10.5461 0.210188 10.6281 0.128141C10.7102 0.0460936 10.8215 0 10.9375 0C11.0535 0 11.1648 0.0460936 11.2469 0.128141C11.3289 0.210188 11.375 0.321468 11.375 0.4375V0.875H12.25C12.7141 0.875 13.1592 1.05937 13.4874 1.38756C13.8156 1.71575 14 2.16087 14 2.625V12.25C14 12.7141 13.8156 13.1592 13.4874 13.4874C13.1592 13.8156 12.7141 14 12.25 14H1.75C1.28587 14 0.840752 13.8156 0.512563 13.4874C0.184374 13.1592 0 12.7141 0 12.25V2.625C0 2.16087 0.184374 1.71575 0.512563 1.38756C0.840752 1.05937 1.28587 0.875 1.75 0.875H2.625V0.4375C2.625 0.321468 2.67109 0.210188 2.75314 0.128141C2.83519 0.0460936 2.94647 0 3.0625 0V0ZM0.875 3.5V12.25C0.875 12.4821 0.967187 12.7046 1.13128 12.8687C1.29538 13.0328 1.51794 13.125 1.75 13.125H12.25C12.4821 13.125 12.7046 13.0328 12.8687 12.8687C13.0328 12.7046 13.125 12.4821 13.125 12.25V3.5H0.875Z"
        fill="inherit"
      />
      <path
        d="M5.63938 10.227V5.55975H5.0855C4.68881 5.77121 4.30543 6.00673 3.9375 6.265V6.87312C4.26563 6.64825 4.78537 6.33062 5.03825 6.19325H5.04875V10.227H5.63938ZM6.67888 9.08512C6.72 9.64512 7.19863 10.3154 8.169 10.3154C9.26975 10.3154 9.919 9.38262 9.919 7.80325C9.919 6.111 9.23563 5.46875 8.21013 5.46875C7.39988 5.46875 6.63775 6.05675 6.63775 7.05162C6.63775 8.06662 7.35875 8.60038 8.10425 8.60038C8.757 8.60038 9.1805 8.27137 9.31438 7.90912H9.338C9.3345 9.06062 8.93463 9.80262 8.19613 9.80262C7.61512 9.80262 7.31413 9.40887 7.27738 9.08512H6.67888ZM9.26275 7.05775C9.26275 7.66675 8.77363 8.09025 8.22675 8.09025C7.70088 8.09025 7.22575 7.75512 7.22575 7.04025C7.22575 6.32012 7.735 5.9815 8.24775 5.9815C8.80163 5.9815 9.26275 6.32975 9.26275 7.05775Z"
        fill="inherit"
      />
    </svg>
  );
};

export default DateIcon;
