import { FC } from "react";

import { PlusIcon } from "icons";

import Button from "components/Button/Button";
import DynamicMenu from "components/Menu/DynamicMenu";
import StaticMenu from "components/Menu/StaticMenu";
import HorizontalDivider from "components/HorizontalDivider/HorizontalDivider";

import styles from "./Menu.module.scss";

const Menu: FC = () => {
  return (
    <div className={styles.menu}>
      <div className={styles.menu__header}>Flexim</div>
      <div className={styles["menu__main-wrapper"]}>
        <Button
          icon={<PlusIcon />}
          iconPosition="right"
          className="d-flex justify-content-between align-items-center"
          childrenWrapperClassName={styles["menu__button-text"]}
          onClick={() => (window.location.href = "/front/main/constructor")}
        >
          Create
        </Button>
        <HorizontalDivider />
        <DynamicMenu key="dynamicMenu" />
        <HorizontalDivider />
        <StaticMenu key="staticMenu" />
      </div>
    </div>
  );
};

export default Menu;
