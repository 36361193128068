import { FC } from "react";

const ImageIcon: FC = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0294 1.75H2.14706C1.92865 1.75 1.71918 1.84219 1.56474 2.00628C1.41029 2.17038 1.32353 2.39294 1.32353 2.625V10.5L3.50259 8.44025C3.5699 8.36899 3.65833 8.32466 3.75289 8.31478C3.84745 8.30489 3.94232 8.33005 4.02141 8.386L6.212 9.93738L9.26729 6.69112C9.32836 6.62618 9.40711 6.58329 9.49239 6.56853C9.57767 6.55376 9.66516 6.56788 9.74247 6.60887L12.8529 8.3125V2.625C12.8529 2.39294 12.7662 2.17038 12.6117 2.00628C12.4573 1.84219 12.2478 1.75 12.0294 1.75ZM2.14706 0.875C1.71023 0.875 1.2913 1.05937 0.982412 1.38756C0.673529 1.71575 0.5 2.16087 0.5 2.625V11.375C0.5 11.8391 0.673529 12.2842 0.982412 12.6124C1.2913 12.9406 1.71023 13.125 2.14706 13.125H12.0294C12.2457 13.125 12.4599 13.0797 12.6597 12.9918C12.8595 12.9038 13.0411 12.7749 13.1941 12.6124C13.347 12.4499 13.4683 12.257 13.5511 12.0447C13.6339 11.8324 13.6765 11.6048 13.6765 11.375V2.625C13.6765 2.16087 13.5029 1.71575 13.1941 1.38756C12.8852 1.05937 12.4662 0.875 12.0294 0.875H2.14706ZM5.44118 4.8125C5.44118 4.98486 5.40922 5.15553 5.34715 5.31477C5.28507 5.47401 5.19408 5.6187 5.07937 5.74058C4.96466 5.86245 4.82848 5.95913 4.67861 6.02509C4.52874 6.09105 4.3681 6.125 4.20588 6.125C4.04366 6.125 3.88303 6.09105 3.73316 6.02509C3.58328 5.95913 3.44711 5.86245 3.3324 5.74058C3.21769 5.6187 3.1267 5.47401 3.06462 5.31477C3.00254 5.15553 2.97059 4.98486 2.97059 4.8125C2.97059 4.4644 3.10073 4.13056 3.3324 3.88442C3.56406 3.63828 3.87826 3.5 4.20588 3.5C4.5335 3.5 4.8477 3.63828 5.07937 3.88442C5.31103 4.13056 5.44118 4.4644 5.44118 4.8125Z"
        fill="inherit"
      />
    </svg>
  );
};

export default ImageIcon;
