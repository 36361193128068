import { Dispatch, FC, SetStateAction } from "react";
import InForModelContainer from "comp/common/InForModel";
import ModalOvelay from "comp/common/ModalOverlay";
import SidebarBox from "comp/common/SidebarBox";
import ExcelProgress from "pages/Collection/components/ExcelProgress";
import ExcelProgressExport from "pages/Collection/components/ExcelProgressExport";
import ExcelSettings from "pages/Collection/components/ExcelSettings";
import { PARAM_COLLECTION_ID } from "constants/index";
import useRequiredParam from "hooks/useRequiredParam";

type LegacyModalsProps = {
  typeModel: string;
  progressProps: {
    openImportProgressPopup: boolean;
    openExportProgressPopup: boolean;
    statusImportExcel: string;
    statusExportExcel: { status: string };
    setOpenImportProgressPopup: Dispatch<SetStateAction<boolean>>;
    setOpenExportProgressPopup: Dispatch<SetStateAction<boolean>>;
  };
  excelSettingsModalOpen: boolean;
  setExcelSettingsModalOpen: Dispatch<SetStateAction<boolean>>;
  extractModalOpen: boolean;
  setExtractModalOpen: Dispatch<SetStateAction<boolean>>;
};

const LegacyModals: FC<LegacyModalsProps> = ({
  typeModel,
  progressProps,
  excelSettingsModalOpen,
  setExcelSettingsModalOpen,
  extractModalOpen,
  setExtractModalOpen,
}) => {
  const collectionId = useRequiredParam(PARAM_COLLECTION_ID);

  return (
    <div>
      <ModalOvelay
        key={"excelSettings"}
        open={excelSettingsModalOpen}
        timeout={300}
        closePopUp={(e: any) => {
          if (e) {
            e.stopPropagation();
            e.preventDefault();
          }
          setExcelSettingsModalOpen(false);
        }}
      >
        <SidebarBox open={excelSettingsModalOpen}>
          <ExcelSettings
            nameColl={collectionId}
            selectOps={[]}
            fields={[]}
          />
        </SidebarBox>
      </ModalOvelay>

      <ModalOvelay
        key={"InForModel"}
        open={extractModalOpen}
        timeout={300}
        closePopUp={(e: any) => {
          if (e) {
            e.stopPropagation();
            e.preventDefault();
          }
          setExtractModalOpen(false);
        }}
      >
        <SidebarBox
          open={extractModalOpen}
          className="w-700"
        >
          <InForModelContainer
            typeModel={typeModel}
            nameColl={collectionId}
            schemaNameDB={collectionId}
            fields={[]}
            selectOps={[]}
            lockName={""}
          />
        </SidebarBox>
      </ModalOvelay>

      {progressProps.openImportProgressPopup && (
        <ExcelProgress
          dowloadProgress={0}
          statusImportExcel={progressProps.statusImportExcel}
          setImportPopupExcel={progressProps.setOpenImportProgressPopup}
        />
      )}
      {progressProps.openExportProgressPopup && (
        <ExcelProgressExport
          statusExportExcel={progressProps.statusExportExcel}
          setExportPopupExcel={progressProps.setOpenExportProgressPopup}
        />
      )}
    </div>
  );
};

export default LegacyModals;
