/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import PopupEditMedia from "./PopupMedia";
import UploadMediaBlock from "./UploadMediaBlock";
import { SERVER_URL } from "../../func/constants";

const UploadMediaModal = (props) => {
  const {
    activePic,
    setActivePic,
    open,
    close,
    getData,
    editMediaDefault = false,
  } = props;

  const [editMedia, setEditMedia] = useState(editMediaDefault);

  useEffect(() => {
    return () => {};
  }, []);

  const closeEditMedia = () => {
    if (editMediaDefault) close();
    else setEditMedia(false);
  };

  const imgLink = `${SERVER_URL + "/" + activePic?.url}`;
  return (
    <div
      className={`modal fade ${open ? "show" : ""}`}
      style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
      onMouseDown={(e) => {
        e.stopPropagation();

        if (e.currentTarget === e.target) close();
      }}
    >
      {!editMedia ? (
        <div
          className="modal-dialog modal-xl modal-dialog-centered modal-dialog-media"
          style={{ zIndex: 1070 }}
        >
          <div className="modal-content">
            <div className="modal-header bg-light">
              <h5
                className="modal-title"
                id="uploadMediaModalLabel"
              >
                Загрузка медиа
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  close();
                }}
              ></button>
            </div>
            <div className="modal-body">
              <UploadMediaBlock
                activePic={activePic}
                getData={getData}
                setEditMedia={setEditMedia}
                setActivePic={setActivePic}
              />
            </div>
          </div>
        </div>
      ) : (
        <PopupEditMedia
          link={imgLink}
          id={activePic?._id}
          close={closeEditMedia}
          save={getData}
        />
      )}
    </div>
  );
};

export default UploadMediaModal;
