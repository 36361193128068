import { FC } from "react";

import styles from "./SelectOption.module.scss";

interface SelectOptionProps {
  label: string;
  onClose: () => void;
}

const SelectOption: FC<SelectOptionProps> = ({ label, onClose }) => {
  return (
    <div className={styles["wrapper"]}>
      <span className={styles["label"]}>{label}</span>
      <button
        className={styles["close-button"]}
        onClick={onClose}
      >
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.06518 4.06506C4.10582 4.02431 4.1541 3.99199 4.20725 3.96993C4.2604 3.94788 4.31738 3.93652 4.37493 3.93652C4.43247 3.93652 4.48945 3.94788 4.54261 3.96993C4.59576 3.99199 4.64404 4.02431 4.68468 4.06506L6.99993 6.38118L9.31518 4.06506C9.35585 4.02438 9.40414 3.99211 9.45729 3.9701C9.51044 3.94808 9.5674 3.93675 9.62493 3.93675C9.68245 3.93675 9.73942 3.94808 9.79256 3.9701C9.84571 3.99211 9.894 4.02438 9.93468 4.06506C9.97535 4.10573 10.0076 4.15402 10.0296 4.20717C10.0516 4.26032 10.063 4.31728 10.063 4.37481C10.063 4.43233 10.0516 4.48929 10.0296 4.54244C10.0076 4.59559 9.97535 4.64388 9.93468 4.68456L7.61855 6.9998L9.93468 9.31505C9.97535 9.35573 10.0076 9.40402 10.0296 9.45717C10.0516 9.51032 10.063 9.56728 10.063 9.6248C10.063 9.68233 10.0516 9.73929 10.0296 9.79244C10.0076 9.84559 9.97535 9.89388 9.93468 9.93455C9.894 9.97523 9.84571 10.0075 9.79256 10.0295C9.73942 10.0515 9.68245 10.0629 9.62493 10.0629C9.5674 10.0629 9.51044 10.0515 9.45729 10.0295C9.40414 10.0075 9.35585 9.97523 9.31518 9.93455L6.99993 7.61843L4.68468 9.93455C4.644 9.97523 4.59571 10.0075 4.54256 10.0295C4.48942 10.0515 4.43245 10.0629 4.37493 10.0629C4.3174 10.0629 4.26044 10.0515 4.20729 10.0295C4.15414 10.0075 4.10585 9.97523 4.06518 9.93455C4.0245 9.89388 3.99223 9.84559 3.97022 9.79244C3.94821 9.73929 3.93687 9.68233 3.93687 9.6248C3.93687 9.56728 3.94821 9.51032 3.97022 9.45717C3.99223 9.40402 4.0245 9.35573 4.06518 9.31505L6.3813 6.9998L4.06518 4.68456C4.02443 4.64392 3.99211 4.59564 3.97005 4.54248C3.948 4.48933 3.93665 4.43235 3.93665 4.37481C3.93665 4.31726 3.948 4.26028 3.97005 4.20713C3.99211 4.15397 4.02443 4.1057 4.06518 4.06506Z"
            fill="#9B42F0"
          />
        </svg>
      </button>
    </div>
  );
};

export default SelectOption;
