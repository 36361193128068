import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

const toLocalISOString = (date) => {
  const offsetDate = new Date(date.getTime());

  const year = offsetDate.getFullYear();
  const month = (offsetDate.getMonth() + 1).toString().padStart(2, "0");
  const day = offsetDate.getDate().toString().padStart(2, "0");
  const hours = offsetDate.getHours().toString().padStart(2, "0");
  const minutes = offsetDate.getMinutes().toString().padStart(2, "0");
  const seconds = offsetDate.getSeconds().toString().padStart(2, "0");

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
};

const DatePicker = ({
  body,
  activeIndex,
  dataObjInside,
  dispatch,
  changeData,
  data,
  path,
}) => {
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");

  const isRequired = body[activeIndex].requiredField;

  useEffect(() => {
    const currrentDate = dataObjInside[body[activeIndex].nameDB];
    if (!currrentDate) return;

    if (typeof currrentDate === "string") {
      // Convert time to timezoned time (local time)
      const localDate = toLocalISOString(new Date(currrentDate));
      const [datePart, timePart] = localDate.split("T");
      const [hours, minutes] = timePart
        ? timePart.split(":").slice(0, 2)
        : ["", ""];

      setDate(datePart);
      setTime(`${hours}:${minutes}`);
    }
  }, [activeIndex, body, dataObjInside]);

  const handleDateChange = (e) => {
    const newDay = e.target.valueAsNumber;
    setDate(e.target.value);

    const MILLISECONDS_IN_HOUR = 3600000;
    const MILLISECONDS_IN_MINUTE = 60000;

    let chosenTimeInMs = 0;
    if (time) {
      const [hours, minutes] = time.split(":").map(Number);

      if (!isNaN(hours) && !isNaN(minutes)) {
        chosenTimeInMs =
          hours * MILLISECONDS_IN_HOUR + minutes * MILLISECONDS_IN_MINUTE;
      }
    }

    const chosenDateInMsWithHoursToZero = new Date(newDay).setHours(0, 0, 0, 0);

    dispatch({
      data: changeData({
        data,
        path: [...path, body[activeIndex].nameDB],
        value: chosenDateInMsWithHoursToZero + chosenTimeInMs,
      }),
    });
  };

  const handleTimeChange = (e) => {
    const timeInMs = e.target.valueAsNumber;
    const chosenDateInMs = date
      ? new Date(date).setHours(0, 0, 0, 0)
      : new Date().setHours(0, 0, 0, 0);
    const value = chosenDateInMs + timeInMs;

    dispatch({
      data: changeData({
        data,
        path: [...path, body[activeIndex].nameDB],
        value,
      }),
    });

    setTime(e.target.value);
  };

  return (
    <div
      className="flexim-field-wrapper"
      key={body[activeIndex].nameDB}
    >
      <h6 className={`flexim-field-name${isRequired ? " required" : ""}`}>
        {body[activeIndex].name}
      </h6>
      <form className="row">
        <div className="col">
          <div className="input-group">
            <input
              onChange={handleDateChange}
              value={date}
              className="flexim-input"
              type="date"
              style={{ width: "300px" }}
            />
          </div>
        </div>
        <div className="col">
          <div className="input-group">
            <input
              onChange={handleTimeChange}
              className="flexim-input"
              type="time"
              value={time}
              style={{ width: "125px" }}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

DatePicker.propTypes = {
  body: PropTypes.array.isRequired,
  activeIndex: PropTypes.number.isRequired,
  dataObjInside: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  changeData: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  path: PropTypes.array.isRequired,
};

export default DatePicker;
