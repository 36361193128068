import { FC } from "react";

const CheckCircleIcon: FC = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_125_8713)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9 16.875C11.0886 16.875 13.0916 16.0453 14.5685 14.5685C16.0453 13.0916 16.875 11.0886 16.875 9C16.875 6.91142 16.0453 4.90838 14.5685 3.43153C13.0916 1.95468 11.0886 1.125 9 1.125C6.91142 1.125 4.90838 1.95468 3.43153 3.43153C1.95468 4.90838 1.125 6.91142 1.125 9C1.125 11.0886 1.95468 13.0916 3.43153 14.5685C4.90838 16.0453 6.91142 16.875 9 16.875ZM9 18C11.3869 18 13.6761 17.0518 15.364 15.364C17.0518 13.6761 18 11.3869 18 9C18 6.61305 17.0518 4.32387 15.364 2.63604C13.6761 0.948212 11.3869 0 9 0C6.61305 0 4.32387 0.948212 2.63604 2.63604C0.948212 4.32387 0 6.61305 0 9C0 11.3869 0.948212 13.6761 2.63604 15.364C4.32387 17.0518 6.61305 18 9 18Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.341 5.59126C12.4984 5.43519 12.711 5.34736 12.9327 5.34674C13.1544 5.34613 13.3674 5.43279 13.5257 5.58799C13.684 5.74319 13.7748 5.95446 13.7786 6.17611C13.7824 6.39777 13.6988 6.61201 13.5459 6.77251L9.05487 12.3863C8.97769 12.4694 8.88453 12.5361 8.78097 12.5824C8.67741 12.6287 8.56558 12.6537 8.45216 12.6558C8.33874 12.6579 8.22605 12.6371 8.12085 12.5946C8.01565 12.5522 7.92009 12.489 7.83988 12.4088L4.86425 9.43201C4.78135 9.35477 4.71486 9.26162 4.66875 9.15812C4.62263 9.05462 4.59783 8.94289 4.59583 8.8296C4.59384 8.71631 4.61468 8.60377 4.65711 8.49871C4.69955 8.39365 4.76271 8.29821 4.84283 8.21809C4.92295 8.13797 5.01839 8.07481 5.12345 8.03237C5.22851 7.98994 5.34105 7.9691 5.45434 7.97109C5.56763 7.97309 5.67936 7.99789 5.78286 8.04401C5.88635 8.09012 5.97951 8.15661 6.05675 8.23951L8.4125 10.5941L12.3196 5.61601C12.3266 5.6073 12.3341 5.59903 12.3421 5.59126H12.341Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

export default CheckCircleIcon;
