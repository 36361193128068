/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import api from "../../../../func/api";
import AsyncSelect from "react-select/async";
import { errorCatch } from "../../../../func/common";
import { ReactSortable } from "react-sortablejs";

import {
  IndicatorsContainer,
  DropdownIndicator,
  OptionComponent,
  customStyles,
} from "components/Select/Select"; // TODO: Remove when refactor

const Ref = ({
  changeData,
  data,
  path,
  dataObjInside: pageData,
  bodyInside: fieldData,
  namesRefColl,
  dataRefColl,
  setNamesRefColl,
  defaultsNames,
  dispatch,
}) => {
  const [input, setInput] = useState("");
  const [options, setOptions] = useState([]);

  // To manage change of Ref's Type option from `Single connection` to `Multiple connections` and vice versa
  const [selectedOptions, setSelectedOptions] = useState(
    pageData && pageData[fieldData.nameDB]
      ? Array.isArray(pageData[fieldData.nameDB])
        ? pageData[fieldData.nameDB].map((el) => el)
        : [pageData[fieldData.nameDB]].map((el) => el)
      : []
  );

  useEffect(() => {
    if (!namesRefColl.includes(fieldData.refTo) && fieldData.refTo !== null) {
      setNamesRefColl([...namesRefColl, fieldData.refTo]);
    }
  }, [namesRefColl, fieldData.refTo, setNamesRefColl]);

  useEffect(() => {
    if (!fieldData.refTo) return;
    api
      .filterDatas({
        typeModel: "collections",
        model: fieldData.refTo,
      })
      .then((result) => {
        if (result.status === "success" && result.data.items.length) {
          const items = result.data.items;

          let key = Object.keys(items[0]);
          key.splice(key.indexOf("_id"), 1);
          const _options = items.map((el) => ({
            label: defaultsNames[fieldData.refTo]
              ? el[defaultsNames[fieldData.refTo]]
              : el[key[0]],
            value: el._id,
          }));
          setOptions([..._options]);
        }
      })
      .catch((err) => {
        errorCatch(err);
      });
  }, [setOptions, defaultsNames, fieldData.refTo]);

  let list =
    fieldData.isMulti && selectedOptions.length
      ? selectedOptions.map((val, i) => {
          let option = val.value;
          if (typeof option === "string") {
            return (
              <div
                key={i}
                className="flexim-tag cursor-drag dragable-tag"
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "6px",
                }}
              >
                <span className="flexim-tag--with-underline">
                  {options.filter((e) => e.value === option)[0]?.label}
                </span>
                <i
                  onClick={() => {
                    selectedOptions.splice(i, 1);
                    setSelectedOptions([...selectedOptions]);
                  }}
                  className="bi bi-x pointer"
                  style={{
                    width: "18px",
                    height: "18px",
                  }}
                />
              </div>
            );
          } else {
            return (
              <div
                key={i}
                className="flexim-tag cursor-drag dragable-tag"
                style={{
                  display: "inline-flex",
                  alignItems: "center",
                  gap: "6px",
                }}
              >
                <span className="flexim-tag--with-underline">{val.name}</span>
                <i
                  onClick={() => {
                    selectedOptions.splice(i, 1);
                    setSelectedOptions([...selectedOptions]);
                  }}
                  className="bi bi-x pointer"
                  style={{
                    width: "18px",
                    height: "18px",
                  }}
                />
              </div>
            );
          }
        })
      : [];

  const prevSelectedOptions = useRef(selectedOptions);

  useEffect(() => {
    if (
      JSON.stringify(prevSelectedOptions.current) !==
      JSON.stringify(selectedOptions)
    ) {
      if (pageData) {
        pageData[fieldData.nameDB] =
          selectedOptions.length > 0
            ? selectedOptions.map((el) => el._id)
            : null;

        dispatch({ data });
      }
      prevSelectedOptions.current = selectedOptions;
    }
  }, [
    selectedOptions,
    fieldData.isMulti,
    pageData,
    fieldData.nameDB,
    dispatch,
    data,
  ]);

  const handleAsyncSelectChange = (e) => {
    if (fieldData.isMulti) {
      // check if the selected option is already in the selectedOptions
      if (selectedOptions.find((el) => el._id === e.value)) return;

      setSelectedOptions(
        pageData[fieldData.nameDB]
          ? [...selectedOptions, { name: e.label, _id: e.value }]
          : [{ name: e.label, _id: e.value }]
      );
    } else {
      dispatch({
        data: changeData({
          data,
          path: [...path, fieldData.nameDB],
          value: fieldData.isMulti
            ? pageData[fieldData.nameDB]
              ? [...pageData[fieldData.nameDB], e.value]
              : [e.value]
            : e.value,
        }),
      });
      setSelectedOptions([{ name: e.label, _id: e.value }]);
    }
  };

  const handleLoadOptions = (value, callback) => {
    if (fieldData.refTo === null) return callback([]);

    api
      .filterDatas({
        typeModel: "collections",
        model: fieldData.refTo,
        data: {
          filter: { name: { $regex: value, $options: "i" } },
        },
      })
      .then((result) => {
        const newData = result.data?.items.map((el) => ({
          label: el[defaultsNames[fieldData.refTo]],
          value: el._id,
        }));
        callback(newData);
      })
      .catch((err) => {
        errorCatch(err);
      });
  };

  return (
    <div className="flexim-field-wrapper flexim-field-wrapper--align-baseline">
      <h6
        className={`flexim-field-name${
          fieldData.requiredField ? " required" : ""
        }`}
      >
        {fieldData.name}
      </h6>
      <div className="w-100">
        <AsyncSelect
          styles={customStyles}
          cacheOptions
          inputValue={input}
          placeholder="Enter something to search..."
          onChange={handleAsyncSelectChange}
          onInputChange={(value, info) => {
            if (info.action === "input-change") setInput(value);
          }}
          loadOptions={handleLoadOptions}
          components={{
            IndicatorSeparator: null,
            IndicatorsContainer,
            DropdownIndicator,
            MultiValue: () => null,
            Option: OptionComponent,
          }}
        />
        {!fieldData.isMulti &&
          pageData[fieldData.nameDB] !== undefined &&
          pageData[fieldData.nameDB] !== null &&
          pageData[fieldData.nameDB]?.length !== 0 &&
          selectedOptions.length > 0 && (
            <label
              className="text-secondary fs-16"
              style={{
                marginTop: "8px",
              }}
            >
              Selected:{" "}
              <Link
                to={`/front/main/collections/${fieldData.refTo}/${selectedOptions?.[0]?._id}`}
              >
                <div
                  className="flexim-tag"
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    gap: "6px",
                  }}
                >
                  <span className="flexim-tag--with-underline">
                    {selectedOptions?.[0]?.name}
                  </span>
                  <i
                    onClick={(e) => {
                      e.preventDefault();
                      setSelectedOptions([]);
                    }}
                    className="bi bi-x pointer"
                    style={{
                      width: "18px",
                      height: "18px",
                    }}
                  />
                </div>
              </Link>
            </label>
          )}
        {fieldData.isMulti ? (
          <ReactSortable
            list={selectedOptions}
            setList={setSelectedOptions}
            style={{
              display: "flex",
              flexWrap: "wrap",
              marginTop: "8px",
              gap: "6px",
            }}
            handle=".dragable-tag"
          >
            {list}
          </ReactSortable>
        ) : null}
      </div>
    </div>
  );
};

export default Ref;
