import type ICell from "interfaces/ICell";
import type ICellRenderStrategy from "interfaces/ICellRenderStrategy";
import Tag from "pages/Collection/components/DataTable/Tag";

export class SelectCellRenderStrategy implements ICellRenderStrategy<ICell> {
  renderHeaderCell(data: ICell) {
    return <>{data.name}</>;
  }

  renderCell(data: ICell, props?: any): JSX.Element | null {
    const { value, styles } = props;

    return (
      <div className={`${styles["flex-cell"]} ${styles["content-column"]}`}>
        {value?.length > 0 ? <Tag>{value}</Tag> : "—"}
      </div>
    );
  }
}
