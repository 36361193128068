import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { apiService } from "api/apiService";
import { COLLECTION_CONTROL_PANEL } from "constants/index";
import { setCurrentViewConfiguration } from "store/thunks/collectionPageInfoThunks";
import type { SortableField, Option } from "typings/sorting";

import { optionsByFieldType, alphabeticalOptions } from "constants/index";

const setAllVariantsEditMode = (variants: any[], isInEditMode: boolean) => {
  for (let i = 0; i < variants.length; i++) {
    variants[i].isInEditMode = isInEditMode;
  }
};

const initialState: {
  sortableFields: SortableField[];
  savedSortableFields: SortableField[];
  initialSortableFieldsStateAsJson: string;
} = {
  sortableFields: [],
  savedSortableFields: [],
  initialSortableFieldsStateAsJson: "[]",
};

export const sortingSlice = createSlice({
  name: COLLECTION_CONTROL_PANEL.SORTING,
  initialState,
  reducers: {
    addSortingVariant: (state, action: PayloadAction<SortableField>) => {
      setAllVariantsEditMode(state.sortableFields, false);

      state.sortableFields.push(action.payload);
    },
    setVariantToEditMode: (state, action: PayloadAction<string>) => {
      setAllVariantsEditMode(state.sortableFields, false);

      const index = state.sortableFields.findIndex(
        (variant) => variant.id === action.payload
      );

      if (index !== -1) {
        state.sortableFields[index].isInEditMode = true;
      }
    },
    setVariantValue: (
      state,
      action: PayloadAction<{ id: SortableField["id"]; value: Option["value"] }>
    ) => {
      const index = state.sortableFields.findIndex(
        (variant) => variant.id === action.payload.id
      );

      if (index !== -1) {
        state.sortableFields[index].value = action.payload.value;
      }
    },
    saveVariant: (state, action: PayloadAction<SortableField["id"]>) => {
      setAllVariantsEditMode(state.sortableFields, false);

      const index = state.sortableFields.findIndex(
        (variant) => variant.id === action.payload
      );

      if (index !== -1) {
        state.sortableFields[index].isSaved = true;
      }

      const indexInSavedArr = state.savedSortableFields.findIndex(
        (variant) => variant.id === action.payload
      );

      if (indexInSavedArr !== -1) {
        state.savedSortableFields[indexInSavedArr] =
          state.sortableFields[index];
      } else {
        state.savedSortableFields.push(state.sortableFields[index]);
      }
    },
    deleteVariant: (state, action: PayloadAction<SortableField["id"]>) => {
      const index = state.sortableFields.findIndex(
        (variant) => variant.id === action.payload
      );

      if (index !== -1) {
        state.sortableFields.splice(index, 1);
      }

      const indexInSavedArr = state.savedSortableFields.findIndex(
        (variant) => variant.id === action.payload
      );

      if (indexInSavedArr !== -1) {
        state.savedSortableFields.splice(indexInSavedArr, 1);
      }
    },
    clearSortingVariants: (state) => {
      state.sortableFields = [];
      state.savedSortableFields = [];
    },
    resetVariantsEditModeToPreviousState: (state) => {
      for (const savedVariant of state.savedSortableFields) {
        const index = state.sortableFields.findIndex(
          (variant) => variant.id === savedVariant.id
        );

        if (index !== -1) {
          Object.assign(state.sortableFields[index], savedVariant);
        }
      }
    },
    resetToInitialState: (state) => {
      try {
        const parsedState = JSON.parse(state.initialSortableFieldsStateAsJson);

        state.sortableFields = parsedState;
        state.savedSortableFields = parsedState;
      } catch (error) {
        console.log(error);
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setCurrentViewConfiguration.fulfilled, (state, action) => {
        const currentViewConfiguration = action.payload;

        if (!currentViewConfiguration) return;

        const sortableFields = createSortableFields(
          currentViewConfiguration.sortings
        );

        state.sortableFields = sortableFields;
        state.savedSortableFields = sortableFields;
        state.initialSortableFieldsStateAsJson = JSON.stringify(sortableFields);
      })
      .addMatcher(
        apiService.endpoints.updateViewConfiguration.matchFulfilled,
        (state, action) => {
          const { data } = action.payload;
          if (!data) return;
          const { sortings } = data;

          const sortableFields = createSortableFields(sortings);

          state.sortableFields = sortableFields;
          state.savedSortableFields = sortableFields;
          state.initialSortableFieldsStateAsJson =
            JSON.stringify(sortableFields);
        }
      );
  },
});

const createSortableFields = (variants: any[]) => {
  return variants.map((variant: any) => {
    const { id, name, nameDB, type, isDraft } = variant.fieldInfo;
    const key = type as keyof typeof optionsByFieldType;

    return {
      id: id,
      fieldId: id,
      name,
      nameDB,
      value: variant.type,
      isSaved: isDraft || true,
      isInEditMode: false,
      type: type,
      options: optionsByFieldType[key] || alphabeticalOptions,
    };
  });
};

export const {
  addSortingVariant,
  setVariantToEditMode,
  setVariantValue,
  saveVariant,
  deleteVariant,
  clearSortingVariants,
  resetVariantsEditModeToPreviousState,
  resetToInitialState,
} = sortingSlice.actions;

export default sortingSlice.reducer;
