import { FC, useEffect, useState } from "react";
import type { CommonFilter } from "typings/filter";

import styles from "./FilterVariantOptions.module.scss";

const imageLikeFilterOptions = ["Is Empty", "Is Not Empty"];

const selectLikeFilterOptions = [
  "Contain",
  "Doesn't Contain",
  ...imageLikeFilterOptions,
];

const inputLikeFilterOptions = ["Is", "Is not", ...selectLikeFilterOptions];

const filterOptionsMap: Record<string, string[]> = {
  input: inputLikeFilterOptions,
  text: inputLikeFilterOptions,
  number: ["=", "≠", "<", ">", "≤", "≥", ...imageLikeFilterOptions],
  image: imageLikeFilterOptions,
  select: selectLikeFilterOptions,
  ref: selectLikeFilterOptions,
  boolean: [],
  default: inputLikeFilterOptions,
};

interface FilterVariantOptionsProps {
  type: CommonFilter["type"];
  selectedOption: string;
  onOptionChange: (filterOption: CommonFilter["type"]) => void;
  isSlugifator: boolean;
}

const FilterVariantOptions: FC<FilterVariantOptionsProps> = ({
  type,
  selectedOption = "",
  onOptionChange,
  isSlugifator = false,
}) => {
  const [currentFilterOptions, setCurrentFilterOptions] = useState<string[]>(
    []
  );
  const [activeOption, setActiveOption] = useState<string>(selectedOption);

  useEffect(() => {
    if (selectedOption === "") {
      setActiveOption(currentFilterOptions[0]);
    }
  }, [setActiveOption, currentFilterOptions, selectedOption]);

  useEffect(() => {
    const filterOptions = filterOptionsMap[type] || filterOptionsMap.default;
    if (type === "automat" && !isSlugifator) {
      setCurrentFilterOptions([]);
    } else {
      setCurrentFilterOptions(filterOptions);
    }
  }, [type, isSlugifator]);

  useEffect(() => {
    onOptionChange(activeOption as CommonFilter["type"]);
  }, [activeOption, onOptionChange]);

  const handleOnClick = (option: string) => () => setActiveOption(option);

  const additionalClassName =
    type === "number" ? styles["option--comparator"] : "";

  // There is no options for boolean type
  if (type === "boolean") {
    return null;
  }

  return (
    currentFilterOptions && (
      <div className={styles.wrapper}>
        {currentFilterOptions.map((option) => {
          const isActive = activeOption === option;
          const optionClassName = isActive
            ? `${styles.option} ${styles["option--active"]}`
            : styles.option;

          return (
            <button
              className={`${optionClassName}${
                type === "number" ? ` ${additionalClassName}` : ""
              }`}
              key={option}
              onClick={handleOnClick(option)}
            >
              {option}
            </button>
          );
        })}
      </div>
    )
  );
};

export default FilterVariantOptions;
