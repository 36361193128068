import { RootState } from "../store"; // Adjust the import path as necessary
import { ModalMeta } from "../../typings/modal";

export const getOpenModalsList = (state: RootState): string[] =>
  Object.keys(state.modal).filter((modalId) => state.modal[modalId]?.open);

export const isModalOpen = (state: RootState, id: string): boolean =>
  Boolean(state.modal[id]?.open ?? false);

export const getModalMeta = (
  state: RootState,
  id: string
): ModalMeta | undefined => state.modal[id]?.meta;
