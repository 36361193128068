import { FC } from "react";

import type { Option } from "typings/filter";

import styles from "./FieldTypeOption.module.scss";

interface FieldTypeOptionProps {
  option: Option;
  isActive: boolean;
  onClick: (option: Option) => void;
}

const FieldTypeOption: FC<FieldTypeOptionProps> = ({
  option,
  isActive,
  onClick,
}) => {
  const handleOnClick = () => {
    onClick(option);
  };

  const className = isActive
    ? `${styles.option} ${styles["option_active"]}`
    : styles.option;

  return (
    <button
      className={className}
      key={option.value}
      onClick={handleOnClick}
    >
      {option.label}
    </button>
  );
};

export default FieldTypeOption;
