import { ToggleOffIcon, ToggleOnIcon } from "icons";
import type ICell from "interfaces/ICell";
import type ICellRenderStrategy from "interfaces/ICellRenderStrategy";

export class AutomatCellRenderStrategy implements ICellRenderStrategy<ICell> {
  renderHeaderCell(data: ICell) {
    if (data.settingField === "slugifator") {
      return <>{data.name}</>;
    } else {
      return (
        <div
          data-tooltip-id="data-table-tooltip"
          data-tooltip-content={data.name}
          data-tooltip-place="left"
        >
          <ToggleOnIcon />
        </div>
      );
    }
  }

  renderCell(data: ICell, props?: any): JSX.Element | null {
    const { value, styles } = props;
    const { columnSchema } = props.column.columnDef.meta;

    const isSlugifator = columnSchema.settingField === "slugifator";

    if (!isSlugifator) {
      return (
        <div
          className={`${styles["flex-cell"]} ${styles["icon-column"]} ${
            value ? styles["flex-cell--with-content"] : ""
          }`}
        >
          {Boolean(value) ? <ToggleOnIcon /> : <ToggleOffIcon />}
        </div>
      );
    }

    return (
      <div className={`${styles["flex-cell"]} ${styles["content-column"]}`}>
        {value || "—"}
      </div>
    );
  }
}
