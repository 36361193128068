export const PARAM_COLLECTION_ID = "namecoll";

export const FILTERABLE_FIELD_TYPES = [
  "text",
  "number",
  "boolean",
  "image",
  "email",
  "input",
  "select",
  "ref",
  "automat",
];

export const FILTERABLE_FIELD_TYPES_AS_CONST = [
  "text",
  "number",
  "boolean",
  "image",
  "email",
  "input",
  "select",
  "ref",
  "automat",
] as const;

export const VISIBILITY_FIELD_TYPES = [...FILTERABLE_FIELD_TYPES, "date"];

export const VISIBILITY_FIELD_TYPES_AS_CONST = [
  "text",
  "number",
  "boolean",
  "image",
  "email",
  "input",
  "select",
  "ref",
  "automat",
  "date",
] as const;

export const SORTABLE_FIELD_TYPES = [
  "input",
  "number",
  "boolean",
  "image",
  "email",
  "select",
  "ref",
  "automat",
];

export const SORTABLE_FIELD_TYPES_AS_CONST = [
  "input",
  "number",
  "boolean",
  "image",
  "email",
  "select",
  "ref",
  "automat",
] as const;

export const COLLECTION_CONTROL_PANEL = {
  FILTERS: "filters",
  VISIBILITY: "fieldVisibility",
  SORTING: "sorting",
};

export const alphabeticalOptions = [
  { label: "Alphabetical (A → Z)", value: "asc" },
  { label: "Alphabetical (Z → A)", value: "desc" },
];
export const booleanOptions = [
  { label: "True first", value: "desc" },
  { label: "False first", value: "asc" },
];
export const numberOptions = [
  { label: "Ascending", value: "asc" },
  { label: "Descending", value: "desc" },
];
export const mediaOptions = [
  { label: "Filled first", value: "desc" },
  { label: "Empty first", value: "asc" },
];

export const optionsByFieldType = {
  input: alphabeticalOptions,
  ref: alphabeticalOptions,
  select: alphabeticalOptions,
  email: alphabeticalOptions,
  automat: alphabeticalOptions,
  boolean: booleanOptions,
  number: numberOptions,
  image: mediaOptions,
};
