/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import api from "../../func/api";
import { errorCatch } from "../../func/common";
import Pagination from "../common/Pagination";
import SearchElem from "../common/SearchElem";

const InfoCMSList = ({ cms }) => {
  const [infoCMS, setInfoCMS] = useState([]);
  const [limitInPage, setLimitInPage] = useState(40);
  const [count, setCount] = useState(10);
  const [page, setPage] = useState(1);

  const getData = useCallback(() => {
    api
      .getInfoCMSs(`page=${page}&limit=${limitInPage}`)
      .then((roles) => {
        setInfoCMS(roles);
      })
      .catch((err) => {
        errorCatch(err);
      });
  }, [page, limitInPage]);

  const getCount = () => {
    api
      .getInfoCMSCount()
      .then(({ count }) => {
        setCount(count);
      })
      .catch((err) => {
        errorCatch(err);
      });
  };

  useEffect(() => {
    getCount();
  }, []);

  useEffect(() => {
    getData();
  }, [page, limitInPage, getData]);

  const roleListHTML = infoCMS.map((el) => {
    return (
      <tr key={el.slug}>
        <td>
          <Link
            to={`/front/${cms}/settings/info-cms/${el.slug}`}
            className="tr-title"
          >
            {el.name}
          </Link>
        </td>
        <td>
          <span className="badge bg-secondary">
            {el.isMain ? "Главная" : "Дополнительная"}
          </span>
        </td>
        <td className="text-end">
          {!el.isMain && (
            <i
              className="bi bi-trash pointer me-3"
              onClick={() => {
                api
                  .deleteInfoCMS(el._id)
                  .then((result) => {
                    getData();
                  })
                  .catch((err) => {
                    errorCatch(err);
                  });
              }}
            />
          )}
          <i className="bi bi-files-alt pointer me-3" />
        </td>
      </tr>
    );
  });

  return (
    <main className="main">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 d-flex justify-content-between align-items-center mb-4 mt-4">
            <div>
              <h4 className="m-0">Список CMS</h4>
            </div>
            <div>
              <Link
                className="btn btn-primary"
                to={`/front/${cms}/settings/info-cms/new`}
              >
                Создать
              </Link>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between mb-2">
          <div className="">
            {/* <ul className="nav nav-tabs border-0">
              <li className="nav-item">
                <a className="nav-link p-2 active" aria-current="page" href="#">
                  Все
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link p-2" href="#">
                  Активные (10)
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link p-2 disabled"
                  href="#"
                  tabIndex="-1"
                  aria-disabled="true"
                >
                  Архив
                </a>
              </li>
            </ul> */}
          </div>
          <div>
            <SearchElem
              className=" ps-2"
              onClick={(searchString) => {}}
            />
          </div>
        </div>
        <div className="border rounded mb-3">
          <table className="table table-striped mb-0">
            <thead className="th-title">
              <tr>
                <th>Название</th>
                <th>Статус</th>
                <th className="text-end pe-4">Управление</th>
              </tr>
            </thead>
            <tbody>{roleListHTML}</tbody>
          </table>
        </div>
        <Pagination
          page={page}
          setPage={setPage}
          limitInPage={limitInPage}
          setLimitInPage={setLimitInPage}
          countAll={count}
        />
      </div>
    </main>
  );
};

export default InfoCMSList;
