import { FC } from "react";

const EyeSlashIcon: FC = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.359 11.238C15.06 9.72 16 8 16 8C16 8 13 2.5 7.99996 2.5C7.03955 2.50331 6.09002 2.70342 5.20996 3.088L5.97996 3.859C6.6274 3.62315 7.31091 3.50168 7.99996 3.5C10.12 3.5 11.879 4.668 13.168 5.957C13.7883 6.58069 14.3444 7.26513 14.828 8C14.77 8.087 14.706 8.183 14.633 8.288C14.298 8.768 13.803 9.408 13.168 10.043C13.003 10.208 12.831 10.371 12.651 10.529L13.359 11.238Z"
        fill="inherit"
      />
      <path
        d="M11.2974 9.17635C11.5205 8.5522 11.5618 7.8775 11.4165 7.23077C11.2712 6.58405 10.9452 5.99188 10.4765 5.52317C10.0078 5.05447 9.41566 4.72849 8.76893 4.58318C8.12221 4.43787 7.44751 4.4792 6.82335 4.70235L7.64635 5.52535C8.03066 5.47035 8.4225 5.5056 8.79082 5.62832C9.15915 5.75104 9.49383 5.95785 9.76834 6.23237C10.0429 6.50688 10.2497 6.84156 10.3724 7.20988C10.4951 7.5782 10.5304 7.97004 10.4754 8.35435L11.2974 9.17635ZM8.35435 10.4754L9.17635 11.2974C8.5522 11.5205 7.8775 11.5618 7.23077 11.4165C6.58405 11.2712 5.99188 10.9452 5.52317 10.4765C5.05447 10.0078 4.72849 9.41566 4.58318 8.76893C4.43787 8.12221 4.4792 7.44751 4.70235 6.82335L5.52535 7.64635C5.47035 8.03066 5.5056 8.4225 5.62832 8.79082C5.75104 9.15915 5.95785 9.49383 6.23237 9.76834C6.50688 10.0429 6.84156 10.2497 7.20988 10.3724C7.5782 10.4951 7.97004 10.5304 8.35435 10.4754Z"
        fill="inherit"
      />
      <path
        d="M3.35 5.46972C3.17 5.62972 2.997 5.79172 2.832 5.95672C2.21165 6.58041 1.65552 7.26485 1.172 7.99972L1.367 8.28772C1.702 8.76772 2.197 9.40772 2.832 10.0427C4.121 11.3317 5.881 12.4997 8 12.4997C8.716 12.4997 9.39 12.3667 10.02 12.1397L10.79 12.9117C9.90994 13.2963 8.9604 13.4964 8 13.4997C3 13.4997 0 7.99972 0 7.99972C0 7.99972 0.939 6.27872 2.641 4.76172L3.349 5.47072L3.35 5.46972Z"
        fill="inherit"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.6465 14.3545L1.64648 2.35448L2.35448 1.64648L14.3545 13.6465L13.6465 14.3545Z"
        fill="inherit"
      />
    </svg>
  );
};

export default EyeSlashIcon;
