import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { useAppSelector } from "hooks/useAppSelector";
import { PlusIconWithoutRect } from "icons/index";
import Button from "components/Button/Button";
import useOutsideClick from "hooks/useOutsideClick";
import InputWithControls from "components/InputWithControls/InputWithControls";

import styles from "./ViewConfigurations.module.scss";
import { ViewConfiguration } from "./interfaces/ViewConfiguration";
import ViewConfigurationButton from "./ViewConfigurationButton";
import Dropdown from "components/Dropdown/Dropdown";
import {
  useCreateViewConfigurationMutation,
  useDeleteViewConfigurationMutation,
  useFetchViewConfigurationsQuery,
  useLazyFetchViewConfigurationsQuery,
  useUpdateViewConfigurationsOrderMutation,
  useUpdateViewConfigurationMutation,
} from "api/apiService";
import { selectSortableFields } from "store/selectors/sortingSelectors";
import { selectVisibleFields } from "store/selectors/fieldVisibilitySelectors";
import { selectHaveChangesInViewConfiguration } from "store/selectors/combinedSelectors/combinedSelector";
import { selectAllFilters } from "store/selectors/filterSelectors";
import { prepareViewConfigurationData } from "helpers/view-configurations";
import { useSearchParams } from "react-router-dom";
import { PARAM_COLLECTION_ID } from "constants/index";
import useRequiredParam from "hooks/useRequiredParam";
import { useAppDispatch } from "hooks/useAppDispatch";
import { setCurrentViewConfiguration } from "store/thunks/collectionPageInfoThunks";

const ViewConfiguratations: FC = () => {
  const dispatch = useAppDispatch();
  const collectionId = useRequiredParam(PARAM_COLLECTION_ID);
  const sortableFields = useAppSelector(selectSortableFields);
  const visibilityFields = useAppSelector(selectVisibleFields);
  const filters = useAppSelector(selectAllFilters);
  const haveChangesInViewConfiguration = useAppSelector(
    selectHaveChangesInViewConfiguration
  );

  const { data: configurations, isLoading: configurationsAreLoading } =
    useFetchViewConfigurationsQuery(collectionId, {
      refetchOnMountOrArgChange: true,
    });

  const [fetchViewConfigurations] = useLazyFetchViewConfigurationsQuery();

  const [updateConfiguration] = useUpdateViewConfigurationMutation();

  const [createViewConfiguration] = useCreateViewConfigurationMutation();

  const [deleteConfiguration] = useDeleteViewConfigurationMutation();

  const [updateOrder] = useUpdateViewConfigurationsOrderMutation();

  const [searchParams, setSearchParams] = useSearchParams();

  const selectViewConfigurationById = useCallback(
    async (id: string) => {
      setSearchParams((params) => {
        const searchParams = new URLSearchParams(params);
        searchParams.set("viewConfigurationId", id);
        searchParams.set("page", "1");

        return searchParams.toString();
      });
      const currentConfigurationView = await dispatch(
        setCurrentViewConfiguration({
          viewConfigurationId: id,
        })
      ).unwrap();

      return currentConfigurationView;
    },
    [dispatch, setSearchParams]
  );

  const [currentConfigurationIdFromUrl, setCurrentConfigurationIdFromUrl] =
    useState<string | null>(null);

  useEffect(() => {
    if (!configurationsAreLoading) {
      const viewConfigurationIdFromURL = searchParams.get(
        "viewConfigurationId"
      );
      const viewConfigurationId =
        viewConfigurationIdFromURL || configurations?.data?.[0]?._id || null;
      setCurrentConfigurationIdFromUrl(viewConfigurationId);
    }
  }, [searchParams, configurationsAreLoading, configurations]);

  const slicedConfigurations = useMemo(
    () =>
      configurations?.data
        ?.map((config) => config)
        .sort((a, b) => a.order - b.order)
        .slice(0, 4),
    [configurations?.data]
  );

  const dropdownConfigurations = useMemo(
    () =>
      configurations?.data
        ?.map((config) => config)
        .sort((a, b) => a.order - b.order)
        .slice(4)
        .map((view: ViewConfiguration) => ({
          id: view._id,
          name: view.name,
        })),
    [configurations?.data]
  );

  const [lastAction, setLastAction] = useState<
    "create" | "update" | "delete" | null
  >(null);

  const [configurationInputSettings, setConfigurationInputSettings] = useState<{
    isOpen: boolean;
    top: number;
    left: number;
    isEditingExistingConfigurationView: boolean;
    isSavingAsNew: boolean;
  }>({
    isOpen: false,
    top: 0,
    left: 0,
    isEditingExistingConfigurationView: false,
    isSavingAsNew: false,
  });

  const amountOfConfigurationsLeft = useMemo(() => {
    if (!configurations?.data) return 0;

    return configurations.data.length - (slicedConfigurations?.length || 0);
  }, [configurations?.data, slicedConfigurations?.length]);

  useEffect(() => {
    if (slicedConfigurations?.length) {
      switch (lastAction) {
        case "create":
          const createdViewConfigurationId =
            slicedConfigurations[slicedConfigurations.length - 1]._id;
          selectViewConfigurationById(createdViewConfigurationId);
          setLastAction(null);
          break;
        case "update":
          return;
        case "delete":
          const defaultViewConfigurationId = slicedConfigurations[0]._id;
          selectViewConfigurationById(defaultViewConfigurationId);
          setLastAction(null);
        default:
      }
    }
  }, [lastAction, selectViewConfigurationById, slicedConfigurations]); // TODO: Check this one more time (.length in deps)

  const isActiveViewConfiguration = useCallback(
    (view: ViewConfiguration) => {
      return currentConfigurationIdFromUrl === view._id;
    },
    [currentConfigurationIdFromUrl]
  );

  const buttonWrapperRef = useRef<HTMLInputElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  // const [shouldFocus, setShouldFocus] = useState(false);

  const handleOnCancel = () => {
    setConfigurationInputSettings((state) => ({
      ...state,
      isEditingExistingConfigurationView: false,
      isOpen: false,
    }));
  };

  const handleButtonClick = () => {
    if (buttonRef.current) {
      const buttonPosition = buttonRef.current.getBoundingClientRect();

      setConfigurationInputSettings((state) => ({
        ...state,
        isOpen: !state.isOpen,
        isEditingExistingConfigurationView: false,
        isSavingAsNew: haveChangesInViewConfiguration,
        top: buttonPosition.top + window.scrollY + buttonPosition.height,
        left: buttonPosition.left + window.scrollX,
      }));
    }
  };

  const inputWithControlsRef = useRef<{ resetInput: () => void }>(null);

  useOutsideClick(
    buttonWrapperRef,
    () => {
      setConfigurationInputSettings((state) => ({
        ...state,
        isEditingExistingConfigurationView: false,
        isOpen: false,
      }));

      if (inputWithControlsRef.current) {
        inputWithControlsRef.current.resetInput();
      }
    },
    configurationInputSettings.isOpen
  );

  const onNewViewConfigurationSubmit = async (
    viewConfigurationName: string
  ) => {
    try {
      if (!viewConfigurationName) return;

      if (configurationInputSettings.isEditingExistingConfigurationView) {
        const view = slicedConfigurations?.find(
          (configuration) => configuration._id === currentConfigurationIdFromUrl
        );

        if (!view) return;

        const updatedView: ViewConfiguration = {
          ...view,
          viewType: "list-view",
          name: viewConfigurationName,
        };

        await handleEditViewConfiguration({
          view: updatedView,
          shouldUpdateOnlyName: true,
        });
        setConfigurationInputSettings((state) => ({
          ...state,
          isOpen: false,
          isEditingExistingConfigurationView: false,
        }));
        return;
      }

      if (configurationInputSettings.isSavingAsNew) {
        const activeViewConfiguration = slicedConfigurations?.find(
          (configuration) => configuration._id === currentConfigurationIdFromUrl
        );
        if (!activeViewConfiguration) return;

        const view = await createViewConfiguration({
          collectionId,
          body: {
            name: viewConfigurationName,
            viewType: "list-view",
            ...prepareViewConfigurationData({
              filters,
              sortings: sortableFields,
              fieldVisibilities: visibilityFields,
            }),
          },
        }).unwrap();

        if (view) {
          await updateOrder({
            collectionId,
            viewConfigurationId: view.data?._id || "",
            body: {
              viewType: "list-view",
              order: 3,
            },
          }).unwrap();
          setConfigurationInputSettings((state) => ({
            ...state,
            isSavingAsNew: false,
            isOpen: false,
          }));

          setLastAction("create");
          await fetchViewConfigurations(collectionId);
        }
        return;
      }

      const payload = await createViewConfiguration({
        collectionId,
        body: {
          name: viewConfigurationName,
          viewType: "list-view",
        },
      }).unwrap();

      if (payload) {
        if (payload?.data) {
          if (payload.data.order > 3) {
            await updateOrder({
              collectionId,
              viewConfigurationId: payload.data._id,
              body: {
                viewType: "list-view",
                order: 3,
              },
            }).unwrap();
          }
        }

        setConfigurationInputSettings((state) => ({
          ...state,
          isOpen: false,
        }));

        await fetchViewConfigurations(collectionId);
        setLastAction("create");
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleOnDeleteViewConfiguration = async (view: ViewConfiguration) => {
    try {
      const payload = await deleteConfiguration({
        collectionId,
        viewConfigurationId: view._id,
      }).unwrap();

      if (payload) {
        setLastAction("delete");
        await fetchViewConfigurations(collectionId);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleEditViewConfiguration = async ({
    view,
    shouldUpdateOnlyName,
  }: {
    view: ViewConfiguration;
    shouldUpdateOnlyName: boolean;
  }) => {
    let body: any = {
      viewType: "list-view",
    };

    if (shouldUpdateOnlyName) {
      body = {
        ...body,
        name: view.name,
      };
    } else {
      body = {
        ...body,
        ...view,
        sortings: sortableFields.map((field) => ({
          fieldId: field.fieldId,
          type: field.value,
          isDraft: !field.isSaved,
        })),
        fieldVisibilities: visibilityFields.map((field) => ({
          fieldId: field.fieldId,
          visible: field.isVisible,
          order: field.order,
        })),
      };
    }

    try {
      const payload = await updateConfiguration({
        collectionId,
        viewConfigurationId: view._id,
        body,
      }).unwrap();

      if (payload) {
        setLastAction("update");
        await fetchViewConfigurations(collectionId);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setShouldFocus(configurationInputSettings["isOpen"]);
  //     clearTimeout(timer);
  //   }, 150);
  // }, [configurationInputSettings["isOpen"]]);

  const onDropdownConfigurationClick = async (id: string) => {
    const view = configurations?.data?.find((view) => view._id === id);

    if (view) {
      selectViewConfigurationById(view._id);

      try {
        const payload = await updateOrder({
          collectionId,
          viewConfigurationId: view._id,
          body: {
            viewType: "list-view",
            order: 3,
          },
        }).unwrap();

        if (payload) {
          await fetchViewConfigurations(collectionId);
          setLastAction("update");
        }
      } catch (error) {
        console.log("error", error);
      }
    }
  };

  if (configurationsAreLoading) return <div>Loading...</div>;

  return (
    <div className={styles["wrapper"]}>
      <div className={styles["view-wrapper"]}>
        {slicedConfigurations?.map((view) => (
          <ViewConfigurationButton
            key={view._id}
            view={view}
            isActiveViewConfiguration={isActiveViewConfiguration(view)}
            onViewConfigurationClick={(view) => {
              selectViewConfigurationById(view._id);
            }}
            onContextMenuEditClick={({ position, view }) => {
              if (position === null) return;

              setSearchParams((params) => {
                const searchParams = new URLSearchParams(params);
                searchParams.set("viewConfigurationId", view._id);
                return searchParams.toString();
              });

              setConfigurationInputSettings((state) => ({
                ...state,
                isOpen: true,
                isEditingExistingConfigurationView: true,
                top: position.top + window.scrollY + position.height,
                left: position.left + window.scrollX,
              }));
            }}
            onContextMenuDeleteClick={handleOnDeleteViewConfiguration}
          />
        ))}
      </div>
      {amountOfConfigurationsLeft > 0 && (
        <Dropdown
          options={dropdownConfigurations || []}
          onOptionClick={onDropdownConfigurationClick}
        >
          {amountOfConfigurationsLeft} more
        </Dropdown>
      )}
      <div
        className={styles["button-wrapper"]}
        ref={buttonWrapperRef}
      >
        <div className={styles["divider"]} />
        <Button
          ref={buttonRef}
          icon={<PlusIconWithoutRect />}
          appearance="accent"
          onClick={handleButtonClick}
          isActive={
            configurationInputSettings["isOpen"] &&
            !configurationInputSettings["isEditingExistingConfigurationView"]
          }
        >
          {haveChangesInViewConfiguration ? "Save as" : "Blank"}
        </Button>
        {configurationInputSettings.isOpen && (
          <InputWithControls
            ref={inputWithControlsRef}
            style={{
              top: `${configurationInputSettings["top"] + 9}px`,
              left: `${configurationInputSettings["left"]}px`,
              // visibility: configurationInputSettings["isOpen"]
              //   ? "visible"
              //   : "hidden",
              // opacity: configurationInputSettings["isOpen"] ? 1 : 0,
            }}
            onCancel={handleOnCancel}
            onSubmit={onNewViewConfigurationSubmit}
            initialValue={
              (configurationInputSettings.isEditingExistingConfigurationView &&
                slicedConfigurations?.find(
                  (configuration) =>
                    configuration._id === currentConfigurationIdFromUrl
                )?.name) ||
              ""
            }
            shouldFocus={true}
          />
        )}
      </div>
    </div>
  );
};

export default ViewConfiguratations;
